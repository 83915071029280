/* eslint-disable  */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { VideoTileState } from 'amazon-chime-sdk-js';
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import styles from './LocalVideo.css';
import stylesRV from './RemoteVideo.css';
import Tooltip from './Tooltip';
import { useIntl } from 'react-intl';

const cxRV = classNames.bind(stylesRV);
const cx = classNames.bind(styles);

type Props = {
  tileView: boolean;
  videoLength: any;
  onShowLocalVidoe: (bl: boolean) => Boolean;
  localVideoView: boolean;
};

export default function LocalVideo(props: Props) {

  const {
    tileView,
    videoLength,
    onShowLocalVidoe,
    localVideoView
  } = props;

  const [enabled, setEnabled] = useState(false);
  const [muted, setMuted] = useState(false);
  const intl = useIntl();

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const videoElement = useRef(null);

  let userName = chime?.name;
  const initials = userName?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('');

  useEffect(() => {

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {
        if (
          !tileState.boundAttendeeId ||
          !tileState.localTile ||
          !tileState.tileId ||
          !videoElement.current
        ) {
          return;
        }

        chime?.audioVideo?.bindVideoElement(
          tileState.tileId,
          (videoElement.current as unknown) as HTMLVideoElement
        );

        onShowLocalVidoe(tileState.active);
        setEnabled(tileState.active);
      }
    });
  }, []);
  useEffect(() => {
    const callback = (localMuted: boolean) => {
      setMuted(localMuted);
    };

    chime?.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
    return () => {
      if (chime && chime?.audioVideo) {
        chime?.audioVideo?.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(
          callback
        );
      }
    };
  }, []);

  return (
    <div style={{'position': 'relative','zIndex' : 1}} className={cxRV(
      {
        remoteVideo:!tileView, 
        enabled:!tileView, 
        large:!tileView
      }, cx({
        videoTileview: tileView,
} ))} >
      <video 
      muted ref={videoElement} className={cx('video', {
        localVideoTileView : tileView,
        'setbgcolor' : !localVideoView,
      })} />
           {!localVideoView && <div className={cxRV({
              attendeeName: !tileView,
              attendeeNameTileview: tileView,
            })}>
              {initials}
            </div>
            }
          <Tooltip
        tooltip={
          muted ?
            intl.formatMessage({ id: 'Controls.unmuteTooltip' })
            :
            intl.formatMessage({ id: 'Controls.muteTooltip' })

        }
      >
        <div className={cx('micIcon')} onClick={async () => {
          if (muted) {
            chime?.audioVideo?.realtimeUnmuteLocalAudio();
          } else {
            chime?.audioVideo?.realtimeMuteLocalAudio();
          }
          // Adds a slight delay to close the tooltip before rendering the updated text in it
          await new Promise(resolve => setTimeout(resolve, 10));

        }}>
          {
            muted ? (
            <i className="fas fa-microphone-slash" />
            ) : (
              <i className="fas fa-microphone marginlefticon" />
            )}
        </div>
        </Tooltip>            
      <div 
      // style={ tileView ? {bottom: '45px', left: '3%'} : {}}
      className={cx("namemain")}>
          {/* <div 
          style={ tileView ? {padding: '5px'} : {}}
          className={cx("host")}>Me</div> */}
          <div 
          // style={ tileView ? {padding: '3px', 'fontSize': '16px'} : {}}
          className={cx("localname")}>&nbsp;{userName}</div>
      </div>
    </div>
  );
}
