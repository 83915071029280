/* eslint-disable  */
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styles from './UpcomingMeetings.css';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import routes from '../constants/routes.json';
import commonob from '../constants/common.json'
import pkgJSON from '../package.json'
import localStorageKeys from '../constants/localStorageKeys.json';
import Tooltip from './Tooltip';
import { globalApiService } from "../common_function"

const cx = classNames.bind(styles);
var currentPage = { "page": 1 }
var total_meeting: any;

type Props = {
  joinMeetingFromList: (b: string,host:boolean) => void;
  editMeetingFromList: (id: any) => void;
};

export default function UpcomingMeetings(props: Props) {
  const login_flag = (localStorage.getItem("isAuthenticated") ? true : false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const removeItem = localStorageKeys.REMOVE_ITEMS;
  const perPage: any = 3;
  const [postData, setPostData] = useState();
  const intl = useIntl();

  const {
    joinMeetingFromList,
    editMeetingFromList
  } = props;
  // variable for logout check

  const meetingList = async () => {
    let body = {
      "created_by": localStorage.getItem("userName")
    }

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };
    var requestOptions: any = {
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify({
        "invited_email" : localStorage.getItem("userName")
      })
    };
    return new Promise(async (resolve) => {
      let list:any = []
      setLoading(true)

      var options = {
        'method': 'POST',
        'body': JSON.stringify(body),
        'url': `${commonob.admin_url}/meeting_schedule/list`
      };

      try{
        let rs:any = await globalApiService(options);
        rs.Items.map((item:any)=>{
          list.push(item)
        })
        var options = {
          'method': 'POST',
          'body': JSON.stringify({
            "invited_email" : localStorage.getItem("userName")
          }),
          'url': `${commonob.getBaseUrl}meeting_schedule/invited_list`
        };
  
        try{
          let rs:any = await globalApiService(options);
          rs.Items.map((item:any)=>{
            if (item.created_by != localStorage.getItem("userName")){
              list.push(item)
            }
          })
        } catch (e){
          console.log(e)
          setLoading(false)
        }
      } catch (e){
        console.log(e)
        setLoading(false)
      }
      setLoading(false)
      resolve(list);
    })
  }


  useEffect(() => {
    handlePageClick()
    removeItem.map((key) =>
      localStorage.removeItem(key)
    )

  }, [])

  // delete meeting
  const deleteMeeting = async (id: any, title: any) => {
    var r = confirm(`Are you sure want to cancel? :${title}`);
    if (r == true) {
      setLoading(true)
      const requestOptions4 = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('nylas_token')
        }
      }
      const nylas_response = await fetch(`${commonob.nylas.APIURL}events/${id}`, requestOptions4)
      const response = await fetch(commonob.admin_url + `/meeting_schedule?event_id=${id}`, { method: 'DELETE' })
      if (nylas_response.ok && response.ok) {
        setLoading(true)
        await handlePageClick()
      } else {
        alert("Oops! Something went wrong")
        setLoading(true)
      }
    } else {
      console.log("You pressed Cancel!")
    }

  }

  // change page 
  const handlePageClick = async () => {
    function timer(time: any) {
      var date = new Date(time * 1000);
      var meet_time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
      var meet_date = date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, ' ');
      return (<><p className={cx("date_time")}>{meet_date},</p><p className={cx("date_time")}>{meet_time}</p></>)
    }

    var list: any = await meetingList()

    await list.sort(function (a:any, b:any) {
      return a.from_ts.localeCompare(b.from_ts);
    });
    
    var exp_meeting: any = [];
    await list.map((key: any) => {
      var countDownDate = key.to_ts * 1000;
      var now = new Date().getTime();
      var cT = countDownDate - now;
      if (cT > 0) {
        exp_meeting.push(key)
      }
    })

    total_meeting = exp_meeting.length;
    // setInterval(async () => {
    var slice = exp_meeting.slice((currentPage.page * perPage) - perPage, currentPage.page * perPage)
    var post_Data = slice.map((pd: any, index: any) =>
      <React.Fragment key={index}>
        <div className={cx('meetingsCard')} >

          <div className={cx("meetingParentDiv")}>
            <div className={cx('meetingHeader')}>
              <div className={cx('meetingTitleId')} >{pd.meeting_heading}</div>
              <div className={cx('actionCell')}>
                {/* <Tooltip
                  tooltip={
                    intl.formatMessage({ id: 'Controls.joinMeeting' })
                  }
                >
                  <img className={cx('editIcon')} src={`public/icons/joinMeeting.png`} onClick={
                    () => {
                      joinMeetingFromList(pd.Title)
                    }
                  } />
                </Tooltip> */}
                {/* <Tooltip
                  tooltip={
                    intl.formatMessage({ id: 'Controls.editMeeting' })
                  }
                >
                  <img className={cx('deleteIcon')} src={`public/icons/editMeeting.png`} onClick={() => {
                    editMeetingFromList(pd.Title)
                  }} />
                </Tooltip> */}
              { pd.created_by == localStorage.getItem('userName') &&
                <>
                <Tooltip
                  tooltip={
                    intl.formatMessage({ id: 'Controls.deleteMeeting' })
                  }
                >
                  <div className={cx('deleteMeeting')} onClick={() => {
                    deleteMeeting(pd.nylas_event_id, pd.meeting_heading)
                  }}>
                  <img className={cx('deleteIcon')} src={`/icons1/deleteMeeting.png`} />
                  {/* <span>Cancel</span> */}
                  </div>
                </Tooltip>
                <Tooltip
                tooltip={
                  intl.formatMessage({ id: 'Controls.editMeeting' })
                }
              >
                 <div className={cx('editMeeting')} onClick={() => {
                    editMeetingFromList(pd.Title)
                  }}>
                    <img className={cx('deleteIcon')} src={`/icons1/edit.png`} />                  
                  {/* <span>Edit</span> */}
                  </div>             
              </Tooltip>
                </>
              }
              </div>
            </div>
            <div className={cx('meetingDate')}>{timer(pd.from_ts)}</div>
            <div className={cx('inviteParticipants')}>
              <div className={cx('emailList')}>
              {pd.participants.map((item:any)=>{
                return(
                <span>{item.email}</span>
                )
              })}
              </div>
              <button className={cx('startMeeting')} onClick={()=>{
                joinMeetingFromList(pd.Title,pd.created_by == localStorage.getItem('userName'))
              }}>Start</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
    setPostData(post_Data)
    // }, 1000)
  }
  return (
    <>
      { login_flag &&
        <div className={cx('upcomingMeetings')}>

          {
            <div className={cx('upcomingMeetings_formWrapper')}>
              <img className={cx('logo')} src={`/${commonob.app_name}_logo.png`} />
              <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
              <h1 className={cx('title')}>Upcoming Meetings</h1>
              {
                <div className={cx('meetingList')}>
                  {total_meeting > 0 ? postData : !loading ? "You have no meetings schedule." : ""}
                </div>}
              <div className={cx('scedularNextButtons')}>
                <div>
                  {currentPage.page > 1 &&
                    <div className={cx('prevLink', 'olderLinkBlackColor')} onClick={async () => { currentPage.page = currentPage.page - 1; await handlePageClick() }}>
                      {'<<<'}
                    </div>}
                  {loading &&
                    <div className={cx('upcoming_meeting_spinner')}>
                      {Array.from(Array(12).keys()).map(key => (
                        <div key={key} className={cx('upcoming_meeting_circle', `upcoming_meeting_circle${key + 1}`)} />
                      ))}
                    </div>}
                  {total_meeting > currentPage.page * perPage && <div className={cx('nextLink', 'olderLinkBlackColor')} onClick={async () => { currentPage.page = currentPage.page + 1; await handlePageClick() }}>
                    {'>>>'}
                  </div>}


                </div>
              </div>
              <div className={cx('upcoming_meeting_loginLink')} onClick={() => {
                    history.push(routes.JOIN);
                  }}>
                    <FormattedMessage id="Back.link" />
                  </div>
            </div>
          }
        </div>
      }
    </>
  );
}