// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */


import classNames from 'classnames/bind';
import React, { useContext,useEffect } from 'react';
import { useIntl } from 'react-intl';
import ViewMode from '../enums/ViewMode';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './RemoteVideo.css';
import styles1 from './RosterLayout.css';
import Tooltip from './Tooltip';
import ClassMode from '../enums/ClassMode';
import localStorageKeys from "../constants/localStorageKeys.json"

const css = classNames.bind(styles1);
const cx = classNames.bind(styles);

export enum Size {
  Small,
  Medium,
  Large
}

type Props = {
  viewMode: ViewMode;
  size: Size;
  rosterAttendee?: RosterAttendeeType;
  raisedHand?: boolean;
  isVideoEnable: boolean;
  activeSpeaker: boolean;
  boundAttendeeId: string;
  tileView: boolean;
  exitZoomRoster: () => void;
  volume: any;
  activeSpeakerMuted: boolean;
  removeAttendee: (id: string) => void;
  muteunmuteAttendee: (id: string) => void;
  attendeeIdFullScreen: string;
  isRightBarEnabled: boolean;
};

export default function RosterLayout1(props: Props) {
  const intl = useIntl();
  const {
    viewMode,
    size = Size.Large,
    rosterAttendee = {},
    raisedHand,
    isVideoEnable,
    activeSpeaker,
    boundAttendeeId,
    tileView,
    exitZoomRoster,
    volume,
    activeSpeakerMuted,
    removeAttendee,
    muteunmuteAttendee,
    attendeeIdFullScreen,
    isRightBarEnabled
  } = props;

  var { name } = rosterAttendee;
  const initials = name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('');

  useEffect(()=>{
    if (attendeeIdFullScreen == boundAttendeeId){
      setTimeout(()=>{
        let nav = document.getElementById('participantStatusNew')
        nav?.setAttribute("style","opacity:1;");
      },2000)
      
      setTimeout(()=>{
        let nav = document.getElementById('participantStatusNew')
        nav?.setAttribute("style","opacity:0;");
      },10000)
    }
  },[attendeeIdFullScreen,boundAttendeeId])

  return (
    <>
      <div
        className={cx('attendee', {
          remoteVideo: !tileView,
          screenShareMode: viewMode === ViewMode.ScreenShare,
          rosterEnabled: isVideoEnable == false,
          small: size === Size.Small,
          medium: size === Size.Medium,
          large: size === Size.Large && !tileView,
          largeHeight: boundAttendeeId == attendeeIdFullScreen && !tileView
        })}
      >

        {
          !tileView && (
            <>
              {/* { boundAttendeeId !== attendeeIdFullScreen && (
        <video 
        // style={{ 'width' : (activeSpeaker && !activeSpeakerMuted && viewMode === ViewMode.ScreenShare) ? '214px' : '210px' }}
        muted className={cx('videonew', {
        // activeSpeaker : activeSpeaker && !activeSpeakerMuted, 
        tileVideo : tileView,
        screenShareViewVideo : viewMode == ViewMode.ScreenShare
        })} />
      )} */}

              <div style={{ textTransform: 'uppercase' }} className={cx({
                'attendeeName': boundAttendeeId != attendeeIdFullScreen,
                'attendeeNameScreenShareView': viewMode == ViewMode.ScreenShare,
                'contentcenter': boundAttendeeId == attendeeIdFullScreen && !isRightBarEnabled, // 
                'contentcenterRightSideEnable': boundAttendeeId == attendeeIdFullScreen && isRightBarEnabled
              })}>  {initials} </div>


              {boundAttendeeId != attendeeIdFullScreen && (
                <>
                  <div className={css('participantStatus', {
                    screenShareViewModeParticipants: viewMode == ViewMode.ScreenShare
                  })}>
                    <div className={css('nameStatus', 'newlook', {
                      screenShareViewModenameStatus: viewMode == ViewMode.ScreenShare
                    })}>
                      <div
                        style={{ cursor: 'pointer' }}
                        className={css('name')}>
                        {rosterAttendee.name}
                      </div>

                    </div>
                  </div>

                  {localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher ?
                    <div className={css('participantControl')}>
                      <div className={css('controStatus', 'newlook', {
                        screenShareViewControlStatus: viewMode == ViewMode.ScreenShare
                      })}>
                        <Tooltip
                          tooltip={'Remove Attendee'}
                        >
                          <div style={{ cursor: 'pointer' }} onClick={() => {
                            if (confirm(`Are you sure to remove ${rosterAttendee.name} from meeting ?`)) {
                              removeAttendee(boundAttendeeId)
                            }
                          }}
                            className={css('user')}> <i className={css('fas fa-user-times')} />
                          </div>
                        </Tooltip>

                        {typeof rosterAttendee.muted === 'boolean' && (

                          <Tooltip
                            tooltip={
                              rosterAttendee.muted ? 'Mute Attendee'
                                : 'Unmute Attendee'
                            }
                          >
                            <div style={{ cursor: 'pointer' }} className={css('muted__', 'manageheight')}
                              onClick={() => { muteunmuteAttendee(boundAttendeeId) }} >

                              {rosterAttendee.muted ? (
                                <i className="fas fa-microphone-slash" />
                              ) : (
                                <i className="fas fa-microphone marginlefticon" />
                              )}
                            </div>
                          </Tooltip>
                        )
                        }
                      </div>
                    </div>
                    :
                    <div className={css('participantControl')}>
                    <div className={css('controStatusJoinee', 'newlook', {
                      screenShareViewControlStatus: viewMode == ViewMode.ScreenShare
                    })}>

                      {typeof rosterAttendee.muted === 'boolean' && (

                          <div  className={css('muted__', 'manageheight')}>

                            {rosterAttendee.muted ? (
                              <i className="fas fa-microphone-slash" />
                            ) : (
                              <i className="fas fa-microphone marginlefticon" />
                            )}
                          </div>
                      )
                      }
                    </div>
                  </div>
                  }
                </>
              )
              }



              {boundAttendeeId === attendeeIdFullScreen && !tileView && viewMode != ViewMode.ScreenShare && (
                <div id='participantStatusNew' className={cx({
                  participantRosterStatusNew: boundAttendeeId === attendeeIdFullScreen,
                  participantStatusWidht: boundAttendeeId === attendeeIdFullScreen,
                })}>

                  <div className={css('fullScreenControl')} style={{ flexDirection: 'column' }}>

                    <div className={css('firstdiv')}>
                      {/* {
                  <div style={{marginTop: '7px', float: 'left'}}>
                  <div style={{ height: '6px', bottom: '-6px', background: volume >= 20 ?  '#00C5BA' : '#8a8d8a' }} className={css('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '8px', bottom: '-4px', background: volume >= 40 ?  '#00C5BA' : '#8a8d8a' }} className={css('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '10px', bottom: '-2px', background: volume >= 60 ?  '#00C5BA' : '#8a8d8a' }} className={css('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '12px', bottom: '0px', background: volume >= 80 ?  '#00C5BA' : '#8a8d8a' }} className={css('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '14px', bottom: '2px', background: volume >= 100 ?  '#00C5BA' : '#8a8d8a' }} className={css('volumecontrol')}>&nbsp;</div>
                  { rosterAttendee.muted  && (
                    <div className={css('volumecontrol','mutemic')}>&nbsp;</div>
                  )}                  
                  </div>
                }                 */}
                      {volume >= 0 && volume < 25 && !rosterAttendee.muted && <img src='/icons1/volumeIndicator1.png' />}
                      {volume >= 25 && volume < 50 && <img src='/icons1/volumeIndicator2.png' />}
                      {volume >= 50 && volume < 75 && <img src='/icons1/volumeIndicator3.png' />}
                      {volume >= 75 && <img src='/icons1/volumeIndicator4.png' />}
                      {rosterAttendee.muted && (
                        <img src='/icons1/volumeIndicator5.png' />
                      )}
                      <span><strong>{rosterAttendee.name}</strong></span>
                    </div>


                    {/* <div  style={{'color' : '#807e7e','cursor': 'default'}} className={css('firstdiv')}>                
                  { <i className="fas fa-arrows-alt" /> }
                  <span>{intl.formatMessage({ id: 'RosterLayout.FullScreen' })}</span>  
            </div>
            <div style={{'color' : '#807e7e','cursor': 'default'}}  className={css('firstdiv')}>                
                  { localStorage.getItem('fullscreen') == 'true' ? <i className="fas fa-search-minus" /> : <i className="fas fa-search-plus" /> 
                  }
                  <span>{intl.formatMessage({ id: 'RosterLayout.ZoomInZoomOut' })}</span>
            </div> */}
                    <div onClick={() => exitZoomRoster()} className={css('firstdiv')}>
                    <img src={'/icons1/exitZoom.png'} />
              <span>{intl.formatMessage({ id: 'RosterLayout.ExitZoom' })}</span>
                    </div>

                  </div>
                </div>
              )}


            </>

          )}


        {tileView && (
          <>
            <div
              className={cx('attendeeNameTileview', {
                // activeSpeaker: activeSpeaker && attendeeIdFullScreen == boundAttendeeId && !activeSpeakerMuted
              })}  >
              {initials}
            </div>
            <div
              // style={{bottom:0, position:'absolute',marginBottom: '16px', height: '30px', marginLeft: '2%'}}
              className={cx('participantStatusTileView')}>
              <div className={cx('participantnameTileView')} >
                {rosterAttendee.name}
              </div>
            </div>
            <div className={cx('participantControlTileView')}>
              <div className={cx('participantControlDivTileView')}>
                {
                  typeof rosterAttendee.muted === 'boolean' && (
                    <div
                    // className={cx('muted')}
                    >
                      {attendeeIdFullScreen == boundAttendeeId && !activeSpeakerMuted && (
                        <div className={css('firstdiv')}>
                          {volume >= 0 && volume < 25 && !rosterAttendee.muted && <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator1.png' />}
                          {volume >= 25 && volume < 50 && <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator2.png' />}
                          {volume >= 50 && volume < 75 && <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator3.png' />}
                          {volume >= 75 && <img style={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator4.png' />}
                          {rosterAttendee.muted && (
                            <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator5.png' />
                          )}
                        </div>
                      )}

                      {attendeeIdFullScreen == boundAttendeeId && activeSpeakerMuted && (
                            <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator5.png' />
                            )}

                      {attendeeIdFullScreen != boundAttendeeId && (
                        <>
                          {rosterAttendee.muted ? (
                            <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator5.png' />
                            ) : (
                              <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator1.png' />
                              )}
                        </>
                      )}
                    </div>
                  )
                }
              </div>
            </div>
          </>
        )
        }



        {raisedHand && (
          <div className={cx('raisedHand')}>
            <span
              role="img"
              aria-label={intl.formatMessage({
                id: 'RemoteVideo.raiseHandAriaLabel'
              })}
            >
              ✋
            </span>
          </div>
        )}
      </div>
    </>
  );
}





