/* eslint-disable */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useIntl } from 'react-intl';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import useDevices from '../hooks/useDevices';
import DeviceType from '../types/DeviceType';
import styles from './DeviceSwitcher.css';

const cx = classNames.bind(styles);

export default function DeviceSwitcher() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const intl = useIntl();
  const deviceSwitcherState = useDevices();
  const [microphoneDevice, setMicrophoneDevice] = useState(null)
  const [audioDevice, setAudioDevice] = useState(null);
  const [videoDevice, setVideoDevice] = useState(null);
    

  async function setLocalStorage(identifier, selectedDevice) {
    await localStorage.setItem(identifier,JSON.stringify(selectedDevice));
  }

useEffect( () => {

if(videoDevice != null){

if(deviceSwitcherState.videoInputDevices.length == 1){

let one =  deviceSwitcherState.videoInputDevices[0];
deviceSwitcherState.currentVideoInputDevice = one;
setLocalStorage("video_default",one);
// setVideoDevice(true);
chime?.chooseVideoInputDevice(one);

}else {

deviceSwitcherState.videoInputDevices.map( async (device) => {
    if(device.label === videoDevice.label){
        deviceSwitcherState.currentVideoInputDevice = device;
        localStorage.setItem("video_default",(device).toString());
        setLocalStorage("video_default",device);
        await setVideoDevice(device);
        await chime?.chooseVideoInputDevice(device);
    }
})
}
}


new Promise<void>(async (resolve, reject) => { 

await setMicrophoneDevice(localStorage.getItem("microphone_default") != null && localStorage.getItem("microphone_default") != undefined ? JSON.parse(localStorage.getItem("microphone_default") ) : (deviceSwitcherState.currentAudioInputDevice));

await setAudioDevice(localStorage.getItem("audio_default") != null && localStorage.getItem("audio_default") != undefined ? JSON.parse(localStorage.getItem("audio_default") ) : (deviceSwitcherState.currentAudioOutputDevice));

await setVideoDevice(localStorage.getItem("video_default") != null && localStorage.getItem("video_default") != undefined ? JSON.parse(localStorage.getItem("video_default")) : (deviceSwitcherState.currentVideoInputDevice));        
resolve();

})

}, [deviceSwitcherState]) 


  return (
    <div className={cx('deviceList')}>


      <div className={cx('device-list')}> 
      <div className={cx('device-name')}>CAMERA</div>
      <Dropdown
        className={cx('dropdown111')}
        controlClassName={cx('control')}
        placeholderClassName={cx('placeholder')}
        menuClassName={cx('menu')}
        arrowClassName={cx('arrow')}
        value={videoDevice == null || videoDevice == undefined ? deviceSwitcherState.currentVideoInputDevice : videoDevice}
        options={deviceSwitcherState.videoInputDevices || ([] as DeviceType[])}
        disabled={
          !deviceSwitcherState.videoInputDevices ||
          !deviceSwitcherState.videoInputDevices.length
        }
        onChange={async (selectedDevice: DeviceType) => {
          deviceSwitcherState.currentVideoInputDevice = selectedDevice;
          await chime?.chooseVideoInputDevice(selectedDevice);
          new Promise<void>(async (resolve, reject) => { 
            await setLocalStorage("video_default",selectedDevice);
            resolve();
          });

        }}
        placeholder={
          deviceSwitcherState.currentVideoInputDevice
            ? intl.formatMessage({
                id: 'DeviceSwitcher.noVideoInputPlaceholder'
              })
            : ''
        }
      />
        </div>

      <div className={cx('device-list')}> 
      <div className={cx('device-name')} >MICROPHONE</div>
      <Dropdown
        className={cx('dropdown')}
        controlClassName={cx('control')}
        placeholderClassName={cx('placeholder')}
        menuClassName={cx('menu')}
        arrowClassName={cx('arrow')}
        value={ microphoneDevice == null || microphoneDevice == undefined ? deviceSwitcherState.currentAudioInputDevice : microphoneDevice }
        options={deviceSwitcherState.audioInputDevices || ([] as DeviceType[])}
        disabled={
          !deviceSwitcherState.audioInputDevices ||
          !deviceSwitcherState.audioInputDevices.length
        }
        onChange={async (selectedDevice: DeviceType) => {
          await chime?.chooseAudioInputDevice(selectedDevice);
          // localStorage.setItem("microphone_default",JSON.stringify(selectedDevice));
          new Promise<void>(async (resolve, reject) => { 
            await setLocalStorage("microphone_default",selectedDevice);
            resolve();
          });

        }}
        placeholder={
          deviceSwitcherState.currentAudioInputDevice
            ? intl.formatMessage({
                id: 'DeviceSwitcher.noAudioInputPlaceholder'
              })
            : ''
        }
      />
      </div>

      <div className={cx('device-list')}> 
      <div className={cx('device-name')}>SPEAKERS</div>
      <Dropdown
        className={cx('dropdown')}
        controlClassName={cx('control')}
        placeholderClassName={cx('placeholder')}
        menuClassName={cx('menu')}
        arrowClassName={cx('arrow')}
        value={audioDevice == null || audioDevice == undefined ?  deviceSwitcherState.currentAudioOutputDevice : audioDevice}
        options={deviceSwitcherState.audioOutputDevices || ([] as DeviceType[])}
        disabled={
          !deviceSwitcherState.audioOutputDevices ||
          !deviceSwitcherState.audioOutputDevices.length
        }
        onChange={async (selectedDevice: DeviceType) => {


          await chime?.chooseAudioOutputDevice(selectedDevice);
          // localStorage.setItem("audio_default",JSON.stringify(selectedDevice));
          new Promise<void>(async (resolve, reject) => { 
            await setLocalStorage("audio_default",selectedDevice);
            resolve();
          });

        }}
        placeholder={
          deviceSwitcherState.currentAudioOutputDevice
            ? intl.formatMessage({
                id: 'DeviceSwitcher.noAudioOutputPlaceholder'
              })
            : ''
        }
      />
      </div>

      
    </div>
  );
}
