/* eslint-disable */
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useContext, useEffect, useState } from 'react';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import ClassMode from '../enums/ClassMode';
import localStorageKeys from "../constants/localStorageKeys.json"

export default function useFocusMode() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [focusMode, setFocusMode] = useState(false);
  const attendeeId = chime?.configuration?.credentials?.attendeeId;

  useEffect( () => {

    chime?.pubnub?.addListener({
        message: (message: any) => {

          if (localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher) {
            return;
          }
          const { type, payload } = message.message;
          if (type === 'focus' && payload) {
            chime?.audioVideo?.realtimeSetCanUnmuteLocalAudio(!payload.focus);
            if (payload.focus === true) {
              chime?.audioVideo?.realtimeMuteLocalAudio();
            }
            setFocusMode(!!payload.focus);
          } else if (type === 'mute-attendee' && payload) {
            if(attendeeId === payload.attendeeId){
              chime?.audioVideo?.realtimeSetCanUnmuteLocalAudio(!payload.focus);        
              if (payload.focus === true) {
                chime?.audioVideo?.realtimeMuteLocalAudio();
              }else{
                chime?.audioVideo?.realtimeUnmuteLocalAudio();
              }
              setFocusMode(!!payload.focus);
            }
          }

        }
    }); 

  }, [])

  return focusMode;
}
