// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */

import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import localStorageKeys from '../constants/localStorageKeys.json';
import routes from '../constants/routes.json';
import getUIStateContext from '../context/getUIStateContext';
import ClassMode from '../enums/ClassMode';
import styles from './CreateOrJoin.css';
import commonob from '../constants/common.json'
import { userAuthenticate, setLogoutTimer, isEmpty, isEmail, isPhone } from '../common_function'
import LoadingSpinner from './LoadingSpinner';
import pkgJSON from '../package.json';
import { useFeatures } from '../providers/FeatureProvider';
import { useExternalChat } from '../providers/ExternalChatProvider';

const cx = classNames.bind(styles);

export default function Login() {
  const [, dispatch] = useContext(getUIStateContext());

  const logo_name = commonob.app_name
  const history = useHistory();
  const intl = useIntl();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = React.useState({});
  const [signUpUrl, setSignUpUrl] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [forgotpassword_url, setForgotpassword_url] = useState('')
  const { external_chat } = useFeatures();
  const { makePunnubObj } = useExternalChat()

  useEffect(() => {
    localStorage.clear();
    dispatch({
      type: 'SET_CLASS_MODE',
      payload: {
        classMode: ClassMode.Student
      }
    });
  }, []);

  async function signUpURL() {
    const baseURL = commonob.getBaseUrl
    const response = await fetch(`${baseURL}user/setting?app=${commonob.app_name}`)
    const res = await response.json();
    return res;
  }

  useEffect(() => {
    new Promise(async (res) => {
      let newData = await signUpURL();
      setSignUpUrl(newData.signup_url + commonob.return_url)
      setForgotpassword_url(newData.forgotpassword_url + commonob.return_url)

      res({})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (window.location.href.includes('?action_completed=register')) {
      error["login"] = ('Please check your inbox to verify your email address.')
      setError(error)
    }
    if (window.location.href.includes('?action_completed=forgotpassword')) {
      error["login"] = ('Please check your inbox to reset your password.')
      setError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function setCreateMode() {
    localStorage.setItem(
      localStorageKeys.CLASS_MODE,
      ClassMode.Teacher
    );

    dispatch({
      type: 'SET_CLASS_MODE',
      payload: {
        classMode: ClassMode.Teacher
      }
    });
    history.push({
      pathname: routes.CREATE_OR_JOIN,
    });
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    setLoading(true)
    setError({})
    let error: any = {}
    if (!isEmail(email)) {
      setLoading(false)
      error['login'] = `Invalid ${intl.formatMessage({ id: 'UserLogin.email' })}`
    }
    if (isEmpty(password)) {
      setLoading(false)
      error['login'] = `Invalid ${intl.formatMessage({ id: 'UserLogin.password' })}`
    }
    if (isEmpty(email, password)) {
      setLoading(false)
      setError(error)
    } else {

      localStorage.setItem("userName", email);
      localStorage.setItem("userPass", password);

      // request payload
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ email: email, password: password, app: commonob.app_name })
      };

      new Promise<void>(async (resolve) => {
        // user authenticate
        let data = await userAuthenticate(requestOptions);
        if(external_chat){
          makePunnubObj(email)
        }

        if (data?.success) {
          error['success'] = 'Login Success..'
          localStorage.setItem("isAuthenticated", (true).toString());
          localStorage.setItem("isLogin", (true).toString());

          // set logout timer
          setLogoutTimer();
          setLoading(false)
          setCreateMode()
        } else {
          setLoading(false)
          if (data.msg == undefined){
            //console.log("--------------------------------")
            error['login'] = "Invalid login detail!"
            setError(error)
            return 0
          }
          // get error message from body or default to response status
          error['login'] = data.msg
        }
        setError(error)
        resolve();
      })
    }
  }

  const showPassword = () => {
    setIsPasswordShow(!isPasswordShow)
    var elem:any = document.getElementById("password");
    if (elem?.type === "password") {
      elem.type = "text";
    } else {
      elem.type = "password";
    }   
}

  return (
    <div className={cx('createOrJoin')}>
        <div className={cx('formWrapper')}>
          <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
          <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
          <h1 className={cx('title')}>
            <FormattedMessage id="UserLogin.title" />
          </h1>
          <div>
            <form onSubmit={handleSubmit}>
            <label className={cx("formLabel")}>Email</label>
              <input
                className={cx('titleInput')}
                autoFocus={true}
                onChange={event => {
                  setEmail(event.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: 'UserLogin.email'
                })}
              />
              {error['email'] ? <div className={cx('errorMessage')}>{error['email']}</div> : null}
              <div style={{position:"relative"}}>
              <label className={cx("formLabel")}>Password</label>
              <input
                id="password"
                className={cx('nameInput')}
                type="password"
                onChange={event => {
                  setPassword(event.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: 'UserLogin.password'
                })}
              />
              <img className={cx("showPassword")} src={isPasswordShow ? `/icons1/showPasswordOff.png` : `/icons1/showPasswordOn.png`} onClick={showPassword}/>
              </div>
              {error['password'] ? <div className={cx('errorMessage')}>{error['password']}</div> : null}

              <button className={cx('submitbutton')} type="submit">
              {isLoading ? <LoadingSpinner className="small" varient="white" /> : <FormattedMessage id="UserLogin.title" />}
                </button>
                <div style={{marginTop:""}}>
                <a target="_blank" className={cx('loginLink_url')} href={forgotpassword_url}>
                  <FormattedMessage id="UserLogin.forgotpassword" />
                </a>
              </div>
              {error['login'] ? <div className={cx('errorMessage')}>{error['login']}</div> : null}
              {error['success'] ? <div className={cx('successMessage')}>{error['success']}</div> : null}

            </form>
          </div>

          <div className={cx('login-bottom-link')}>
          <div>
          <a target="_blank" style={{ color: "#9BABC5" }} className={cx('loginLink')} href={signUpUrl}>
                     <FormattedMessage id="UserLogin.accnt" />
                  </a>
                  <a target="_blank" className={cx('loginLink')} href={signUpUrl}>
                    <FormattedMessage id="UserLogin.signupadvise" />
                  </a>
          </div>
          <div className={cx('joinMeetingLink')}>
            <Link style={{ float: "right" }} className={cx('loginLink')} to={routes.HOME}>
              <FormattedMessage id="UserLogin.join" />
            </Link>
          </div>
        </div>
        </div>      
    </div>
  );
}
