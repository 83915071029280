// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */

import classNames from 'classnames/bind';
import React, { useState,useContext } from 'react';
import styles from './StandAloneChat.css';
import StandAloneChatComponent from './standAloneChatComponent';
import { useExternalChat } from '../providers/ExternalChatProvider';

const cx = classNames.bind(styles);
export default function StandAloneChat() {
  const [chatChannel,setChatChannel] = useState("")
  const [currentChatUser, setCurrentChaatUser] = useState('')
  const [currentChatUserName, setCurrentChaatUserName] = useState('');
  const [currentChatUserId, setCurrentChaatUserId] = useState('')
  const [isChatOpen,setIsChatOpen] = useState(false)
  const { chatMessages } = useExternalChat();
  const { channelList,messageCounter } = chatMessages;

  return (
    <div className={cx('divContainer')}>
      {!isChatOpen && (      
        channelList.length > 0 ? 
        channelList.map((attendee:any,index:any)=>{
            let user_name = attendee.name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('').substring(0, 2);
            return(
              <div key={attendee.channel} className={cx('chatAttendeeListMainDiv')} onClick={()=>{
                setCurrentChaatUser(attendee.name)
                setCurrentChaatUserName(attendee.doctorName)
                setCurrentChaatUserId(attendee.email_id)
                setIsChatOpen(true)
                setChatChannel(attendee.channel)
                }
                }>
                <div className={cx('chatusersNameDiv')}>
                <span className={cx('chatusersName')}>{user_name}</span>
                </div>
                <div className={cx("chatAttendeeList")} >{attendee.name}</div>
                <div className={cx("lastActiveTime")}>{new Date(attendee.lastMessageTime).getHours()}:{new Date(attendee.lastMessageTime).getMinutes()}</div>
                  {messageCounter.map((item:any,index:any)=>{
                    if(item.channel == attendee.channel){
                      if (item.count > 0){
                        return <div key={index} className={cx("msgCounter")}>{item.count}</div>
                      }
                      else{
                        return;
                      }
                    }else{
                      return;
                    }
                  })}
                  {/* <div style={{color:"green"}}>{messageCounter[attendee.email_id].count}</div> */}
              </div>
            )
          })
        : 
          <div className={cx("noFoundData")}>No data found!</div>     
      )       
      }

      {isChatOpen &&
        <StandAloneChatComponent 
        currentChatUser={currentChatUser}
        currentChatUserName={currentChatUserName}
        currentChatUserId={currentChatUserId}
        channelName={chatChannel}
        isChatOpen={(flag:any)=>{
          setIsChatOpen(flag)
        }}
        />
      }
    </div>
  );
}