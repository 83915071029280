/* eslint-disable */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import routes from '../constants/routes.json';
import getChimeContext from '../context/getChimeContext';
import ClassMode from '../enums/ClassMode';
import ViewMode from '../enums/ViewMode';
import styles from './Controls.css';
import Tooltip from './Tooltip';
import HandlePopUp from './HandlePopUp';
import commonob from '../constants/common.json';
import CallRecording from './CallRecording';
import getGlobalVarContext from '../context/getGlobalVarContext';
import localStorageKeys from "../constants/localStorageKeys.json"

const cx = classNames.bind(styles);

enum VideoStatus {
  Disabled,
  Loading,
  Enabled
}

type Props = {
  tab: number;
  title: string
  viewMode: ViewMode;
  messageCounter: number;
  isSmallScreen: boolean;
  isRightBarEnabled: boolean;
  isContentShareEnabled: boolean;
  onClickShareButton: () => void;
  onClickChatButton: () => void;
  showHideRightPanel: () => void;
  setTabToParent: (id: number) => void;
  onClikcEnableCopyMeeting: () => void;
};
var started = "";
var timer:any = 0;

export default function Controls(props: Props) {
  const {
    tab,
    title,
    viewMode,
    messageCounter,
    setTabToParent,
    onClickShareButton,
    onClickChatButton,
    showHideRightPanel,
    onClikcEnableCopyMeeting,
  } = props;

  const intl = useIntl();
  const history = useHistory();

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());

  const [muted, setMuted] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(false);
  const [zooming, setZooming] = useState(false);
  const [videoStatus, setVideoStatus] = useState(VideoStatus.Disabled);
  const [recordFlag, setRecordFlag] = useState(0);
  const publicChannel = `${commonob.app_name}-chat-${title}`;
  const attendeeId = chime?.configuration?.credentials?.attendeeId;
  const { globalVar,setGlobalVar } = useContext(getGlobalVarContext());
  const {fullScreenMode,recordInProcess,recordingUrls} = globalVar;

  useEffect(() => {
    const callback = (localMuted: boolean) => {
      setMuted(localMuted);
      setGlobalVariables('localMute', localMuted)
    };
    chime?.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
    return () => {
      if (chime && chime?.audioVideo) {
        chime?.audioVideo?.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(
          callback
        );
      }
    };
  }, []);

  useEffect(()=>{
    let msgType = ((recordFlag == 2 || recordFlag == 0) && !recordInProcess)
    ? false
    : true
    let msg = {
      payload: {
        focus: msgType,
        message: msgType
      },
      timestampMs: Date.now(),
      type: "record"
    }
    chime?.pubnub?.publish({ channel: publicChannel, message: msg })
  },[recordFlag,recordInProcess])

  const setGlobalVariables = (prop: string, value: any) => {

    switch (prop) {
      case "fullScreenMode":
        setGlobalVar((oldData) => ({ ...oldData, fullScreenMode: value }))
        break;
      case "localMute":
        setGlobalVar((oldData) => ({ ...oldData, localMute: value }))
        break;
      case "localVideo":
        setGlobalVar((oldData) => ({ ...oldData, localVideo: value }))
        break;
      case "totalMeetingMinutes":
        setGlobalVar((oldData) => ({ ...oldData, totalMeetingMinutes: value }))
        break;        
      default:
        // code...
        break;
    }
  }

  const getMeetingDuration = () => {
    let totalSec= new Date().getTime() - new Date(parseInt(localStorage.getItem("meetingStartTime"))).getTime();
    var minutes = Math.floor((totalSec) / (1000 * 60));
    setGlobalVariables('totalMeetingMinutes', minutes)
    return minutes;
  }


  async function endMeeting() {
    chime?.leaveRoom(true,recordingUrls,getMeetingDuration());
    history.push(routes.HOME); 
  }

const mainTainFullScreen = ()=> {
  var elem:any = document.documentElement;
  if (!zooming){
    setGlobalVariables('fullScreenMode', true)
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }else{
    setGlobalVariables('fullScreenMode', false)
    exitFullscreen()
  }
  setZooming(!zooming)
  }

  const exitFullscreen = ()=> {
    if(fullScreenMode){
      var elem1:any = document
      if (elem1.exitFullscreen) {
        elem1.exitFullscreen();
      } else if (elem1.webkitExitFullscreen) { /* Safari */
        elem1.webkitExitFullscreen();
      } else if (elem1.msExitFullscreen) { /* IE11 */
        elem1.msExitFullscreen();
      }
    }
  }

  useEffect(()=>{
    if(fullScreenMode){
      setZooming(fullScreenMode)
    }else{
      setZooming(fullScreenMode)
    }
  },[fullScreenMode])

  return (
    <>
      <HandlePopUp
        isHost={localStorage.getItem(localStorageKeys.CLASS_MODE) == ClassMode.Teacher ? true : false}
        isOpen={value}
        onCancel={() => {
          setValue(!value)
          clearTimeout(timer)
        }}
        onEnding={async() => {
          await exitFullscreen()
          await endMeeting()
        }}
        onLeave={() => {
          exitFullscreen()
          if(localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher){
            chime?.leaveRoom(false,recordingUrls,getMeetingDuration());
          }else{
            chime?.leaveRoom(false,[],0);
          }
          localStorage.setItem('meeting_url', null)
          history.push(routes.HOME);
        }}
      />
      <div
        className={cx('controls', {
          roomMode: viewMode === ViewMode.Room,
          screenShareMode: viewMode === ViewMode.ScreenShare,
          videoEnabled: videoStatus === VideoStatus.Enabled,
          audioMuted: muted
        })}
      >

        <div className={cx('button-left-side')}>
          {/* {viewMode === ViewMode.ScreenShare && ( */}
          <Tooltip
            tooltip={
              muted
                ? intl.formatMessage({ id: 'Controls.unmuteTooltip' })
                : intl.formatMessage({ id: 'Controls.muteTooltip' })
            }
          >
            <div className={cx('conrolIconDiv')}>
              <img
                onClick={async () => {
                  if (muted) {
                    chime?.audioVideo?.realtimeUnmuteLocalAudio();
                  } else {
                    chime?.audioVideo?.realtimeMuteLocalAudio();
                  }
                  // Adds a slight delay to close the tooltip before rendering the updated text in it
                  await new Promise(resolve => setTimeout(resolve, 10));
                }}

                src={muted ? (
                  '/icons1/mic-mute.png'
                ) : (
                  '/icons1/mic-unmute.png'
                )} />
            </div>
          </Tooltip>
          {/* )}


          {viewMode === ViewMode.ScreenShare && ( */}
          <Tooltip
            tooltip={
              videoStatus === VideoStatus.Disabled
                ? intl.formatMessage({ id: 'Controls.turnOnVideoTooltip' })
                : intl.formatMessage({ id: 'Controls.turnOffVideoTooltip' })
            }
          >
            <div className={cx('conrolIconDiv')}>
              <img
                onClick={async () => {
                  // Adds a slight delay to close the tooltip before rendering the updated text in it
                  await new Promise(resolve => setTimeout(resolve, 10));
                  if (videoStatus === VideoStatus.Disabled) {
                    setVideoStatus(VideoStatus.Loading);
                    try {
                      if (!chime?.currentVideoInputDevice) {
                        throw new Error('currentVideoInputDevice does not exist');
                      }
                      await chime?.chooseVideoInputDevice(
                        localStorage.getItem("video_default") != null && localStorage.getItem("video_default") != undefined ? JSON.parse(localStorage.getItem("video_default")) : chime?.currentVideoInputDevice
                      );
                      chime?.audioVideo?.startLocalVideoTile();
                      setGlobalVariables('localVideo', true)
                      setVideoStatus(VideoStatus.Enabled);
                    } catch (error) {
                      // eslint-disable-next-line
                      console.error(error);
                      setVideoStatus(VideoStatus.Disabled);
                      setGlobalVariables('localVideo', false)
                    }
                  } else if (videoStatus === VideoStatus.Enabled) {
                    setVideoStatus(VideoStatus.Loading);
                    chime?.audioVideo?.stopLocalVideoTile();
                    setVideoStatus(VideoStatus.Disabled);
                  }
                }}
                src={videoStatus === VideoStatus.Enabled ? (
                  '/icons1/video-on.png'
                ) : (
                  '/icons1/video-off.png'
                )}
              />
            </div>
          </Tooltip>
          {/* )} */}
          {localStorage.getItem(localStorageKeys.CLASS_MODE) == ClassMode.Teacher && viewMode === ViewMode.Room && (
            <Tooltip
              tooltip={
                focus
                  ? intl.formatMessage({ id: 'Controls.turnOffFocusTooltip' })
                  : intl.formatMessage({ id: 'Controls.turnOnFocusTooltip' })
              }
            >
              <div className={cx('conrolIconDiv')}>
                <img onClick={() => {
                  const newFocusState = !focus;
                  let msg = {
                    name: chime?.roster[attendeeId].name,
                    payload: {
                      focus: newFocusState,
                      message: "mute-attendee"
                    },
                    timestampMs: Date.now(),
                    type: "focus"
                  }
                  chime?.pubnub?.publish({ channel: publicChannel, message: msg })

                  let msg1 = {
                    name: chime?.roster[attendeeId].name,
                    payload: {
                      attendeeId: chime?.configuration?.credentials?.attendeeId,
                      message: newFocusState
                        ? intl.formatMessage({ id: 'Controls.focusOnMessage' })
                        : intl.formatMessage({ id: 'Controls.focusOffMessage' })
                    },
                    timestampMs: Date.now(),
                    type: "auto-focus"
                  }
                  chime?.pubnub?.publish({ channel: publicChannel, message: msg1 })
                  setFocus(newFocusState);
                }}
                  src={focus ? "/icons1/auto-focus-on.png" : "/icons1/auto-focus.png"} />
              </div>
            </Tooltip>
          )}

        </div>

        <div className={cx('button-center')}>
          <Tooltip
            tooltip={intl.formatMessage({ id: 'Controls.endMeetingTooltip' })}
          >
            <div className={cx('endMeetingDiv')}>
              <img className={cx('float-left')}
                onClick={() => { 
                  setValue(!value);
                  timer = setTimeout(()=>{
                    setValue(false)
                    clearTimeout(timer)
                  },5000) 
                }}
                src="/icons1/signout.png"
              />
            </div>
          </Tooltip>
        </div>
        <div className={cx('button-right-side')} >
          {/* {
            viewMode !== ViewMode.ScreenShare && ( */}
          <Tooltip
            tooltip={intl.formatMessage({ id: 'Controls.shareScreenTooltip' })}
          >
            <div className={cx('conrolIconDiv')}>
              <img
                onClick={() => {
                  onClickShareButton();
                }}
                src="/icons1/screen-share-off.png"
              />
            </div>
          </Tooltip>
          <Tooltip tooltip={intl.formatMessage(!zooming ? { id: 'Controls.FullScreen' } : { id: 'Controls.CloseFullScreen' })}>
                  <div
                    className={cx('conrolIconDiv')}>
                    <img
                      onClick={mainTainFullScreen}
                      src="/icons1/fullscreen.png"
                    />
                  </div>
                </Tooltip>
          {/* )} */}          

          {localStorage.getItem(localStorageKeys.CLASS_MODE) == ClassMode.Teacher && viewMode === ViewMode.Room && (
                  <div className={cx('conrolIconDiv')}>
                    <Tooltip
                      tooltip={
                        ((recordFlag == 2 || recordFlag == 0) && !recordInProcess)
                          ? intl.formatMessage({ id: 'Controls.turnOnRecording' })
                          : intl.formatMessage({ id: 'Controls.turnOffRecording' })
                      }>
                      <img className={cx('float-left')}
                        onClick={() => {
                          if (!recordInProcess) {
                            if (recordFlag == 0) { started = "true"; setRecordFlag(1) }
                            else if (recordFlag == 1) { started = "false"; setRecordFlag(2); }
                            else if (recordFlag == 2) { started = "true"; setRecordFlag(1) }
                          }
                        }}
                        src={((recordFlag == 2 || recordFlag == 0) && !recordInProcess) ?
                          '/icons1/recording_on.png'
                          :
                          '/icons1/recording_off.png'
                        } />
                    </Tooltip>

                    {recordFlag == 1 && started === "true" && (
                      <CallRecording recordFlag={recordFlag} callBackFromParent={(flag: any) => { setRecordFlag(flag) }}
                        manageStart={(status: string) => {
                          started = status;
                        }} />
                    )}
                    {recordFlag == 2 && started === "false" && (
                      <CallRecording recordFlag={recordFlag}  callBackFromParent={(flag: any) => { setRecordFlag(flag) }}
                        manageStart={(status: string) => {
                          started = status;
                        }}
                      />
                    )}
                  </div>
                )}
        </div>
      </div>
    </>
  );
}
