// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import React, { useMemo, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import commonob from "../constants/common.json"
import ExternalChatType from '../types/ExternalChatType';
import MessageType from '../types/MessageType';
import { useFeatures } from '../providers/FeatureProvider';
import PubNub from 'pubnub';
import { globalApiService } from "../common_function"

type Props = {
  children: ReactNode;
};

const defaultValue = {};

const ExternalChatContext = createContext<any>(defaultValue);
var initialMessageCntCall = false;
var msgChlList:any [];
export default function ExternalChatProvider({ children }: Props) {
  const [chatMessages, setChatMessages] = useState({
    messageCounter: [],
    channelList: [],
    messages: [],
    whoIsTyping: [],
    totalMessageCounter: 0
  })
  const realTimeMessages: MessageType[] = [];
  const [pubnubStandAlone, setPubnubStandAlone]: any = useState()
  const user_email: any = localStorage.getItem("userName")
  const { external_chat } = useFeatures();

  useEffect(() => {
    makePunnubObj()
    
    return () => {
      initialMessageCntCall = false;
      msgChlList = [];
    }
  }, [])

  useEffect(() => {
    if (pubnubStandAlone != undefined) {
      pubnubStandAlone.addListener({
        message: async (msg: any) => {
          let obj = {
            channel: msg.channel.replace(/@/g, "%40"),
            child: msg.message.child,
            name: msg.message.name,
            payload: msg.message.payload,
            timestampMs: msg.message.timestampMs,
            type: msg.message.type
          };
          listMessages(obj)
          let messageCnt = chatMessages.messageCounter;
          let ttlCnt = 0;
          messageCnt.map((item) => {
            ttlCnt = ttlCnt + item.count
            if (item.channel === msg.channel) {
              item.count = item.count + 1
            }
          })
          setChatMessages((oldState) => ({ ...oldState, messageCounter: messageCnt }))
          setChatMessages((oldState) => ({ ...oldState, totalMessageCounter: ttlCnt + 1 }))
          msgChlList.map((item) => {
            if (item.channel === msg.channel) {
              item.lastMessageTime = msg.message.timestampMs
            }
          })
          msgChlList.sort(function (a:any, b:any) {
            return b.lastMessageTime.toString().localeCompare(a.lastMessageTime.toString());
          });
          setChatMessages((oldState) => ({ ...oldState, channelList: msgChlList }))

        },
        signal: async (signal: any) => {
          setChatMessages((oldState) => ({ ...oldState, whoIsTyping: signal }))
        }
      });
      getInitialChatAttendeesList()
    }
  }, [pubnubStandAlone])


  const initialMessageCnt = (channelList) => {
    if (pubnubStandAlone != undefined) {
      let logoutTime = localStorage.getItem("lastActiveTime") || (parseInt(localStorage.getItem("dateTimeLogout")) * 10000).toString()
      if (logoutTime != null && logoutTime != undefined && logoutTime != "0") {
        initialMessageCntCall = true;
        pubnubStandAlone.messageCounts({
          channels: [channelList],
          channelTimetokens: [logoutTime]
        }).then(async (response) => {
          let messageCnt = chatMessages.messageCounter;
          let ttlCnt = 0;
          messageCnt.map((item) => {
            item.count = response.channels?.[item.channel]
            ttlCnt = ttlCnt + response.channels?.[item.channel];
          })
          setChatMessages((oldState) => ({ ...oldState, messageCounter: messageCnt }))
          setChatMessages((oldState) => ({ ...oldState, totalMessageCounter: ttlCnt }))
        }).catch((error) => {
          // console.log("error",error)
        }
        );
      }
    }
  }

  const makePunnubObj = (email?: any) => {
    if ((user_email != null && user_email != undefined) || email != undefined) {
      var pubnubObj = new PubNub({
        publishKey: commonob.pubnub.publishKey,
        subscribeKey: commonob.pubnub.subscribeKey,
        uuid: user_email || email,
      })
      setPubnubStandAlone(pubnubObj)
    }
  }

  useEffect(() => {
    if (external_chat && user_email != undefined && user_email != null) {
      var socket = new WebSocket(`${commonob.chatWebSocketUrl}?user_id=` + user_email);
      socket.onopen = function (e) {
        console.log("[open] Connection established");
      };

      //Receiving Packet on Socket
      socket.onmessage = function (event) {
        var data = JSON.parse(event.data);
        if (user_email == data.target_id) {
          // subScribePubnubChannel(data.message)
          getInitialChatAttendeesList()
        }
      };
    }
  }, [external_chat])

  const getInitialChatAttendeesList = async(email?: any) => {
    let usr_email = user_email || email

    var requestOption = {
        "method":"POST",
        "body":JSON.stringify({"email":usr_email}),
        "url":`${commonob.getBaseUrl}listChatAttendee`
      }

      try{
        let rs:any = await globalApiService(requestOption)
        if (rs.length > 0) {
            let msgCntList = chatMessages.messageCounter;
            let channels: any = []
            rs.map((item: any) => {
              item.lastMessageTime = new Date().getTime()
              channels.push(item.channel)
              msgCntList.push({ channel: item.channel, count: 0, logoutTime: (new Date().getTime() * 10000), isTyping: "" })
            })
            msgChlList = rs;
            setChatMessages((oldState) => ({ ...oldState, messageCounter: msgCntList }))
            setChatMessages((oldState) => ({ ...oldState, channelList: rs }))
            subScribeChannel(channels)
            if (!initialMessageCntCall) {
              initialMessageCnt(channels)
            }
          }
      }catch (e){
        console.log(e)
      }
  }

  const subScribeChannel = (channelName) => {
    channelName.map((channel) => {
      pubnubStandAlone.subscribe(
        {
          channels: [channel], //Only one channel, split in different rows if required and load in props, can be set by load balancer.
          withPresence: true,
        }
      )
      pubnubStandAlone?.fetchMessages(
        {
          channels: [channel],
          count: 100
        },
        (status: any, response: any) => {
          // handle response
          response?.channels?.[channel.replace(/@/g, "%40")].forEach((data: any) => {
            data.message['channel'] = data.channel
            listMessages(data.message)
          })
        }
      );
    })
  }

  const listMessages = (message: MessageType) => {
    realTimeMessages.push(message);
    setChatMessages((oldState) => ({ ...oldState, messages: realTimeMessages.slice() as MessageType[] }))
  }

  return (
    <ExternalChatContext.Provider value={{ chatMessages, setChatMessages,makePunnubObj }}>
      {children}
    </ExternalChatContext.Provider>
  );
}

export function useExternalChat() {
  const context = useContext(ExternalChatContext);
  return context;
}