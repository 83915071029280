// common_function.ts is the common function file
// use for re-use all the functions
/* eslint-disable  */

import commonob from './constants/common.json'


const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
var request = require('request');

// method to check user credentials
export const userAuthenticate = async (requestOptions) => {
	let url = commonob.getBaseUrl + commonob.sigin_endpoint;
	let response = await fetch(url, requestOptions)
	let res = await response.json()
	localStorage.setItem('personalData', res.access_token)
	return res;
};

export const checkValidToken = async (token) => {
	let options = {
		method: 'POST',
		body: JSON.stringify({
		"access_token": token
		})
	};
	let url = `${commonob.admin_url}/user/profile`;

	let response = await fetch(url,options)
	console.log(response)
	let res = await response.json()
	localStorage.setItem('personalData', token);
	let email = res['UserAttributes'].filter(item => item.Name === "email");
	localStorage.setItem('userName', email[0].Value);
	return response;
  };


// set logout timer
export const setLogoutTimer = () => {
	localStorage.removeItem("dateTimeLogout");
	let date = new Date();
	// add 24 hours
	date.setDate(date.getDate() + 1);
	localStorage.setItem("dateTimeLogout", (new Date(date).getTime()).toString());
}


// check empty variable
export const isEmpty = (...data) => {

	// loop for variable to check value set or not	
	for (let i = 0; i < data.length; i++) {
		if (!data[i]) return true;
	}
	return false;
}

// validate email
export const isEmail = (email) => {
	return emailRegex.test(email);
}

// validate phone number
export const isPhone = (phone) => {

	return phoneRegex.test(phone);

}

// create cipher key for chat
export const createCipherKey = (title: string) => {

	return "cipher-key" + '-' + title;

}

export const shortMeetingUrl = (title) => {
	let long_url = commonob.meeting_url_start_point + title + commonob.meeting_url_end_point
	return new Promise((resolve, reject) => {
		var raw = JSON.stringify(
			{ 
				"longDynamicLink": long_url
			}
			);

		var requestOptions:any = {
			method: 'POST',
			body: raw,
		};

		fetch(`${commonob.firebase.api_url}${commonob.firebase.key}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.shortLink == "" || result.shortLink == undefined || result.shortLink == null) {
				} else {
					resolve(result.shortLink)
				}
			})
			.catch(error => console.log('error', error));
	})

}

export const globalApiService = async (options) => {

	return await new Promise((resolve, reject) => {
		request(options, async function (error: any, response: any) {
			if(error){
			  console.log(error)
			  reject(error)
			}
			let rs = JSON.parse(response.body)
			resolve(rs)
		  })
	});

}