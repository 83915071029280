//  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import React, { ReactNode, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import App from './components/App';
import Classroom from './components/Classroom';
import CreateOrJoin from './components/CreateOrJoin';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import routes from './constants/routes.json';
import getUIStateContext from './context/getUIStateContext';
import MeetingStatusProvider from './providers/MeetingStatusProvider';
import RosterProvider from './providers/RosterProvider';
import DressingRoom from './components/DressingRoom';

export default function Routes() {
  const [state] = useContext(getUIStateContext());

  const PrivateRoute = ({
    children,
    path
  }: {
    children: ReactNode;
    path: string;
  }) => {
    
    return (
      <Route path={path}>
        {state.classMode ? children : <Redirect to={routes.LOGIN} />}
      </Route>
    );
  };

  return (
    <App>
      <Switch>

       <PrivateRoute path={routes.CLASSROOM}>
         <MeetingStatusProvider>
           <RosterProvider>
               <Classroom />
           </RosterProvider>
         </MeetingStatusProvider>
       </PrivateRoute>

       <PrivateRoute path={routes.DRESSING_ROOM}>
                <DressingRoom />
        </PrivateRoute>


        <Route path={routes.CREATE_OR_JOIN}>
            <CreateOrJoin />
        </Route> 
        
        <Route path={routes.LOGIN}>
          <Login />
        </Route>

       <Route path={routes.HOME}>
          <Home />
        </Route> 

        <Route path={routes.SIGNUP}>
          <Signup />
        </Route> 

      </Switch>
    </App>
  );
}




