// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */

import classNames from 'classnames/bind';
import React, { useEffect, useState, useContext } from 'react';
import styles from './StandAloneChat.css';
import MessageType from '../types/MessageType';
import moment from 'moment';
import commonob from "../constants/common.json"
import LoadingSpinner from './LoadingSpinner';
import { useExternalChat } from '../providers/ExternalChatProvider';
import PubNub from 'pubnub';

const cx = classNames.bind(styles);
type Props = {
  currentChatUser: string;
  currentChatUserId: string;
  currentChatUserName: string;
  channelName: string;
  isChatOpen: (flag: any) => void;
};

var msgTimeout:any;

export default function StandAloneChatComponent(props: Props) {

  const { currentChatUser, currentChatUserId, isChatOpen, channelName, currentChatUserName } = props;
  var user_id: any = localStorage.getItem("userName");
  const [inputText, setInputText] = useState('')
  const [isTyping, setIsTyping] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [channelMessages, setChannelMessages] = useState<MessageType[]>([]);
  const realTimeMessages: MessageType[] = [];
  const { chatMessages, setChatMessages } = useExternalChat();
  const { messages,messageCounter,whoIsTyping } = chatMessages;

  const pubnubStandAlone = new PubNub({
    publishKey: commonob.pubnub.publishKey,
    subscribeKey: commonob.pubnub.subscribeKey,
    uuid: user_id,
  })

  const sendMessage = async () => {
    const sendingMessage = inputText.trim();

    let msg = {
      name: currentChatUserName,
      payload: {
        attendeeId: user_id,
        message: sendingMessage,
        receivedBy: currentChatUserId
      },
      timestampMs: Date.now(),
      type: 'pvt-chat-message',
      child: false
    }

    await pubnubStandAlone.publish({ channel: channelName, message: msg })
    setInputText('')
    sendSignal(false, channelName)
  }


  useEffect(() => {
    setChannelMessages(messages.filter((item:any)=>item.channel === channelName.replace(/@/g, "%40")))
    setTimeout(() => {
      var pvtMsg = document.getElementById('standAlonePvtMsg');
      pvtMsg.scrollTop = pvtMsg.scrollHeight;
    }, 10);
  }, [messages])

  useEffect(()=>{
    if(whoIsTyping.channel === channelName && whoIsTyping.message.isTyping){
      setIsTyping(whoIsTyping.message.name)
    }
    setTimeout(()=>{
      setIsTyping("")
    },1000)

    let messageCnt = messageCounter;
    messageCnt.map((item)=>{
      if(item.channel === channelName){
        item.count = 0;
      }
    })
    setChatMessages((oldState)=>({...oldState,messageCounter:messageCnt}))

  },[messages])

  // seinding signal typing message 
  const sendSignal = (type: boolean, channel: string) => {
    pubnubStandAlone?.signal({
      channel: channel,
      message: {
        isTyping: type,
        to: currentChatUserId,
        name:currentChatUserName
      }
    });
  }

  let hideTypingIndicator = () => {
    sendSignal(false, channelName)
}

  return (
    <div className={cx('chatStandAlonwDivContainer')}>
      <div className={cx('attendeesTabtitle')}>
        <div className={cx('TitleHeader')}>
          <img onClick={() => {
            isChatOpen(false)
          }}
            src={'/icons1/backArrow.png'}
          />
        </div>
        <h3 className={cx('titlename')}> {currentChatUser}
        </h3>
      </div>

      <div key={"test"} className={cx('chat', {
        margintop: true
      })}>
        <div className={cx('userlistMain')}>
          <div
            className={cx('messages')} id='standAlonePvtMsg'>
              {channelMessages.length > 0 ? 
                   channelMessages.map((message, index) => {
                    let messageString = message.payload.message;
                    let user_name = message.name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('').substring(0, 2);
                    {
                      return (
                        <div key={index}>
                          {message.payload.attendeeId === user_id ?
                            <div className={cx('messageWrapperSenderDiv')}>
                              {
                                <div
                                  key={message.timestampMs}
                                  // style={{ marginRight: '-11px' }}
                                  className={cx('messageWrapperSender', {
                                    raiseHand: message.type === 'raise-hand'
                                  })}
                                >
                                  <>
                                    <div className={cx('msgRight', {
      
                                      blueColor: message.payload.attendeeId == user_id,
                                      orangeColor: message.payload.attendeeId != user_id
      
                                    })}>
                                      <span className={cx('name')}>
                                        {
                                          user_name
                                        }
                                      </span>
                                    </div>
                                    <div className={cx('msgLeft')}>
                                      <div className={cx('senderName')}>
                                        {message.name}
                                      </div>
                                      {(messageString.includes('https://') || messageString.includes('http://')) ?
                                        <div style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={() => {
                                          window.open(messageString)
                                        }} className={cx('message')}>{messageString}</div>
                                        : <div className={cx('message')}>{messageString}</div>}
                                    </div>
                                    <span className={cx('timecolor')} >
                                      {moment(message.timestampMs).format('h:mm A')}
                                    </span>
                                  </>
                                </div>
                              }
                            </div>
                            :
                            <div className={cx('messageWrapperReceiverDiv')}>
                              {
      
                                <div
                                  key={message.timestampMs}
                                  style={{ marginLeft: '-15px' }}
                                  className={cx('messageWrapperReceiver', {
                                    raiseHand: message.type === 'raise-hand'
                                  })}
                                >
                                  <>
                                    <div className={cx('msgRight', {
      
                                      blueColor: message.payload.attendeeId == user_id,
                                      orangeColor: message.payload.attendeeId != user_id
      
                                    })}>
                                      <span className={cx('name')}>
                                        {user_name}
                                      </span>
                                    </div>
                                    <div className={cx('msgLeft')}>
                                      <div className={cx('senderName')}>
                                        {message.name}
      
                                      </div>
                                      {(messageString.includes('https://') || messageString.includes('http://')) ?
                                        <div style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={() => {
                                          window.open(messageString)
                                        }} className={cx('message')}>{messageString}</div>
                                        : <div className={cx('message')}>{messageString}</div>}
                                    </div>
                                    <span className={cx('timecolor')} >
                                      {moment(message.timestampMs).format('h:mm A')}
                                    </span>
                                  </>
                                </div>
                              }
                            </div>
                          }
                        </div>
                      );
                    }
                  })
              : isLoading && <LoadingSpinner className={"small"}/>}      
          </div>
        </div>

      </div>
      <div
        className={cx('istyping')}>
        {isTyping != "" && (
          <>
            <span>{isTyping} </span>
            <img className={cx('typing')} src="/typing.gif" />
          </>
        )
        }

      </div>
      <div className={cx('chatInput')}>
        <span className={cx('inputSpan')}>
          <input
            className={cx('input')}
            value={inputText}
            onChange={event => {
              setInputText(event.target.value);
            }}
            onKeyUp={event => {
              event.preventDefault();

            // set timeout for each signal request
            if(msgTimeout != null && msgTimeout != undefined)  clearTimeout(msgTimeout);
            msgTimeout = setTimeout( () => hideTypingIndicator(), 3000)

              if (event.keyCode === 13 && inputText.length > 0) {
                sendMessage()
              }else {
                
                // send signal while no tab pressed
                if(event.keyCode !== 91){
                  let  inputHasText = inputText.length > 0;                    
                    // signal msg typing                  
                    sendSignal(inputHasText ? true : false, channelName)                  
                }

              }
            }}
            placeholder="Write now...."
          />
          <button className={cx('sendMessageButtton')} type="submit" onClick={event => {
            event.preventDefault();
            sendMessage()
          }}>
            <img src={'/icons1/sendMsg.png'} />
          </button>
        </span>
      </div>
    </div>
  );
}