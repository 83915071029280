import React, { useMemo, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import commonob from "../constants/common.json"
import Feature from '../types/FeatureType';
import { globalApiService } from "../common_function";

type Props = {
  children: ReactNode;
};

const defaultValue = {
  vod: false,
  document_upload: false,
  chat: false,
  live_stream: false,
  formstack: false,
  cloud_recording:false,
  external_chat:false
};

const FeatureContext = createContext<Feature>(defaultValue);

export default function FeatureProvider({ children }: Props) {
    const [features,setFeatures] = useState({
        vod: false,
        document_upload: false,
        chat: false,
        live_stream: false,
        formstack: false,
        cloud_recording:false,
        external_chat:false
      })

     useEffect(()=>{
      async function getFeatures(){
        var options = {
          'method': 'GET',
          'url': `${commonob.admin_url}/features`
        };

        try{
          let result:any = await globalApiService(options);
          let feature_list = result[0].features_list;
          let obj =  {
            vod: feature_list.includes("vod"),
            document_upload: feature_list.includes("document_upload"),
            chat: feature_list.includes("chat"),
            live_stream: feature_list.includes("live_stream"),
            formstack: feature_list.includes("formstack"),
            cloud_recording: feature_list.includes("cloud_recording"),
            external_chat: feature_list.includes("external_chat")
          };
          setFeatures(obj)
        } catch (e){
          console.log(e)
        }
      }
      getFeatures()
     },[]) 

  return (
    <FeatureContext.Provider value={features}>
      {children}
    </FeatureContext.Provider>
  );
}

export function useFeatures() {
  const context = useContext(FeatureContext);
  return context;
}
