// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import React, { ReactNode, useState, useReducer,useEffect } from 'react';
import getGlobalVarContext from '../context/getGlobalVarContext';

type Props = {
  children: ReactNode;
};

export default function GlobalVarProvider(props: Props) {
  
  const { children } = props;
  const GlobalVarContext = getGlobalVarContext();
  const [globalVar, setGlobalVar] = useState({
  		activeAttendee: "",
      attendeeIdFullScreenActive:"",
      tileView:false,
      isRightBarSectionEnabled:false,
      localMute:false,
      localVideo:false,
      recordInProcess:false,
      isRecordStart:false,
      fullScreenMode:false,
      recordingUrls:[]
  }); 
  
  document.addEventListener('fullscreenchange', exitHandler, false);
  document.addEventListener('mozfullscreenchange', exitHandler, false);
  document.addEventListener('MSFullscreenChange', exitHandler, false);
  document.addEventListener('webkitfullscreenchange', exitHandler, false);
  document.addEventListener('keydown',(event)=>{
    if (event.which == 122) {
      event.preventDefault();
      exitHandler
  }
  });

  function exitHandler(){
    let element:any = document;
    if (element.webkitIsFullScreen === false){
      setGlobalVar((oldData) => ({ ...oldData, fullScreenMode: false }))
    }else if (element.mozFullScreen === false){
      setGlobalVar((oldData) => ({ ...oldData, fullScreenMode: false }))
    }else if (element.msFullscreenElement === false){
      setGlobalVar((oldData) => ({ ...oldData, fullScreenMode: false }))
    }
    } 

    return (
    <GlobalVarContext.Provider value={{ globalVar, setGlobalVar }}>
    	{children}
    </GlobalVarContext.Provider>
  );

}
