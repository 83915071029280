import classNames from 'classnames/bind';
import React, { useEffect, useContext, useState } from 'react';
import styles from './WaitRoom.css';
import { useHistory } from 'react-router-dom';
import commonob from '../constants/common.json'
import pkgJSON from '../package.json'
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import { FormattedMessage } from 'react-intl';
import routes from '../constants/routes.json';
import LoadingSpinner from './LoadingSpinner';
import { globalApiService } from "../common_function";

interface Props {
  timer?: boolean;
  time?: any;
  title?: any;
  isHost?: any;
  goingBack:any;
}
const cx = classNames.bind(styles);

export default function WaitRoom(props: Props) {
  const {
    timer,
    time,
    title,
    isHost,
    goingBack
  } = props;

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [enableButton, setEnableButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  useEffect(() => {
    if (timer == true) {
      var countDownDate = time;

      // Update the count down every 1 second
      var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let hour1: any = document.getElementById('demo1')
        let hour2: any = document.getElementById('demo2')
        let min1: any = document.getElementById('demo3')
        let min2: any = document.getElementById('demo4')
        let sec1: any = document.getElementById('demo5')
        let sec2: any = document.getElementById('demo6')

        let h1 = ('0' + hours).slice(-2)
        let m1 = ('0' + minutes).slice(-2)
        let s1 = ('0' + seconds).slice(-2)

        // Display the result in the element with id="demo"
        if ((hour1 && hour2 && min1 && min2 && sec1 && sec2) != null) {
          hour1.innerHTML = h1.slice(0, 1);
          hour2.innerHTML = h1.slice(1, 2);
          min1.innerHTML = m1.slice(0, 1);
          min2.innerHTML = m1.slice(1, 2);
          sec1.innerHTML = s1.slice(0, 1);
          sec2.innerHTML = s1.slice(1, 2);
        }


        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          setIsLoading(true)
          // clock.innerHTML = "EXPIRED";
        }
      }, 1000);
    }
  })


  const handleStart = async() => {
    setEnableButton(true)

    var options = {
      'method': 'GET',
      'url': `${commonob.getBaseUrl}meeting_schedule?meetingId=${title}`
    };

    try{
      let result:any = await globalApiService(options);
      let selectedStart:any = new Date()
      let selectedEnd:any = new Date(selectedStart.getTime() + 3600000)

      let body = {
        "id": `${result[0].Title}`,
        "created_ts": `${result[0].created_ts}`,
        "from_ts": `${(Math.round(selectedStart / 1000))}`,
        "to_ts": `${(Math.round(selectedEnd / 1000))}`,
        "created_by":`${localStorage.getItem("userName")}`
      }
      var options1 = {
        'method': 'POST',
        'body': JSON.stringify(body),
        'url': `${commonob.admin_url}/meeting_schedule/update`
      };

      try{
        let rs:any = await globalApiService(options1);
        let body = {
          "id": result[0].Title,
          "title": result[0].meeting_heading,
          "location": result[0].meeting_url,
          "calendar_id": localStorage.getItem('nylas_calendar_id'),
          "busy": true,
          "participants": result[0].participant,
          "description": `
            <html><head><meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\"></head>
            <body>
            <p>Start Time: ${(new Date(new Date())).toLocaleString('en', { timeZone: result[0].timezone })}</p>
            <p>End &nbspTime: ${(new Date(new Date(new Date().getTime() + 3600000))).toLocaleString('en', { timeZone: result[0].timezone })}</p>
            <p>Timezone: ${result[0].timezone}</p>
            <p><a href=\"${result[0].meeting_url}\">Click here to join the meeting</a></p>
            <p>Description: ${result[0].description}</p>
            </body></html>`,
          "when": {
            "start_time": (Math.round(selectedStart / 1000)),
            "end_time": Math.round(selectedEnd / 1000),
            "start_timezone": result[0].timezone,
            "end_timezone": result[0].timezone
          },
          // "timezone":timeZone[0]
        }
        const requestOptions = {
          'method': 'PUT',
          'headers': {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('nylas_token')
          },
          'body': JSON.stringify(body),
          'url':`${commonob.nylas.APIURL}events/${result[0].nylas_event_id}?notify_participants=true`
        };
        try{
          await globalApiService(requestOptions)
        }catch(e){
          console.log(e)
        }
      } catch (e){
        console.log(e)
      }
    } catch (e){
      console.log(e)
    }
  }

  return (
    <>{
      isLoading ?
       <LoadingSpinner />
      :
      <div className={cx('timermainDiv')}>
        <div className={cx('timer_formWrapper')}>
          <img className={cx('logo')} src={`/${commonob.app_name}_logo.png`} />
          <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
          <div className={cx('readyOrJoin')}>
            <h1 className={cx('title')}>Waiting Room</h1>
            {timer &&
              <div className={cx("clockMainDiv")}>
                <span id="demo1" className={cx("clock")}></span>
                <span id="demo2" className={cx("clock")}></span>
                <span className={cx("dots")}>:</span>
                <span id="demo3" className={cx("clock")}></span>
                <span id="demo4" className={cx("clock")}></span>
                <span className={cx("dots")}>:</span>
                <span id="demo5" className={cx("clock")}></span>
                <span id="demo6" className={cx("clock")}></span>
              </div>
            }
            <span className={cx('spanHead')}>Meeting will start soon...</span>
            {isHost &&
              <button onClick={handleStart} className={cx('waitRoomJoinButton')}>{enableButton ? "Please wait a moment..." : "Start now"}</button>
            }
            <div className={cx('login-bottom-link')}>

              <div>
                <div className={cx('loginLink', 'loginLinkBlackColor', 'leftSide')} onClick={() => {
                  goingBack()
                  history.push(routes.JOIN)
                }}>

                  <FormattedMessage id="Back.link" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
}
