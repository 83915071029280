// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */


import classNames from 'classnames/bind';
import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import ViewMode from '../enums/ViewMode';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './RosterLayout.css';
import Tooltip from './Tooltip'
import ClassMode from '../enums/ClassMode';
import localStorageKeys from "../constants/localStorageKeys.json"
import getGlobalVarContext from '../context/getGlobalVarContext';

const cx = classNames.bind(styles);

export enum Size {
  Small,
  Medium,
  Large
}

type Props = {

  size: Size;
  volume: any;
  v_height: any;
  attId_: string;
  videoLength: any;
  viewMode: ViewMode;
  enabled: boolean;
  tileView: boolean;
  isRightBarEnabled: boolean;
  boundAttendeeId: string;
  isSmallScreen: boolean;
  activeSpeaker: string;
  raisedHand?: boolean;
  isContentShareEnabled: boolean;
  rosterAttendee?: RosterAttendeeType;
  activeSpeakerMuted: boolean;
  removeAttendee: (id: string) => void;
  muteunmuteAttendee: (id: string) => void;
  resetFullscreen: () => void;
  rendervideotoparent: (b: string) => void;
  setActiveAttendeeForChat: (id: string) => void;
  videoElementRef: (instance: HTMLVideoElement | null) => void;
};

export default function RosterLayout(props: Props) {
  const intl = useIntl();
  const {
    attId_,
    volume,
    viewMode,
    enabled,
    tileView,
    isSmallScreen,
    activeSpeaker,
    activeSpeakerMuted,
    videoLength,
    size = Size.Large,
    rosterAttendee = {},
    raisedHand,
    isContentShareEnabled,
    isRightBarEnabled,
    boundAttendeeId,
    resetFullscreen,
    videoElementRef,
    removeAttendee,
    muteunmuteAttendee,
    rendervideotoparent,
  } = props;

  const [fullscreen, setFullScreen] = useState(false);
  const [zooming, setZooming] = useState(false);
  const { globalVar,setGlobalVar } = useContext(getGlobalVarContext());
  const {fullScreenMode} = globalVar;

  useEffect(() => {
    setFullScreen((localStorage.getItem('fullscreen') == 'true') == true ? true : false)
  })

  const setGlobalVariables = (prop: string, value: any) => {

    switch (prop) {
      case "fullScreenMode":
        setGlobalVar((oldData) => ({ ...oldData, fullScreenMode: value }))
        break;
      default:
        // code...
        break;
    }
  }
  useEffect(()=>{
    if (attId_ == boundAttendeeId){
      setTimeout(()=>{
        let nav = document.getElementById('participantStatusNew')
        nav?.setAttribute("style","opacity:1;");
      },2000)
      
      setTimeout(()=>{
        let nav = document.getElementById('participantStatusNew')
        nav?.setAttribute("style","opacity:0;");
      },10000)
    }
  },[attId_,boundAttendeeId])

  const mainTainFullScreen = ()=> {
    var elem:any = document.documentElement;
    var elem1:any = document
    if (!zooming){
      setGlobalVariables('fullScreenMode', true)
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    }else{
      setGlobalVariables('fullScreenMode', false)
      if (elem1.exitFullscreen) {
        elem1.exitFullscreen();
      } else if (elem1.webkitExitFullscreen) { /* Safari */
        elem1.webkitExitFullscreen();
      } else if (elem1.msExitFullscreen) { /* IE11 */
        elem1.msExitFullscreen();
      }
    }
    setZooming(!zooming)
    }

    useEffect(()=>{
      if(fullScreenMode){
        setZooming(fullScreenMode)
      }else{
        setZooming(fullScreenMode)
      }
    },[fullScreenMode])

  return (
    <div
      className={cx('attendee', 'remoteVideo', {
        enabled,
        large: size === Size.Large && !tileView,
        isContentShareEnabled,
        largevideo: attId_ == boundAttendeeId && !tileView,
        isSpeakerActive: (activeSpeaker && attId_ != boundAttendeeId) || (activeSpeaker && tileView) || (activeSpeaker && !tileView && attId_ != boundAttendeeId),
      })}
    >
      {/* ATTENDEE'S VIDEO FOR ALL VIEW */}
      <video
        muted
        style={{
          height:
            (tileView) &&
            '100%'
        }}
        ref={videoElementRef}
        className={cx({
          position: boundAttendeeId != '' && attId_ != boundAttendeeId && !tileView,
          fullscreen: localStorage.getItem('fullscreen') == 'true' && attId_ == boundAttendeeId,
          fullscreen1: isRightBarEnabled && !fullscreen && attId_ == boundAttendeeId && !tileView
        }, 'video')}
        onClick={
          () => {
            rendervideotoparent(boundAttendeeId)
          }
        }
      />

      {/* ATTENDEE'S VIDEO FOR LEFT SIDE SECTION WHEN ATTENDEE MUTE */}
      {(attId_ != boundAttendeeId) && !tileView && (
        // small video in left section
        <>
          <div className={cx('participantStatus', {
          })}>
            <div className={cx('nameStatus', 'newlook', {
            })}>
              <div
                style={{ cursor: 'pointer' }}
                className={cx('name')}>
                {rosterAttendee.name}
              </div>

            </div>
          </div>

          {localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher ?
            <div className={cx('participantControl')}>
              <div className={cx('controStatus', 'newlook')}>
                <Tooltip
                  tooltip={'Remove Attendee'}
                >
                  <div style={{ cursor: 'pointer' }} onClick={() => {
                    if (confirm(`Are you sure to remove ${rosterAttendee.name} from meeting ?`)) {
                      removeAttendee(boundAttendeeId)
                    }
                  }}
                    className={cx('user')}> <i className={cx('fas fa-user-times')} />
                  </div>
                </Tooltip>

                {typeof rosterAttendee.muted === 'boolean' && (

                  <Tooltip
                    tooltip={!rosterAttendee.muted ? "Mute attendee" : "Unmute attendee"}
                  >
                    <div style={{ cursor: 'pointer' }} className={cx('muted__', 'manageheight')}
                      onClick={() => { muteunmuteAttendee(boundAttendeeId) }} >

                      {rosterAttendee.muted ? (
                        <i className="fas fa-microphone-slash" />
                      ) : (
                        <i className="fas fa-microphone marginlefticon" />
                      )}
                    </div>
                  </Tooltip>
                )
                }
              </div>
            </div>
            :
            <div className={cx('participantControl')}>
            <div className={cx('controStatusJoinee', 'newlook')}>

              {typeof rosterAttendee.muted === 'boolean' && (

                  <div  className={cx('muted__', 'manageheight')} >

                    {rosterAttendee.muted ? (
                      <i className="fas fa-microphone-slash" />
                    ) : (
                      <i className="fas fa-microphone marginlefticon" />
                    )}
                  </div>
              )
              }
            </div>
          </div>
          }
        </>
      )}

      {/* ATTENDEE'S VIDEO FOR TILEVIEW  SECTION */}
      {tileView && (
        <>
          <div
            // style={{ bottom: videoLength == 1 ? '7%' : '12%', position: 'absolute' }}
            className={cx('participantStatus')}>
            <div
              className={cx('nameStatus')}>
              <div
                // style={{ lineHeight: '31px', marginLeft: '30px' }}
                className={cx('name')} >
                {rosterAttendee.name}
              </div>
            </div>
          </div>
          <div className={cx('participantControlTileView')}>
            <div className={cx('participantControlDivTileView')}>
              {
                typeof rosterAttendee.muted === 'boolean' && (
                  <div
                  // style={{ margin: '-3px 0 0px  7px', lineHeight: '31px' }}
                  // className={cx('muted')}
                  >
                    {activeSpeaker == boundAttendeeId && !activeSpeakerMuted && (
                      <div className={cx('firstdiv')}>
                        {/* {
                          <div style={{ marginTop: '7px', float: 'left' }}>
                            <div style={{ height: '6px', bottom: '-6px', position: 'relative', background: volume >= 20 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                            <div style={{ height: '8px', bottom: '-4px', position: 'relative', background: volume >= 40 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                            <div style={{ height: '10px', bottom: '-2px', position: 'relative', background: volume >= 60 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                            <div style={{ height: '12px', bottom: '0px', position: 'relative', background: volume >= 80 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                            <div style={{ height: '14px', bottom: '2px', position: 'relative', background: volume >= 100 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                            {rosterAttendee.muted && (
                              <div className={cx('volumecontrol', 'mutemic')}>&nbsp;</div>
                            )}
                          </div>
                        } */}
                        {volume >= 0 && volume < 25 && !rosterAttendee.muted && <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator1.png' />}
                        {volume >= 25 && volume < 50 && <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator2.png' />}
                        {volume >= 50 && volume < 75 && <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator3.png' />}
                        {volume >= 75 && <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator4.png' />}
                        {rosterAttendee.muted && (
                          <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator5.png' />
                        )}
                      </div>
                    )}
                    {activeSpeaker == boundAttendeeId && activeSpeakerMuted && (
                      <>
                            <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator5.png' />
                      </>
                    )}
                    {activeSpeaker != boundAttendeeId && (
                      <>
                        {rosterAttendee.muted ? (
                            <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator5.png' />
                            ) : (
                              <img style ={{objectFit:'contain',width:"24px",height:"24px"}} src='/icons1/volumeIndicator1.png' />
                              )}
                      </>
                    )}
                  </div>
                )
              }
            </div>
          </div>
        </>
      )
      }

      {/* ATTENDEE'S VIDEO FOR FULL SCREEN WHEN ATTENDEE SPEAKING */}

      {attId_ == boundAttendeeId && !tileView && (
        <div id='participantStatusNew' className={cx('participantStatus', {
          participantStatusNew: attId_ == boundAttendeeId,
          participantStatusWidht: isRightBarEnabled && !fullscreen && attId_ == boundAttendeeId,
          participantStatusWithRightsideEnable: ((attId_ == boundAttendeeId && isRightBarEnabled))
        })}>
          <div className={cx('fullScreenControl')} style={{ flexDirection: 'column' }}>
            <div className={cx('firstdiv')}>
              {/* {
                <div style={{ marginTop: '7px', float: 'left' }}>
                  <div style={{ height: '6px', bottom: '-6px', background: volume >= 20 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '8px', bottom: '-4px', background: volume >= 40 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '10px', bottom: '-2px', background: volume >= 60 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '12px', bottom: '0px', background: volume >= 80 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  <div style={{ height: '14px', bottom: '2px', background: volume >= 100 ? '#00C5BA' : '#8a8d8a' }} className={cx('volumecontrol')}>&nbsp;</div>
                  {rosterAttendee.muted && (
                    <div className={cx('volumecontrol', 'mutemic')}>&nbsp;</div>
                  )}
                </div>
              } */}
              {volume >= 0 && volume < 25 && !rosterAttendee.muted && <img src='/icons1/volumeIndicator1.png' />}
              {volume >= 25 && volume < 50 && <img src='/icons1/volumeIndicator2.png' />}
              {volume >= 50 && volume < 75 && <img src='/icons1/volumeIndicator3.png' />}
              {volume >= 75 && <img src='/icons1/volumeIndicator4.png' />}
              {rosterAttendee.muted && (
                <img src='/icons1/volumeIndicator5.png' />
              )}
              <span><strong>{rosterAttendee.name}</strong></span>
            </div>

            <div
              onClick={mainTainFullScreen}
              className={cx('firstdiv')}>
              {<img src={!zooming ? '/icons1/fullscreenVideo.png' : '/icons1/closeFullscreenVideo.png'} />}
              <span>{intl.formatMessage(!zooming ? { id: 'RosterLayout.FullScreen' } : { id: 'RosterLayout.CloseFullScreen' })}</span>
            </div>
            {/* <div onClick={() => {
              // localStorage.setItem('fullscreen', !fullscreen);
              setFullScreen(!fullscreen);
              rendervideotoparent(boundAttendeeId);
            }}
              className={cx('firstdiv')}>
              {<img src={fullscreen ? '/icons1/zoomOutVideo.png' : '/icons1/zoomVideo.png'} />}
              <span>{intl.formatMessage(fullscreen ? { id: 'RosterLayout.ZoomOut' } : { id: 'RosterLayout.ZoomIn' })}</span>
            </div> */}
            <div onClick={() => { resetFullscreen() }}
              className={cx('firstdiv')}>
              <img src={'/icons1/exitZoom.png'} />
              <span>{intl.formatMessage({ id: 'RosterLayout.ExitZoom' })}</span>
            </div>
          </div>
        </div>
      )}
      {raisedHand && !tileView && (
        <div className={cx('raisedHand')}>
          <span
            role="img"
            aria-label={intl.formatMessage({
              id: 'RemoteVideo.raiseHandAriaLabel'
            })}
          >
            ✋
          </span>
        </div>
      )}
    </div>
  );
}
