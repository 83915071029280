/* eslint-disable */
import React from 'react';
import classNames from 'classnames/bind';
import styles from './ScheduleMeetingForm.css';

const cx = classNames.bind(styles);

function ParticipantItem(props:any){
    const items = props.items;

    // display each participant mail address below
    const listItems = items.map((item:any) => 
    {
        return ( 

            <div  key={item} className={cx('item')} id={item}>
                {item}
                <span className={cx('delete')} onClick={ () => props.deleteItem(item)}> X </span>
            </div>
    )})
    return(   
        <div className={cx('list')}>{listItems}</div>
    )
}

export default ParticipantItem;
