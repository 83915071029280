/* eslint-disable  */
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styles from './ScheduleMeetingForm.css';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import routes from '../constants/routes.json';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePicker from "react-datepicker";
import Participant from './Participant'
import timezones from 'google-timezones-json';
import { shortMeetingUrl } from "../common_function"
import commonob from '../constants/common.json';
import NylasLogin from './NylasLogin';
import { globalApiService } from "../common_function"

const cx = classNames.bind(styles);

type Props = {
  isEdit?: boolean;
  meetingId?: any;
  goBack: () => void;
};

// get all timezone list
let timezoneArray: any = []
Object.entries(timezones).map((item) => {
  timezoneArray.push(item[0])
})
var request = require('request');

function getRandomID() {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 3; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return Date.now() + text;
}
// set meeting Id as random id
let meeting_id = getRandomID()
let pwd = parseInt(Date.now().toString().substring(7, 13))

var moment = require('moment-timezone');
var localTimeZone = moment.tz.guess()
var selEmail: any = []

export default function ScheduleMeetingForm(props: Props) {
  const login_flag = (localStorage.getItem("isAuthenticated") ? true : false);
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [timezone, setTimezone]: any = useState(localTimeZone);
  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(new Date(start.getTime() + 3600000))
  const [selectedStart, setSelectedStart]:any = useState(new Date())
  const [selectedEnd, setSelectedEnd]:any = useState(new Date(selectedStart.getTime() + 3600000))
  const [documentList, setDocumentList]: any = useState([])
  const [documentSelectedData, setDocumentSelectedData]: any = useState([])
  const [videoList, setVideoList]: any = useState([])
  const [selectVideo, setSelectVideo]: any = useState([])
  const [participant, setParticipant] = useState([])
  const [deleteMeetingId, setDeleteMeetingId] = useState('')
  const [resetEmail, setResetEmail] = useState(false)
  const [offset, setOffset] = useState(moment().format("Z"))
  const [error, setError]: any = React.useState({});
  const [loading, setLoading] = useState(false)
  const [toOpen, setToOpen] = useState(false)
  const [docOpen, setDocOpen] = useState(false)
  const [disabled,setDisabled] = useState(false)
  const [vodOpen, setVodOpen] = useState(false)
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)
  
  const { isEdit, meetingId, goBack } = props;
  const intl = useIntl();

  const classNameArr = ['scheduleMeetingForm', 'Schedule_form', 'Schedule_button', 'Schedule_formWrapper', 'createMeetingLabel', 'formLabel', 'participant-input', 'list']

  // all dropdown close when other component focused
  useEffect(()=>{
    let timeZoneDropDown = document.getElementById("myDropdown")
    let videoDropDown = document.getElementById("videoDropDown")
    let documentDropDown = document.getElementById("documentDropDown")

    if (toOpen && timeZoneDropDown != null){
      timeZoneDropDown.style.display = "block";
    }
     if (!toOpen && timeZoneDropDown != null){
      timeZoneDropDown.style.display = "none";
    }
     if (vodOpen && videoDropDown != null){
      videoDropDown.style.display = "block";        
    }
     if(!vodOpen && videoDropDown != null){
      videoDropDown.style.display = "none";        
    }
     if (docOpen && documentDropDown != null){
      documentDropDown.style.display = "block";        
    }
     if (!docOpen && documentDropDown != null){
      documentDropDown.style.display = "none";        
    }
  })

  const closeAllDropdown = () => {      
    setVodOpen(false);
    setDocOpen(false);
    setToOpen(false);  
  }

  useEffect(()=>{
    if (title == "" || participant.length <= 0){
      setDisabled(true)
    }else{
      setDisabled(false)
    }

  },[participant,title,timezone])


  useEffect(() => {

    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        closeAllDropdown()
      }
    });

    document.addEventListener('click', function (event:any) {

      if (classNameArr.includes(event.target.className.split("__")?.[1])) {
        closeAllDropdown()
      }

    })

    return () => {
      document.removeEventListener('keydown',function(event){
        if (event.key === "Escape") {
          closeAllDropdown()
        }
      })
      document.removeEventListener('click', function (event:any) {
        if (classNameArr.includes(event.target.className.split("__")?.[1])) {
          closeAllDropdown()
        }  
      })
    }
  }, [])

  async function getVODData_admin() {
    var requestOption = {
      "method":"POST",
      "body":JSON.stringify({"email":localStorage.getItem("userName")}),
      "url":`${commonob.admin_url}/vods?media_type=vod`
    }
    try{
      let rs:any = await globalApiService(requestOption);
      return rs.private;
    } catch (e){
      console.log(e)
    }
  }

  useEffect(() => {
    if (isEdit) {
      setLoading(true)

      async function getEditMeetingData(){
      var requestOption = {
        "method":"GET",
        "url":`${commonob.getBaseUrl}meeting_schedule?meetingId=${meetingId}`
      }
      try{
        let result:any = await globalApiService(requestOption);
        meeting_id = getRandomID()
        setTitle(result[0].meeting_heading);
        setDescription(result[0].desc);
        setDeleteMeetingId(result[0].nylas_event_id)
        setStart(new Date(parseInt(result[0].from_ts) * 1000));
        setEnd(new Date(parseInt(result[0].to_ts) * 1000));
        setSelectedStart(new Date(parseInt(result[0].from_ts) * 1000))
        setSelectedEnd(new Date(parseInt(result[0].to_ts) * 1000))
        result[0].documents.map((doc: any) => {
          documentSelectedData.push(doc)
        })
        result[0].videos.map((videos: any) => {
          selectVideo.push(videos)
        })
        result[0].participants.map((item: any) => {
          selEmail.push(item.email)
        })
        setParticipant(selEmail)
        selEmail = [];
        setLoading(false)
      } catch (e){
        console.log(e)
        setLoading(false)
      }
    }
    getEditMeetingData()
    }
  }, [isEdit])


  useEffect(() => {

    new Promise(async (res) => {

      // fetch the list of documents and set into schedule meeting form 
      var options = {
        'method': 'POST',
        'body': JSON.stringify({
          'email': `${localStorage.getItem('userName')}`
        }),
        'url': `${commonob.admin_url}/documents`
      };

      try{
        let rs:any = await globalApiService(options);
        let data = rs.Items
        setDocumentList(data)
      } catch (e){
        console.log(e)
      }

      // fetch the list of videos and set into schedule meeting form 
      let videoData = await getVODData_admin()
      setVideoList(videoData)
      res({})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    if (title == '') {
      setError({})
      let error: any = {}
      error['title'] = `meeting title is required!`
      setError(error)
    }else if (participant.length <= 0){
      setError({})
      let error: any = {}
      error['participants'] = `Please add at least one email`
      setError(error)
    }
    else {
      // create meeting in Nylas
      (async function callNylas() {
        setLoading(true)
        var shortLinkUrl = await shortMeetingUrl(meeting_id)
        let body = {
          "title": title,
          "location": shortLinkUrl,
          "calendar_id": localStorage.getItem('nylas_calendar_id'),
          "busy": true,
          "participants": participant.map(item =>{ return { email:item }}),
          "description": `
          <html><head><meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\"></head>
          <body>
          <p>Meeting ID: ${meeting_id}</p>
          <p>Start Time: ${(new Date(selectedStart)).toLocaleString('en', { timeZone: timezone })}</p>
          <p>End &nbspTime: ${(new Date(selectedEnd)).toLocaleString('en', { timeZone: timezone })}</p>
          <p>Timezone: ${timezone}</p>
          <p><a href=\"${shortLinkUrl}\">Click here to join the meeting</a></p>
          <p>Description: ${description}</p>
          </body></html>`,
          "when": {
            "start_time": (Math.round(selectedStart / 1000)),
            "end_time": Math.round(selectedEnd / 1000),
            "start_timezone": timezone,
            "end_timezone": timezone
          },
          // "timezone":timeZone[0]
        }

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('nylas_token')
          },
          body: JSON.stringify(body)
        };
        // send data to nylas and notify participant for meeting
        fetch(`${commonob.nylas.APIURL}events?notify_participants=true`, requestOptions)
          .then(async (response) => {
            let api_res = await response.json()
            if (response.ok) {

              // once meeting created show success message
              let nylas_event_id = api_res.id
              storeEventData(nylas_event_id)
              // fetch the new list of meetings
            } else {
              console.log("error")
              alert("Oops,something went wrong!.Please try after sometime.")
              setTimeout(() => {
                history.push(routes.JOIN);
              }, 2000)
            }
          })
          .catch(error => console.error(error))


      })()

      // call the meeting detail into roomz api 
      const storeEventData = async (nylas_event_id: any) => {
        // store the login user detail
        let email: any = localStorage.getItem('userName')
        // if host name not found take 1st part of mail address 
        let name = (email).split("@")
        name = name[0]
        // meeting url
        var shortLinkUrl = await shortMeetingUrl(meeting_id)
        let body = {
          "title": `${meeting_id}`,
          "meeting_heading": `${title}`,
          "nylas_event_id": `${nylas_event_id}`,
          "meeting_url": `${shortLinkUrl}`,
          "desc": `${description}`,
          "from_ts": `${(Math.round(selectedStart))}`,
          "to_ts": `${Math.round(selectedEnd)}`,
          "password": `${pwd}`,
          "created_by": `${email}`,
          "created_name": `${name}`,
          "participants": participant.map(item =>{ return { email:item }}),
          "documents": documentSelectedData.map((item: any) => item.id = {
            "id": item.id,
            "key": item.key,
            "name": item.name,
            "type": item.type,
            "url": item.url,
          }),
          "videos": selectVideo.map((item: any) => item.id = {
            "id": item.id,
            "media_type": item.media_type,
            "assetId": item.assetId,
            "duration": item.duration,
            "playbackId": item.playbackId,
            "title": item.title

          }),
          "created_ts": `${Math.round(selectedStart / 1000)}`,
          // "timezone":`${timeZone}`
        }


        var options = {
          'method': 'POST',
          'body': JSON.stringify(body),
          'url': `${commonob.admin_url}/meeting_schedule`
        };
  
        try{
          let rs:any = await globalApiService(options);
          console.log("rs-->",rs)
          // once meeting created, refresh the schedule form
          meeting_id = getRandomID()
          setSelectVideo([])
          setParticipant([])
          setStart(new Date())
          setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
          setEnd(new Date(start.getTime() + 3600000))
          setTitle('');
          setDescription('');
          setTimezone(localTimeZone);
          setSelectedStart(new Date())
          setSelectedEnd(new Date(selectedStart.getTime() + 3600000))
          setDocumentList([])
          setDocumentSelectedData([])
          setVideoList([])
          setSelectVideo([])
          setParticipant([])
          setResetEmail(true)
          setOffset(moment().format("Z"))
          alert(isEdit ? intl.formatMessage({
            id: 'CreateOrJoin.upDateSuccessMessage'
          })
            : intl.formatMessage({
              id: 'CreateOrJoin.createSuccessMessage'
            }))
          setTimeout(() => {
            history.push(routes.JOIN);
          }, 2000)
          if (isEdit && deleteMeetingId != '') {
            const requestOptions4 = {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('nylas_token')
              }
            }
            const nylas_response = await fetch(`${commonob.nylas.APIURL}events/${deleteMeetingId}`, requestOptions4)
            const response = await fetch(commonob.admin_url + `/meeting_schedule?event_id=${deleteMeetingId}`, { method: 'DELETE' })
            if (nylas_response.ok && response.ok) {
              setLoading(false)
              setDeleteMeetingId('')
            } else {
              alert("Oops! Something went wrong")
            }
          } else {
            setLoading(false)
          }
        } catch (e){
          console.log(e)
          alert("Oops,something went wrong!.Please try after sometime.")
          setTimeout(() => {
            history.push(routes.JOIN);
          }, 2000)
        }
        
      }
    }
  };

  const handleEndTime = (event: any) => {
    setEnd(new Date(event.getTime()))
    let ed = new Date((event).getTime())
    setSelectedEnd(new Date(ed.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) + offset))
  }

  const handleStartTime = (event: any) => {
    let ed = new Date((event).getTime() + 3600000)
    setStart(event)
    setSelectedStart(new Date(event.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) + offset))
    setSelectedEnd(new Date(ed.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) + offset))
    setEnd(new Date(event.getTime() + 3600000))
  }
  const handleDocument = async (doc: any) => {
    let isActive: any = false;
    var idx;
    // add document if not selected
    await documentSelectedData.map((item: any, index: number) => {
      if (item.id == doc.id) {
        isActive = true;
        idx = index;
      }
    })

    if (isActive == true) {
      let docs = documentSelectedData;
      docs.splice(idx, 1)
      setDocumentSelectedData([]);
      setDocumentSelectedData(docs)
    }

    // remove document code
    if (isActive == false) {
      setDocumentSelectedData((old: any) => [...old, doc])
    }
  }


  const handleVideos = async (videos: any) => {
    let isActive: any = false;
    var idx;
    // add video if not selected
    await selectVideo.map((item: any, index: number) => {
      if (item.id == videos.id) {
        isActive = true;
        idx = index;
      }
    })

    if (isActive == true) {
      let vdo = selectVideo;
      vdo.splice(idx, 1)
      setSelectVideo([]);
      setSelectVideo(vdo)
    }

    // remove video code
    if (isActive == false) {
      setSelectVideo((old: any) => [...old, videos])
    }

  }
  
  const handleTimezone = (event: any) => {
    if(timezoneArray.includes(event)){
    setTimezone(event)
    var input: any = document.getElementById("myInput")
    input.value = event;
    setToOpen(false)
    let only_timezone = event.toString().split(",")[0]
    let offset = moment().tz(only_timezone).format('Z')

    let tz = only_timezone
    let selected_tz = new Date().toLocaleString('en-US', { timeZone: tz })
    let endTime = new Date(selected_tz)
    let ed = new Date(endTime.getTime() + 3600000)
    setStart(new Date(selected_tz))
    setSelectedStart(new Date(selected_tz + offset))
    setEnd(new Date(endTime.getTime() + 3600000))
    setSelectedEnd(new Date(ed.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) + offset))
    setOffset(offset)
    setTimezone(only_timezone)
    setError({})
  }else{
    let error: any = {}
    error['timezone'] = `Please select valid timezone`
    setError(error)
     }    
  }

  const handleParticipant = (email: any) => {
    setParticipant(email)
    setResetEmail(false)
  }

  // searchable all dropdown code
  function filterList(inputId: string, dropDownId: string,mainDivId:string) {
    keyNav(mainDivId)
    if (inputId == 'myInput') {
      setToOpen(true)
    } else if (inputId == 'docInput') {
      setDocOpen(true)
    } else if (inputId == 'vodInput') {
      setVodOpen(true)
    }
    var input: any, filter: any, ul: any, li: any, a: any, i: any, div: any, txtValue: any;
    input = document.getElementById(inputId);
    filter = input.value.toUpperCase();
    if (filter == "") {
      setToOpen(false)
      setDocOpen(false)
      setVodOpen(false)
    }

    div = document.getElementById(dropDownId);
    if (div != null) {
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    }
  }

  useEffect(()=>{
    keyNav('Schedule_regionsList')
    keyNav('vod_multiSelect')
    keyNav('doc_multiSelect')
  },[])

  // select value on key up - down 
  const keyNav = async (elementId:string) => {
  var divs:any = [];
  var id = elementId; 
  var element:any = document.getElementById(elementId)
  if(element != null){
    var div:any = element.getElementsByTagName('a'),
    selectedDiv = 0,
    i;
 
  for(i = 0; i < div.length; i++){
    if (div[i].style.display != 'none'){
      await divs.push(div[i])
    }
  }

    for(i = 0; i < divs.length; i++){      
        divs[selectedDiv].focus();
    }

    element.getElementsByTagName('input')[0].onkeydown = function(e){
         var x = 0;
         if(e.keyCode == 38)
             x = -1;
         else if(e.keyCode == 40)
             x = 1;
         else
             return;

         divs[selectedDiv].style.backgroundColor = '';
         divs[selectedDiv].style.color = '';                 
         selectedDiv = ((selectedDiv+x)%divs.length);
         selectedDiv = selectedDiv < 0 ? divs.length+selectedDiv : selectedDiv;
         element.getElementsByTagName('input')[0].value = (divs[selectedDiv].textContent);
         divs[selectedDiv].style.backgroundColor = '#0053BC'; 
         divs[selectedDiv].style.color = '#fff';                 
         divs[selectedDiv].scrollIntoView();
         divs[selectedDiv].focus();                  
    };
  }
  }


  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  function detectMob() {
      const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];

      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });
  }
  return (
    <>

      {   !localStorage.getItem('nylas_token') ? 
            <NylasLogin /> :
        <div className={cx({
          scheduleMeetingForm : !detectMob(),
          mobileScheduleMeetingForm : detectMob(),
        })}>
          {
            <div className={cx('Schedule_formWrapper')}>
              <legend className={cx('createMeetingLabel')} >{isEdit ? intl.formatMessage({
                id: 'CreateOrJoin.upDateMeeting'
              }) : intl.formatMessage({
                id: 'CreateOrJoin.createMeeting'
              })}</legend>
              <div className={cx({
                Schedule_form : !detectMob(),
                mobileSchedule_form : detectMob()
              })}>
                <div>
                <div className={cx("text-field-box")}>
                <label className={cx("formLabel")}>Meeting title</label>
                  <input
                    maxLength={355}
                    defaultValue={title}
                    className={cx('Schedule_titleInput')}
                    placeholder={intl.formatMessage({
                      id: 'CreateOrJoin.meetingtitlePlaceholder'
                    })}
                    onClick={() => {
                      closeAllDropdown()
                    }}
                    onChange={(event: any) => {
                      setError({})
                      setTitle(event.target.value);
                      closeAllDropdown()
                    }}
                    autoFocus={true}
                  />
                </div>
                  {error['title'] ? <div className={cx('Schedule_errorMessage')}>{error['title']}</div> : null}
                  <div className={cx("text-field-box")}>
                  <label className={cx("formLabel")}>Description (Optional)</label>

                  <input
                    maxLength={355}
                    className={cx('Schedule_nameInput')}
                    onChange={event => {
                      setDescription((event.target.value));
                    }}
                    onClick={() => {
                      closeAllDropdown()
                    }}
                    defaultValue={description}
                    placeholder={intl.formatMessage({
                      id: 'CreateOrJoin.descriptionPlaceholder'
                    })}
                  />
                  </div>

                  <div className={cx("ScheduleinviteParticipants")}>
                  <label className={cx("formLabel")}>Invite Participants  <span className={cx("scheduleMeeting_InviteParticipants_span")}> (Hit "Enter" to add email address)</span></label>
                  <div className={cx('participant')}>
                    <Participant
                      callbackFromParent={handleParticipant}
                      reset={resetEmail}
                      selectedEmail={selEmail}
                    />
                  </div>
                  <span className={cx("scheduleMeeting_InviteParticipants_span")}> (Hit "Enter" to add email address)</span>  
                  </div>

                  </div>
                  <div style={!detectMob() ? {marginLeft: '2rem'} : {}}> <div className={cx("text-field-box")}>
                  <div id ="startDateTimePiker" className={cx("dateTimePiker")} onClick={() => {
                    closeAllDropdown()
                  }}>
                 
                    <label className={cx('formLabel')}>Start</label>

                    <DatePicker
                      selected={start}
                      className={cx("datePickerInput")}
                      onChange={handleStartTime}   
                      onCalendarClose={()=>{
                        setOpenStartDate(false)
                      }}
                      onCalendarOpen={()=>{
                        setOpenStartDate(true)
                        closeAllDropdown()
                      }}
                      calendarClassName={cx('datePickerWrapper')}  
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      showTimeSelect
                      timeFormat="hh:mm aa"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                    {openStartDate && <button 
                    className={cx('dateTimeDoneStart')}
                    onClick={()=>{
                      let startDate:any =document.getElementById('startDateTimePiker')?.getElementsByTagName('input')[0].value
                      handleStartTime(new Date(startDate))
                      setOpenStartDate(false)
                    }}
                    >x</button>}
                  </div>
                  </div> <div className={cx("text-field-box")}>
                  <div id="endDateTimePiker" className={cx("dateTimePiker")} onClick={() => {
                    closeAllDropdown()
                  }}>
                    <label className={cx('formLabel')}>End</label>
                    <DatePicker
                      selected={end}
                      className={cx("datePickerInput")}
                      calendarClassName={cx('datePickerWrapper')}
                      onChange={handleEndTime}
                      onCalendarClose={()=>{
                        setOpenEndDate(false)
                      }}
                      onCalendarOpen={()=>{
                        setOpenEndDate(true)
                        closeAllDropdown()
                      }}
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      showTimeSelect
                      timeFormat="hh:mm aa"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                     {openEndDate && <button 
                    className={cx('dateTimeDoneEnd')}
                    onClick={()=>{
                      let endDate:any =document.getElementById('endDateTimePiker')?.getElementsByTagName('input')[0].value
                      handleEndTime(new Date(endDate))
                      setOpenEndDate(false)
                    }}
                    >X</button>}
                  </div></div>     <div className={cx("text-field-box")}>
                  <div id="Schedule_regionsList" className={cx('Schedule_regionsList')}>
                  <label className={cx("formLabel")}>TimeZone</label>
                    <input                      
                      type="text"
                      defaultValue={timezone}
                      onChange={(e)=>{
                        handleTimezone(e.target.value)
                      }}
                      className={cx("dropdown-input")}
                      placeholder="Select Timezone"
                      id="myInput"
                      onKeyUp={(e: any) => {
                        if (e.key !== "ArrowDown"
                          && e.key !== "ArrowUp"
                          && e.key !== "ArrowLeft"
                          && e.key !== "ArrowRight"){   
                          filterList('myInput', 'myDropdown','Schedule_regionsList')
                          }
                        if (e.key == "ArrowDown" && !toOpen) {
                          setToOpen(true)
                        }
                        if(e.key == 'Enter'){
                          handleTimezone(e.target.value)
                        }
                      }}
                      onClick={() => {
                        setToOpen(!toOpen)
                        setVodOpen(false);
                        setDocOpen(false);
                      }}
                    />
                    { <div id="myDropdown" className={cx("dropdown-content")}>
                      {timezoneArray.map((item: any, index: any) => {
                        return <a className={cx("dropdown-key")} key={index} onClick={() => {
                          handleTimezone(item)
                        }}>{item}</a>;
                      })}
                    </div>}
                  </div>
                  </div>
                  {error['timezone'] ? <div className={cx('Schedule_errorMessage')}>{error['timezone']}</div> : null}

                    {/* <div className={cx("multirow")}> */}
                    
                      {/* <div className={cx("left-row")}>Select Documents +</div>
                      <div className={cx("right-row")}>Select Videos +</div> */}

                  {/* <div id='doc_multiSelect' className={cx("multiSelect")}>
                    <div className={cx('videoParentDiv')}>
                      {
                        <div className={cx("selectedDocXXXX")} onClick={() => {
                          setVodOpen(false)
                          setDocOpen(!docOpen);
                          setToOpen(false);
                          keyNav('doc_multiSelect')
                        }}>
                          {documentSelectedData.map((item: any, index: any) => {
                            return (
                              <div key={item.id} className={cx("selectedDocParentDiv")}>
                                <span className={cx("selectedDocKey")} key={index}>{item.name}</span>
                                <span className={cx("removeKey")} onClick={() => {
                                  handleDocument(item)
                                }}>X</span>
                              </div>
                            )
                          })}
                          <div className={cx("selectedDocParentDiv")}
                            style={{ border: 'none', backgroundImage: 'none' }}>
                            <input
                              type="text"
                              defaultValue={[]}
                              className={cx("doc-input")}
                              placeholder="Select Documents"
                              id="docInput"
                              onKeyUp={(e: any) => {
                                if (e.key !== "ArrowDown"
                                  && e.key !== "ArrowUp"
                                  && e.key !== "ArrowLeft"
                                  && e.key !== "ArrowRight"){
                                  filterList('docInput', 'documentDropDown','doc_multiSelect')
                                  }
                                  if (e.key == "ArrowDown" && !docOpen) {
                                    setDocOpen(true)
                                }
                                if (e.key == "Enter") {
                                  documentList.map((item: any) => {
                                    if(item.name == e.target.value){
                                      handleDocument(item)   
                                      e.target.value = '' 
                                      filterList('docInput', 'documentDropDown','doc_multiSelect')
                                    }
                                  })                             
                                } 
                              }
                            }                              
                            />
                          </div>
                        </div>
                      }
                      <div>
                        { <div id="documentDropDown" className={cx("video-dropdown-content")}>
                          {documentList.length == 0 && <a className={cx("dropdown-key")} >No record found!</a>}
                          {documentList.map((item: any, index: any) => {
                            return <a id={item.id}
                              className={cx("dropdown-key")}
                              key={index}
                              onClick={() => handleDocument(item)}>{item.name}</a>;
                          })}
                        </div>}
                      </div>
                    </div>
                  </div>

                  <div id='vod_multiSelect' className={cx("multiSelect")}>
                    <div className={cx('videoParentDiv')}>
                      {
                        <div
                          onClick={() => {
                            setVodOpen(!vodOpen)
                            setDocOpen(false);
                            setToOpen(false);
                            keyNav('vod_multiSelect')
                          }}>
                          {selectVideo.map((item: any, index: any) => {
                            return (
                              <div key={item.id} className={cx("selectedVodParentDiv")}>
                                <span className={cx("selectedDocKey")} key={index}>{item.title}</span>
                                <span className={cx("removeKey")} onClick={() => {
                                  handleVideos(item)
                                }}>X</span>
                              </div>
                            )
                          })}
                          <div className={cx("selectedVodParentDiv")}
                            style={{ border: 'none', backgroundImage: 'none' }}>
                            <input
                              type="text"
                              defaultValue={[]}
                              className={cx("video-input")}
                              placeholder="Select Videos"
                              id="vodInput"
                              onKeyUp={(e: any) =>  {
                                if (e.key !== "ArrowDown"
                                  && e.key !== "ArrowUp"
                                  && e.key !== "ArrowLeft"
                                  && e.key !== "ArrowRight"){
                                  filterList('vodInput','videoDropDown','vod_multiSelect') 
                                  }
                                  if (e.key == "ArrowDown" && !vodOpen) {
                                    setVodOpen(true)
                                  }  
                                  if (e.key == "Enter") {
                                    videoList.map((item: any) => {
                                      if(item.title == e.target.value){
                                        handleVideos(item)   
                                        e.target.value = '' 
                                        filterList('vodInput','videoDropDown','vod_multiSelect')  
                                      }
                                    })                             
                                  }                                                                 
                                } 
                              }                                
                            />
                          </div>

                        </div>
                      }
                      <div>
                        { <div id="videoDropDown" className={cx("video-dropdown-content")}>
                          {videoList.length == 0 && <a className={cx("dropdown-key")} >No record found!</a>}
                          {videoList.map((item: any, index: any) => {
                            return <a id={item.id}
                              // style = {{ 'background' : item?.isSelected ? 'blue' :'inharit'}}
                              className={cx("dropdown-key")}
                              key={index}
                              onClick={() => handleVideos(item)}>{item.title}</a>;
                          })}
                        </div>}
                      </div>
                    </div>
                  </div> */}
                  {/* </div> */}                             
                  
                </div>    
              </div>
              <div>
              <button className={cx({
                Schedule_button:!disabled,
                disabled_button:disabled
                })} type="submit" onClick={handleSubmit} disabled={disabled} >
                    {loading ?
                      <div className={cx('meeting_spinner')}>
                        {Array.from(Array(12).keys()).map(key => (
                          <div key={key} className={cx('meeting_circle', `meeting_circle${key + 1}`)} />
                        ))}
                      </div>
                      :
                      isEdit ?
                        <FormattedMessage id="CreateOrJoin.upDateButton" />
                        : <FormattedMessage id="CreateOrJoin.submitButton" />}
                  </button>
                </div>  
              <div className={cx('loginLink', 'loginLinkBlackColor', 'leftSide')} onClick={() => {
                isEdit ? goBack() : history.push(routes.JOIN)
              }}>
                <FormattedMessage id="Back.link" />
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}