// import constant from "../src/constants/common.json"

// function GetEnv() {

//   return {
//     nylasAPI: {
//       is_account_updated: `${constant.nylas.nylasAPIbaseURL}/is_account_updated?account_id=${localStorage.getItem('nylas_account_id')}`,
//       webhook: `${constant.nylas.nylasAPIbaseURL}/schedule_webhook`,
//       Login: () => {  // go to nylas login page
//         window.location.href = `${constant.nylas.nylasAPIbaseURL}/authentication?email=${localStorage.getItem('userName')}&return_page=${window.location.protocol + '//' + window.location.host + window.location.pathname}`;
//       },
//       GetAccountRecord: async () => {
//           console.log("GetAccountRecord")
//         try {
//           const record = await (await fetch(`${constant.nylas.nylasAPIbaseURL}/account_record?email=${localStorage.getItem('userName')}`)).json();
//           const calendars = await (await fetch(`${constant.nylas.APIURL}/calendars`, { headers: { Authorization: record.token } })).json();
//           localStorage.setItem('nylas_account_id', record.account_id);
//           localStorage.setItem('nylas_token', record.token);
//           console.log("record",await record)
//           console.log("calendars",calendars)
//           localStorage.setItem('nylas_calendar_id', record.calendar_id);
//           for (const calendar of calendars) {
//             if (calendar.is_primary) {
//               localStorage.setItem('nylas_calendar_id', calendar.id);
//               break;
//             }
//           }
//           return true;
//         } catch (err) {
//             console.log("getEnvErr",err)
//           return false;
//         }
//       }
//     },
//     webmerge: 'https://www.webmerge.me/api'
//   };
// }
// export default GetEnv;

// import axios from 'axios';
// import constants from './constants';

function GetEnv() {
  let nylasAPIbaseURL = 'https://scheduler.roomzz.net/prod';
  // let loginPage = constants.workos.redirectURI;

  if (process.env.NODE_ENV === 'development') {
    nylasAPIbaseURL = 'https://scheduler.roomzz.net/dev';
    // loginPage = 'http://localhost:3001';
  }

  return {
    nylasAPI: {
      is_account_updated: `${nylasAPIbaseURL}/account/status?id=${localStorage.getItem('nylas_account_id')}`,
      webhook: `${nylasAPIbaseURL}/schedule/webhook`,
      Login: () => {  // go to nylas login page
        window.location.href = `${nylasAPIbaseURL}/sso/auth?email=${localStorage.getItem('userName')}&return_page=${window.location.protocol + '//' + window.location.host + window.location.pathname}`;
      },
      GetAccountRecord: async () => {
        try {
          const record = await (await fetch(`${nylasAPIbaseURL}/account?email=${localStorage.getItem('userName')}&attr=id,nylasToken`)).json();
          const calendars = await (await fetch('https://api.nylas.com/calendars', { headers: { Authorization: record.nylasToken } })).json();
          if(record.id && record.nylasToken){
          localStorage.setItem('nylas_account_id', record.id);
          localStorage.setItem('nylas_token', record.nylasToken);
          for (const calendar of calendars) {
            if (calendar.is_primary) {
              localStorage.setItem('nylas_calendar_id', calendar.id);
              break;
            }
          }
        }
          return true;
        } catch (err) {
          return false;
        }
      }
    },
    // loginPage,
    // https://www.webmerge.me/developers/documents#developer_resources
    webmerge: 'https://www.webmerge.me/api'
  };
}
export default GetEnv;