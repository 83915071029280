// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect,useContext} from 'react';
import commonob from '../constants/common.json';
import getGlobalVarContext from '../context/getGlobalVarContext';
import { useFeatures } from '../providers/FeatureProvider';

// var desktopCapturer = require('electron').desktopCapturer;

// const { writeFile } = require('fs');
// let mediaRecorder:any; // MediaRecorder instance to capture footage
// const recordedChunks:any = [];
var recording_endpoint:any;
type Props = {
  callBackFromParent:(flag:any) => void;
  recordFlag:any;
  manageStart:(flag:string) => void;
};

export default function CallRecording(props:Props) {
  const {setGlobalVar,globalVar} = useContext(getGlobalVarContext());
  const { recordingUrls } = globalVar;
  const { cloud_recording } = useFeatures();


//   const getVideoSources = async () => {
//     const inputSources = await desktopCapturer.getSources({
//       types: ['window', 'screen']
//     });

//     const videoOptionsMenu =  Menu.buildFromTemplate(
//       inputSources.map((source) => {
//         selectSource(source)
//         return {
//           label: source.name,
//           click: () => selectSource(source)
//         };
//       })
//     );
//   }

//   const handleDataAvailable = (e:any) => {
//     recordedChunks.push(e.data);
//   }

//   const handleStart = async () => {
//   }
//   // Saves the video file on stop
//   const handleStop = async () => {

//     const blob = new Blob(recordedChunks, {
//       type: 'video/webm; codecs=vp9'
//     });
//     const buffer = Buffer.from(await blob.arrayBuffer());

//     const { filePath } = await dialog.showSaveDialog({

//       buttonLabel: 'Save video',
//       defaultPath: `vid-${Date.now()}.webm`
//     });

//     writeFile(filePath, buffer, () => console.log('video saved successfully!'));
//   }

//   const selectSource = async (source: any) => {
//     if(source.name == commonob.app_name)
//     {
//       // create audio and video constraints
//       const constraintsVideo = {
//           audio: false,
//           video: {
//               mandatory: {
//                   chromeMediaSource: 'desktop',
//                   chromeMediaSourceId: source.id
//               }
//           }
//       }
//       const constraintsAudio = {audio: true}

//       // create audio and video streams separately
//       const audioStream = await navigator.mediaDevices.getUserMedia(constraintsAudio)
//       const videoStream = await navigator.mediaDevices.getUserMedia(constraintsVideo)

//       // combine the streams 
//       const combinedStream = new MediaStream([...videoStream.getVideoTracks(), ...audioStream.getAudioTracks()])

//       // Create the Media Recorder
//       const options = { mimeType: 'video/webm; codecs=vp9' };
//       mediaRecorder = new MediaRecorder(combinedStream, options);

//       //Register Event Handlers
//       mediaRecorder.onstart = handleStart;
//       mediaRecorder.ondataavailable = handleDataAvailable;
//       mediaRecorder.onstop = handleStop;

//     }
//   }

  const subscriberRecordStart = async () => {

    var requestOptions = {
      method: 'POST',
      body:JSON.stringify({
        "meetingId": localStorage.getItem("currenteMeetingID"),
        "setRecording": false,
        "mediaPipeLine": ""
    })
    };
    fetch(`${commonob.start_stop_recording_url}record`, requestOptions)
      .then(response => {
        if (!response.ok){
          alert("Oops! Something went wrong!")
          props.callBackFromParent(1)
        }
        return response.json()
      })
      .then(result => {
          if (result.failures){
            alert("Oops! Something went wrong!")
            props.callBackFromParent(1)
          }else{
          recording_endpoint=result.MediaCapturePipeline.MediaPipelineId
          }
        })
      .catch(error => console.log('error', error));
  }

  const subscriberRecordStop = async () => {
    setGlobalVar((oldData)=> ({ ...oldData, recordInProcess: true }))
    var requestOptions = {
      method: 'POST',
      body:JSON.stringify({
        "meetingId": "",
        "setRecording": true,
        "mediaPipeLine": recording_endpoint
    })
    };
    fetch(`${commonob.start_stop_recording_url}record`, requestOptions)
      .then(response => {
        if (!response.ok){
          alert("Oops! Something went wrong!")
          props.callBackFromParent(1)
        }
        return response.json()
      })
      .then(result => {
          if (result.failures){
            alert("Oops! Something went wrong!")
            props.callBackFromParent(1)
          }else{
            var requestOptions = {
              method: 'POST',
              body:JSON.stringify({
                "meetingId": localStorage.getItem("currenteMeetingID")
            })
            };
            fetch(`${commonob.start_stop_recording_url}process`, requestOptions)
              .then(response =>  {
                setGlobalVar((oldData)=> ({ ...oldData, recordInProcess: false }))
                if (!response.ok){
                  alert("Oops!.Something went wrong!")
                  props.callBackFromParent(1)
                }
                return response.json()
              })
              .then(result => { 
                setGlobalVar((oldData)=> ({ ...oldData, recordInProcess: false }))
                setGlobalVar((oldData)=> ({ ...oldData, recordingUrls:[...recordingUrls,result.processedUrl] }))
                })
              .catch(error => {
                console.log('error', error)
                setGlobalVar((oldData)=> ({ ...oldData, recordInProcess: false }))
              })
          }
        })
      .catch(error => console.log('error', error));
  }
  
  useEffect(() => {
    if (cloud_recording){
      if(props.recordFlag==1){              
        subscriberRecordStart();
        props.manageStart("");
      }
      else{                            
        subscriberRecordStop();
        props.manageStart("")
      }
    }
  }, []);
  

  return (
    <></>
  );
}