/* eslint-disable */
import React from 'react';
import classNames from 'classnames/bind';
import styles from './ScheduleMeetingForm.css';
import commonob from '../constants/common.json'
import ParticipantItem from './ParticipantItem';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const cx = classNames.bind(styles);

function getRandomKey() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 16; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}

type Props = {
    callbackFromParent: (b: any,reset:boolean) => void;
    reset: boolean;
    selectedEmail:any;
  };
  let FilteredEmailItems:any = [];

class ParticipantMail extends React.Component <any, any> {
    items:any;
    constructor(props:Props) {
        super(props);
        this.state = {
            items: this.props.selectedEmail,
            error: null,
            email_data:[],
            currentItem: {
                email: '',
                key: ''
            }
        }
        this.handleInput = this.handleInput.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.openList = this.openList.bind(this)
    }

    // open and close dropdown email list
    openList = (isOpen:boolean) => {
        let emailDropDown = document.getElementById("emailDropDown")
        if (isOpen && emailDropDown != null){
          emailDropDown.style.display = "block";
        }
         if (!isOpen && emailDropDown != null){
          emailDropDown.style.display = "none";
        }
    }

    async componentDidMount() {
        //close dropdown on esc key press        
        document.addEventListener('keydown',  (event:any) => {
            if (event.key === "Escape") {
                 this.openList(false)            
            }
          });
             
        const isGoodEmail = (filters, email) => {
            if (!RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i).test(email)) {
              return false;
            }
            for (const filter of filters) {
              if (filter.test(email)) {
                return false;
              }
            }
            return true;
          };
        
            if (FilteredEmailItems.length === 0) {
              fetch('https://api.nylas.com/contacts?source=address_book&limit=1000', {
                  headers:{
                    Authorization: localStorage.getItem('nylas_token')
                  }
              })
                .then((response) => response.json()).then((contacts)=>{
                    const with_name = [];
                    const without_name = [];
                    const filters = [];
                    filters.push(new RegExp(/no.?reply/i));
                    filters.push(new RegExp(/notify|notification/i));
                    filters.push(new RegExp(/news.+@/i));
                    filters.push(new RegExp(/announcement/i));
                    filters.push(new RegExp(/@slack-mail.com/i));
                    filters.push(new RegExp(/tips/i));
                    // TODO: filter same address in different caps
                    // TODO: get source=inbox&limit=1000, if user used it, add it to address_book
                    contacts.map((contact) => {
                      contact.emails.map((emailObj) => {
                        if (isGoodEmail(filters, emailObj.email)) {
                          let name = '';
                          if (contact.given_name) {
                            name = '(' + name + contact.given_name;
                            if (contact.middle_name) {
                              name = name + ' ' + contact.middle_name;
                            }
                            if (contact.surname) {
                              name = name + ' ' + contact.surname;
                            }
                          }
                          if (contact.nickname) {
                            if (name) {
                              name = name + ' | ' + contact.nickname + ')';
                            } else {
                              name = '(' + contact.nickname + ')';
                            }
                          } else if (name) {
                            name += ')';
                          }
          
                          if (name) {
                            with_name.push({
                              email: emailObj.email,
                              email_name: `${emailObj.email} ${name}`,
                              from_contacts: true
                            });
                          } else {
                            without_name.push({
                              email: emailObj.email,
                              email_name: `${emailObj.email} ${name}`,
                              from_contacts: true
                            });
                          }
                        }
                      });
                    });
          
                    for (let i = 0; i < without_name.length; i++) {
                      for (const item of with_name) {
                        if (without_name[i].email === item.email) {
                          without_name[i].email = null;
                        }
                      }
                    }
                    FilteredEmailItems = without_name.filter((value) => { if (value.email) return value; });
                    for (const item of with_name) {
                      FilteredEmailItems.push(item);
                    }
                    FilteredEmailItems.sort((a, b) => a.email.localeCompare(b.email));
                    this.setState({email_data:FilteredEmailItems});
                })
                .catch(error => console.log('error', error));
            } else {
                this.setState({email_data:FilteredEmailItems});
            }
             this.keyNav()
    }

    async componentDidUpdate(){
        var input:any = document.getElementById('emailInput');
        if (input != document.activeElement){
            this.openList(false)
        }
        if (this.props.reset){
            if (this.state.currentItem.email != '') {
                await this.setState({
                    items: [],
                    currentItem: {
                        email: '',
                        key: ''
                    }
                })
            }
        }
    }

    handleInput(event:any) {
        this.openList(true)            

        this.setState({
            currentItem: {
                email: event.target.value,
                key: Date.now()
            },
            error: null
        })
        this.remainEmailList(event.target.value);                    

        this.props.callbackFromParent(this.state.items)
    }


    // check for error if invalid email
    isValid = (email:any) => {
        let error = null;

        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (this.isAlreadyAdded(email)) {
            error = `${email} -  is already invited.`;
        }

        if (error) {
            this.setState({ error });

            return false;
        }

        return true;
    }

    // check for valid mail address
    isEmail = (email:any) => {
        return emailRegex.test(email);
    }

    // check if entered mail already exist
    isAlreadyAdded = (email:any) => {
        const emailArray = this.state.items.map((item:any) => item)
        return emailArray.includes(email);
    }

    // searchable dropdown email list code
    selectedEmail = async () => {
        this.keyNav()
        var input:any, filter, ul, li, a, i, txtValue;
        input = document.getElementById("emailInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        if (ul != null){
            li = ul.getElementsByTagName("a");
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("span")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                } else {
                    li[i].style.display = "none";
                }
            } 
        }      
    }
  
    handleKeyDown = async (evt:any) => {
        if (["Enter", "Tab", ",", ";", " "].includes(evt.key)) {
            evt.preventDefault();

            const newItem = this.state.currentItem;

            // check for entered value and validity of value
            if (newItem.email && this.isValid(newItem.email)) {

                // adds the new object to array
                if ((newItem.email) !== '') {
                    const newItems = [...this.state.items, newItem.email];
                    await this.setState({
                        items: newItems,
                        currentItem: {
                            email: '',
                            key: ''
                        }                        
                    })
                    this.openList(false)
                }

                this.remainEmailList(newItem.email);                    
                // pass the array to parent component CreateMeeting 
                this.props.callbackFromParent(this.state.items)
            }
        }
    };

    // paste email code
    handlePaste = async (evt:any) => {
        evt.preventDefault();
        let paste = evt.clipboardData.getData("text");
        let emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

        if (emails) {
            let toBeAdded = emails.filter((email:any) => !this.isAlreadyAdded(email));

            let data = toBeAdded.map((item:any) => ({
                'email': item,
                'key': Date.now()
            }))
            const newData = [...this.state.items]
            data.map((email:any)=>{
                newData.push(email.email)
                this.remainEmailList(email.email);                    
            })
 
            await this.setState({
                items: newData,
                currentItem: {
                    email: '',
                    key: ''
                }
            })
            this.props.callbackFromParent(this.state.items)
        }
    };

    // remove email from selected list
    async deleteItem(key:any) {

        // check for the deleted key and gives data with remaining keys
        const filteredItems = this.state.items.filter((item:any) =>
            item !== key);
        await this.setState({
            items: filteredItems
        })
        FilteredEmailItems.map((val:any)=>{
            if(val.email == key){
                this.state.email_data.push(val)
            }
        })
        // pass the list to parent component CreateMeeting
        this.props.callbackFromParent(this.state.items)
    }

     keyNav = async () => {	
        var divs:any = [];	
        var id = "emailListDropDown"; 
        var that = this;	
        var element:any = document.getElementById("emailListDropDown")	
        if(element != null){	
          var div:any = element.getElementsByTagName('a'),	
          selectedDiv = 0,	
          i;	
           
        for(i = 0; i < div.length; i++){	
          if (div[i].style.display != 'none'){	
            await divs.push(div[i])	
          }	
        }	
          for(i = 0; i < divs.length; i++){      	
              divs[selectedDiv].focus();	
          }	
          element.getElementsByTagName('input')[0].onkeydown = function(e){	                       
               var x = 0;
               if(e.keyCode == 38)
                   x = -1;
               else if(e.keyCode == 40)
                   x = 1;
               else
                   return;
                that.openList(true)
               divs[selectedDiv].style.backgroundColor = '';
               divs[selectedDiv].style.color = '';                 
               selectedDiv = ((selectedDiv+x)%divs.length);
               selectedDiv = selectedDiv < 0 ? divs.length+selectedDiv : selectedDiv;
               element.getElementsByTagName('input')[0].value = (divs[selectedDiv].textContent);
               that.setState({
                currentItem: {
                    email: divs[selectedDiv].textContent,
                    key: Date.now()
                },
                error: null
            })
               divs[selectedDiv].style.backgroundColor = '#2d99e5';
               divs[selectedDiv].style.color = '#fff';                 
               divs[selectedDiv].scrollIntoView();
               divs[selectedDiv].focus();                  
          };
        }	
        }

        // add email on selected list
        addParticipant = (item:any) =>{
            if(this.isValid(item.email)){
                const newItem = {
                     email: item.email,
                     key: Date.now()   
                }
                const newItems = [...this.state.items, newItem.email];
                this.setState({
                    items: newItems
                })
                this.props.callbackFromParent(newItems)
            }
            this.setState({
                    currentItem: {
                        email: '',
                        key: ''
                    }
                })
            this.remainEmailList(item.email);               
            this.openList(false)
            this.keyNav()    
        }

        remainEmailList(email:any){
            let remainList:any=[];
            this.state.email_data.map((val:any)=>{
                if(val.email != email){
                    remainList.push(val)
                }
            })
            this.setState({email_data:remainList})  
        }

    render() {
        return (
            <>
            <div id='emailListDropDown'>
                <div  className={cx("custom")} >
                {this.state.error && <p className={cx('mail-error')}>{this.state.error}</p>}
                    <input
                        id="emailInput"
                        className={cx('participant-input')}
                        type='email'
                        name='email'
                        onKeyDown={this.handleKeyDown}
                        value={this.state.currentItem.email}
                        placeholder="Invite Participants"
                        onChange={this.handleInput}
                        onKeyUp={(e: any) => {
                            if (e.key !== "ArrowDown"
                              && e.key !== "ArrowUp"
                              && e.key !== "ArrowLeft"
                              && e.key !== "ArrowRight"){   
                                this.selectedEmail()
                            }                       
                          }}                        
                          onPaste={this.handlePaste}
                    />        
                    <ParticipantItem
                        className={cx('listItems')}
                        items={this.state.items}
                        deleteItem={this.deleteItem}
                    />
                </div>
             <div id="emailDropDown" className={cx("emailDropDown")} >
                        <div id="myUL" >
                            {this.state.email_data.map((item:any,index:any)=>{
                                return(         
                                    <a key={index} className={cx("underList")} id="list"  onClick={()=>this.addParticipant(item)}>
                                        <span key={index}>{item.email}</span></a>
                                )
                            })}
                        
                        </div>
                    </div>
                    </div>
            </>
        );
    }
}

export default ParticipantMail;