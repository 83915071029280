/* eslint-disable  */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { VideoTileState } from 'amazon-chime-sdk-js';
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getRosterContext from '../context/getRosterContext';
import getUIStateContext from '../context/getUIStateContext';
import useRaisedHandAttendees from '../hooks/useRaisedHandAttendees';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './Roster.css';
import ClassMode from '../enums/ClassMode';
import ViewMode from '../enums/ViewMode';
import Tooltip from './Tooltip';
import getGlobalVarContext from '../context/getGlobalVarContext';
import localStorageKeys from "../constants/localStorageKeys.json"
import LoadingSpinner from './LoadingSpinner';

const cx = classNames.bind(styles);

type Props = {
  viewMode: ViewMode;
  publicChannel: string;
}

export default function Roster(props: Props) {
  const { viewMode, publicChannel } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const roster = useContext(getRosterContext());
  const [videoAttendees, setVideoAttendees] = useState(new Set());
  const raisedHandAttendees = useRaisedHandAttendees();
  const intl = useIntl();
  const { globalVar, } = useContext(getGlobalVarContext());
  const { attendeeIdFullScreenActive } = globalVar;
  const [forceUpadateKey,setForceUpdateKey] = useState(Math.floor(Math.random() * 1000))

  useEffect(() => {
    const tileIds: { [tileId: number]: string } = {};
    // <tileId, attendeeId>
    const realTimeVideoAttendees = new Set();

    const removeTileId = (tileId: number): void => {
      const removedAttendeeId = tileIds[tileId];
      delete tileIds[tileId];
      realTimeVideoAttendees.delete(removedAttendeeId);
      setVideoAttendees(new Set(realTimeVideoAttendees));
    };

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {
        if (
          !tileState.boundAttendeeId ||
          tileState.isContent ||
          !tileState.tileId
        ) {
          return;
        }

        if (tileState.active) {
          tileIds[tileState.tileId] = tileState.boundAttendeeId;
          realTimeVideoAttendees.add(tileState.boundAttendeeId);
          setVideoAttendees(new Set(realTimeVideoAttendees));
        } else {
          removeTileId(tileState.tileId);
        }
      },
      videoTileWasRemoved: (tileId: number): void => {
        removeTileId(tileId);
      }
    });

    if(localStorage.getItem(localStorageKeys.CLASS_MODE) == ClassMode.Teacher){
      localStorage.setItem('hostId', localStorage.getItem("currentAtendeeId"));
    }
  }, []);
  const myAttendeeId = chime?.configuration?.credentials?.attendeeId;
  let attendeeIds;
  if (chime?.meetingSession && roster) {
    attendeeIds = Object.keys(roster)
    // .filter( (aId) => aId !== myAttendeeId );
  }

  return (
    <div className={cx('rosterDiv')}>
      <div className={cx('rosterscroll')} >
        {attendeeIds &&
          attendeeIds.map((attendeeId: string) => {
            const rosterAttendee: RosterAttendeeType = roster[attendeeId];
            var initials = roster[attendeeId].name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join(''); 

            return (
              <div
                style={{ display: rosterAttendee.name == 'Unknown' ? 'none' : '' }}
                key={`${attendeeId}_${forceUpadateKey}`} className={cx('rosterAttendee')}>
                <Tooltip tooltip={(attendeeId == localStorage.getItem("hostId")) ? `${rosterAttendee.name}(Host)` : rosterAttendee.name} >
                <div  className={cx('attendeeInfo')}>
                    <div className={cx('userProfile',{
                      isHost: attendeeId == localStorage.getItem("hostId"),
                      isActiveAttd: attendeeIdFullScreenActive == attendeeId,
                    })}>{initials}
                    </div>
                  <div className={cx(
                    'attdname', {
                    hostName: attendeeId == localStorage.getItem("hostId"),
                    activeAttdName: attendeeIdFullScreenActive == attendeeId,
                  })}>{(attendeeId == localStorage.getItem("hostId")) ? `${rosterAttendee.name}(Host)` : rosterAttendee.name}
                  </div>
                </div>
                </Tooltip>
                <div className={cx('iconDiv')}>
                  {localStorage.getItem(localStorageKeys.CLASS_MODE) != ClassMode.Teacher && viewMode === ViewMode.Room && (
                    <>
                      <div className={cx('rostermicicon')}>
                        {rosterAttendee.muted ? (
                          <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-mute.png" /></span>
                        ) : (
                          attendeeIdFullScreenActive == attendeeId ?
                            <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-green.png" /></span>
                            :
                            <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-grey.png" /></span>
                        )}
                      </div>
                      <div className={cx('rostervideoicon')}>
                        {videoAttendees.has(attendeeId) ? (
                          <span className={cx('rosterunmuteicon')}> <img src="/icons1/video-on.png" /></span>
                        ) : (
                          <span className={cx('rosterunmuteicon')}> <img src="/icons1/video-off-grey.png" /></span>
                        )}
                      </div>
                    </>
                  )}

                  {localStorage.getItem(localStorageKeys.CLASS_MODE) == ClassMode.Teacher && viewMode === ViewMode.Room && (
                    <>{
                      attendeeId == localStorage.getItem("hostId") ?                  
                         <div className={cx('rostervideoicon')} >
                         { 
                         videoAttendees.has(attendeeId) ? (
                           <span className={cx('rosterunmuteicon')}> <img src="/icons1/video-on.png" /></span>
                         ) : (
                           <span className={cx('rosterunmuteicon')}> <img src="/icons1/video-off-grey.png" /></span>
                         )}
                       </div>
                      :
                      <Tooltip tooltip={videoAttendees.has(attendeeId) ? "Video Off" : "Video On"} >
                      <div className={cx('rostervideoicon')} onClick={() => {
                        let msg1 = {
                          name: chime?.roster[attendeeId].name,
                          payload: {
                            attendeeId: attendeeId, focus: !videoAttendees.has(attendeeId), message: "video-enable"
                          },
                          timestampMs: Date.now(),
                          type: "video-enable"
                        }
                        chime?.pubnub?.publish({ channel: publicChannel, message: msg1 })
                        chime.roster[attendeeId]['isVodLoading'] = true;
                        // let msgType = videoAttendees.has(attendeeId) ? "disable-video" : "enable-video"
                        // chime?.sendMessage(msgType, {
                        //   attendeeId: attendeeId
                        // });
                        setTimeout(()=>{
                          chime.roster[attendeeId]['isVodLoading'] = false;
                          setForceUpdateKey(Math.floor(Math.random() * 1000))
                        },1500)
                      }}>
                        { chime.roster[attendeeId].isVodLoading ? <LoadingSpinner className="small"/>
                          : 
                        videoAttendees.has(attendeeId) ? (
                          <span className={cx('rosterunmuteicon')}> <img src="/icons1/video-on.png" /></span>
                        ) : (
                          <span className={cx('rosterunmuteicon')}> <img src="/icons1/video-off-grey.png" /></span>
                        )}
                      </div>
                      </Tooltip>
                        }
                      {
                      attendeeId == localStorage.getItem("hostId") ?
                                          <div className={cx('rostermicicon')}>
                                          {                                         
                                          rosterAttendee.muted ? (
                                            <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-mute.png" /></span>
                                          ) : (
                                            attendeeIdFullScreenActive == attendeeId ?
                                              <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-green.png" /></span>
                                              :
                                              <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-grey.png" /></span>
                                          )}
                                        </div>                      
                        :
                        typeof rosterAttendee.muted === 'boolean' && (                    
                        <Tooltip tooltip={!rosterAttendee.muted ? "Mute attendee" : "Unmute attendee"} >
                        <div onClick={() => {
                          let msg1 = {
                            name: chime?.roster[attendeeId].name,
                            payload: {
                              attendeeId: attendeeId, focus: !rosterAttendee.muted, message: "mute-attendee"
                            },
                            timestampMs: Date.now(),
                            type: "mute-attendee"
                          }
                          chime?.pubnub?.publish({ channel: publicChannel, message: msg1 })
                          chime.roster[attendeeId]['isMicLoading'] = true;
                          // let msgType = rosterAttendee.muted ? "unmute" : "mute"
                          // chime?.sendMessage(msgType, {
                          //   attendeeId: attendeeId
                          // });
                          setTimeout(()=>{
                            chime.roster[attendeeId]['isMicLoading'] = false;
                            setForceUpdateKey(Math.floor(Math.random() * 1000))
                          },1500)
                        }} className={cx('rostermicicon')}>
                          { chime.roster[attendeeId].isMicLoading ? <LoadingSpinner className="small"/>
                            : 
                          rosterAttendee.muted ? (
                            <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-mute.png" /></span>
                          ) : (
                            attendeeIdFullScreenActive == attendeeId ?
                              <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-green.png" /></span>
                              :
                              <span className={cx('rosterunmuteicon')}> <img src="/icons1/mic-grey.png" /></span>
                          )}
                        </div>
                      </Tooltip>)
                    }

                    {
                      attendeeId == localStorage.getItem("hostId") ?                     
                        <div
                        className={cx('rosterhangonicon')}>
                        <span className={cx('rosterunmuteicon')}> <img src="/icons1/hang_on.png" /></span>
                      </div>
                      :
                      <Tooltip tooltip={'Remove attendee'} >
                      <div onClick={() => {
                        if (confirm(`Are you sure to remove ${rosterAttendee.name} from meeting ?`)) {
                          let msg1 = {
                            name: chime?.roster[attendeeId].name,
                            payload: {
                              attendeeId: attendeeId, message: "remove-attendee"
                            },
                            timestampMs: Date.now(),
                            type: "remove-attendee"
                          }
                          chime?.pubnub?.publish({ channel: publicChannel, message: msg1 })

                        }
                      }}
                        className={cx('rosterhangonicon')}>
                        <span className={cx('rosterunmuteicon')}> <img src="/icons1/hang_on.png" /></span>
                      </div>
                    </Tooltip>
                    }
                    </>
                  )}
                </div>
                {raisedHandAttendees.has(attendeeId) && (
                  <div className={cx('raisedHand')}>
                    <span
                      role="img"
                      aria-label={intl.formatMessage(
                        {
                          id: 'Roster.raiseHandAriaLabel'
                        },
                        {
                          name: rosterAttendee.name
                        }
                      )}
                    >
                      ✋
                    </span>
                  </div>
                )}
              </div>
            );
          })}

      </div>

    </div>
  );
}
