// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import React, { ReactNode, useEffect, useReducer } from 'react';
import commonob from "../constants/common.json"

import getUIStateContext, {
  initialState,
  SetClassModeActon,
  StateType
} from '../context/getUIStateContext';

const reducer = (state: StateType, action: SetClassModeActon): StateType => {
  switch (action.type) {
    case 'SET_CLASS_MODE':
      return {
        ...state,
        classMode: action.payload.classMode
      };
    default:
      throw new Error();
  }
};

type Props = {
  children: ReactNode;
};

export default function UIStateProvider(props: Props) {
  const { children } = props;
  const UIStateContext = getUIStateContext();

  useEffect(()=>{
    if(document.body){
      var bodyStyles = document.body.style;
      bodyStyles.setProperty('--color_primary', commonob.primary_color);
      bodyStyles.setProperty('--color_secondary', commonob.secondary_color);
      bodyStyles.setProperty('--color_border_color_light', commonob.border_color); 
    }
  },[])

  return (
    <UIStateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </UIStateContext.Provider>
  );
}
