/* eslint-disable */ 

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import {
  MeetingSessionStatus,
  MeetingSessionStatusCode
} from 'amazon-chime-sdk-js';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getMeetingStatusContext from '../context/getMeetingStatusContext';
import ClassMode from '../enums/ClassMode';
import MeetingStatus from '../enums/MeetingStatus';
import localStorageKeys from "../constants/localStorageKeys.json"
import getGlobalVarContext from '../context/getGlobalVarContext';

type Props = {
  children: ReactNode;
};

var timeOut:any;
export default function MeetingStatusProvider(props: Props) {
  const MeetingStatusContext = getMeetingStatusContext();
  const { children } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [meetingStatus, setMeetingStatus] = useState<{
    meetingStatus: MeetingStatus;
    errorMessage?: string;
  }>({
    meetingStatus: MeetingStatus.Loading
  });
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const audioElement = useRef(null);
  const { globalVar } = useContext(getGlobalVarContext());
  const { recordingUrls,totalMeetingMinutes } = globalVar;

  useEffect(() => {
    const start = async () => {
      try {
        await chime?.createRoom(
          query.get('title'),
          query.get('name'),
          query.get('region'),
          localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher ? 'teacher' : 'student'
        );

        setMeetingStatus({
          meetingStatus: MeetingStatus.Succeeded
        });

        chime?.audioVideo?.addObserver({
          audioVideoDidStop: (sessionStatus: MeetingSessionStatus): void => {
            if (
              sessionStatus.statusCode() ===
              MeetingSessionStatusCode.AudioCallEnded
            ) {
              if(localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher){
                chime?.leaveRoom(true,recordingUrls,totalMeetingMinutes);
              }else{
                chime?.leaveRoom(false,[],0);
              }
              history.push('/');
            }
          }
        });

        await chime?.joinRoom(audioElement.current);
        clearTimeout(timeOut)
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        if (query.get('timeRemain') == "true"){
          console.log("time remain")
          timeOut = setTimeout(()=>{
            start();
          },5000)
        }else{
          console.log("time not remain")
          clearTimeout(timeOut)
          setMeetingStatus({
            meetingStatus: MeetingStatus.Failed,
            errorMessage: error.message
          });
        } 
      }
    };
    start();
  }, []);

  return (
    <MeetingStatusContext.Provider value={meetingStatus}>
      {/* eslint-disable-next-line */}
      <audio ref={audioElement} style={{ display: 'none' }} />
      {children}
    </MeetingStatusContext.Provider>
  );
}
