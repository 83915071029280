/* eslint-disable  */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { VideoTileState, DefaultActiveSpeakerPolicy } from 'amazon-chime-sdk-js';
import classNames from 'classnames/bind';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getRosterContext from '../context/getRosterContext';
import ViewMode from '../enums/ViewMode';
import useRaisedHandAttendees from '../hooks/useRaisedHandAttendees';
import RosterAttendeeType from '../types/RosterAttendeeType';
import styles from './RemoteVideoGroup.css';
import { Size } from './RemoteVideo';
import RosterLayout from './RosterLayout';
import RosterLayout1 from './RosterLayout1';
import LocalVideo from './LocalVideo';
import Tooltip from './Tooltip';
import getGlobalVarContext from '../context/getGlobalVarContext';
import commonob from '../constants/common.json';

const cx = classNames.bind(styles);
const MAX_REMOTE_VIDEOS = 16;

type Props = {
  viewMode: ViewMode;
  isSmallScreen: boolean;
  isRightBarEnabled: boolean;
  isContentShareEnabled: boolean;
  shareScreenSmall: (ss: boolean) => void;
  setActiveAttendeeForChatParent: (id: string) => void;
};

let tm: any = 0;
let r_attd = '';
let tileFullView = false;

export default function RemoteVideoGroup(props: Props) {
  const {
    viewMode,
    isSmallScreen,
    isContentShareEnabled,
    isRightBarEnabled,
    shareScreenSmall,
    setActiveAttendeeForChatParent
  } = props;

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const roster = useContext(getRosterContext());
  const [visibleIndices, setVisibleIndices] = useState<{
    [index: string]: { boundAttendeeId: string };
  }>({});

  const [minmax, setMinMax] = useState(true);
  // const [tileView, setTileView] = useState(false);
  // const [elemHeight, setElemHeight] = useState(0);
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [attendeeIdFullScreen, setAttendeeId] = useState('');
  const currentUser = chime?.configuration.credentials.attendeeId;
  const [localVideoView, setLocalVideoView] = useState(false);
  const { globalVar, setGlobalVar,  } = useContext(getGlobalVarContext());
  const {attendeeIdFullScreenActive,tileView,fullScreenMode} = globalVar;
  const query = new URLSearchParams(useLocation().search);

  const publicChannel=`${commonob.app_name}-chat-${query.get("title")}`

  const raisedHandAttendees = useRaisedHandAttendees();
  const videoElements: HTMLVideoElement[] = [];
  const tiles: { [index: number]: number } = {};
  let attdLength: any = Object.keys(roster);
  const intl = useIntl();
  const meetingRecorderName = "Unknown";



  const setGlobalVariables = (prop: string, value: any) => {

    switch (prop) {
      case "attendeeIdFullScreenActive":        
            setGlobalVar((oldData)=> ({ ...oldData, attendeeIdFullScreenActive: value }))          
        break;
      case "tileView":        
          setGlobalVar((oldData)=> ({ ...oldData, tileView: value }))          
        break;
      default:
        // code...
        break;
    }
    
}

  useEffect(() => {
    // if content is shareble --sanjay balai
    if (localStorage.getItem("contentshare") == 'true') {
      localStorage.setItem("contentshare", 'none');
      setAttendeeId("")
      setGlobalVariables("attendeeIdFullScreenActive","")
      setMinMax(false);
      setGlobalVariables('tileView',false)
    } else if (localStorage.getItem("contentshare") == 'false') {
      localStorage.setItem("contentshare", 'none');
      setMinMax(true);
    }
    // if content is shareble + contentshare screen is not small --sanjay balai
    if (isSmallScreen == false && isContentShareEnabled == true) {
      setAttendeeId("")
      setGlobalVariables("attendeeIdFullScreenActive","")
      setGlobalVariables('tileView',false)
    }
    // if no one can join meeting set tileview disable --sanjay balai
    if (attendeeIds.length == 0) {
      setGlobalVariables('tileView',false)
    }

    // this is for tileview video hieght get from class --sanjay balai
    // let window_height = document.querySelector(".tileView");
    // if (window_height) {
    //   setElemHeight(window_height.clientHeight - 50)
    // }
    if (chime?.roster[attendeeIdFullScreen]?.muted == true && !tileFullView) {
      setAttendeeId("")
      setGlobalVariables("attendeeIdFullScreenActive","")
      setActiveSpeaker("")
    }
    reorganize()
  },[isContentShareEnabled,isSmallScreen])

  const acquireVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        return index;
      }
    }
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (!(index in tiles)) {
        tiles[index] = tileId;
        return index;
      }
    }
    throw new Error('no tiles are available');
  };

  const releaseVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        delete tiles[index];
        return index;
      }
    }
    return -1;
  };

  const numberOfVisibleIndices = Object.keys(visibleIndices).reduce<number>(
    (result: number, key: string) => result + (visibleIndices[key] ? 1 : 0),
    0
  );

  const [videoAttendees, setVideoAttendees] = useState(new Set());

  const activeSpeakerCallback = (attendeeIds) => {
    //remove selfAttendeeId when Speaker active   --sanjay balai
    if (attendeeIds.length) {
      let selfAttendeeId = currentUser;
      function removeSelfAttendeeId(arr, value) {
        return arr.filter(function (id) {
          return id != value;
        });
      }
      let activeAttendee = removeSelfAttendeeId(attendeeIds, selfAttendeeId)

      if (activeAttendee.length) {

        // checks whether attendee is UnMute then only set as Active Speaker --sanjay balai
        if (chime?.roster[activeAttendee[0]]?.muted == false) {
          if (tm === 0) {
            tm = setTimeout(() => {
              if (attendeeIdFullScreen === activeAttendee[0]) {
                clearTimeout(tm);
              } else {
                if (localStorage.getItem('shareScreen') == 'false' && !tileFullView) {
                  setAttendeeId(activeAttendee[0])
                  setGlobalVariables("attendeeIdFullScreenActive",activeAttendee[0])
                  setActiveSpeaker(activeAttendee[0])
                  tm = 0
                } else {
                  setActiveSpeaker(activeAttendee[0])
                  tm = 0
                }
              }
            }, 2250)
          }
        }

      }
    };
  }

  // find the number of attendee join --sanjay balai
  let activeAttendee: any;
  if (chime?.meetingSession && roster) {

    activeAttendee = Object.keys(roster);

    activeAttendee.forEach(function (item: string, i: number) {
      if (item === currentUser) {
        activeAttendee.splice(i, 1);
        activeAttendee.unshift(item);
      }
      if(chime?.roster[item].name == ''){
        activeAttendee.splice(i, 1);
      }
    });

  }

  // remove self attendee for roster layout --sanjay balai
  let selfAttendeeId = currentUser;
  function removeSelfAttendeeId(arr, value) {

    return arr.filter(function (id) {
      return id != value;
    });
  }
  let attendeeIds = removeSelfAttendeeId(activeAttendee, selfAttendeeId)


  const getSize = (): Size => {
    if (numberOfVisibleIndices >= 10) {
      return Size.Small;
    }
    if (numberOfVisibleIndices >= 5) {
      return Size.Medium;
    }
    return Size.Large;
  };

  useEffect(() => {
    // active speaker listner called --sanjay balai
    chime?.audioVideo?.subscribeToActiveSpeakerDetector(
      new DefaultActiveSpeakerPolicy,
      activeSpeakerCallback
    );

    // remove tile id when attendee leave --sanjay balai
    const tileIds: { [tileId: number]: string } = {};
    const realTimeVideoAttendees = new Set();
    const removeTileId = (tileId: number): void => {
      const removedAttendeeId = tileIds[tileId];
      delete tileIds[tileId];
      realTimeVideoAttendees.delete(removedAttendeeId);
      setVideoAttendees(new Set(realTimeVideoAttendees));
      // when attendee video on fullscreen and suddenly attendee leave --sanjay balai
      if ((localStorage.getItem('shareScreen') == 'true' || tileFullView) && removedAttendeeId == r_attd) {
        setAttendeeId('')
        setGlobalVariables("attendeeIdFullScreenActive",'')
        shareScreenSmall(false);
        setGlobalVariables('tileView',false)
        tileFullView = false;
      }
    };

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {

        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent ||
          !tileState.tileId
        ) {
          return;
        }

        const index = acquireVideoIndex(tileState.tileId);

        chime?.audioVideo?.bindVideoElement(
          tileState.tileId,
          videoElements[index],
        );
        setVisibleIndices(previousVisibleIndices => ({
          ...previousVisibleIndices,
          [index]: {
            boundAttendeeId: tileState.boundAttendeeId
          }
        }));

        // code for audio/video enable or not start
        if (tileState.active) {
          tileIds[tileState.tileId] = tileState.boundAttendeeId;
          realTimeVideoAttendees.add(tileState.boundAttendeeId);
          setVideoAttendees(new Set(realTimeVideoAttendees));
        } else {
          removeTileId(tileState.tileId);
        }
        // code for audio/video enable or not end
      },
      videoTileWasRemoved: (tileId: number): void => {
        const index = releaseVideoIndex(tileId);
        setVisibleIndices(previousVisibleIndices => ({
          ...previousVisibleIndices,
          [index]: null
        }));
        // code for audio/video enable or not start
        removeTileId(tileId);
        // code for audio/video enable or not end

      }
    });

  }, []);

  // get height and width of tileview from class for responsive view --sanjay balai
  function reorganize() {
    let window_height= document.getElementById("tileView"); 
    let elHeight = window_height?.clientHeight - 50;
    let maxCols = 7;
    let numPeople = attdLength.length;

    let cols = 1;
    for (cols; cols <= maxCols; cols++) {
      if (numPeople <= (cols * cols)) { break; }
    }
    let row = Math.ceil(numPeople / cols)
    if (row === 1){
      row = 1.5;
    }
    let dd = (100 / cols) - 1;
    let Twidth = `${dd}%`;
    let Theight = `${elHeight / row}px`
    return { width: Twidth, height: Theight };
  }

  useEffect(()=>{
    draggable(document.getElementById('attendeeRoster'));
  },[])
  
  function draggable(el:any) {
    el.addEventListener('mousedown', function(e) {
      var offsetX = e.clientX - parseInt(window.getComputedStyle(el).left);
      var offsetY = e.clientY - parseInt(window.getComputedStyle(el).top);
      
      function mouseMoveHandler(e) {
        if((window.innerHeight) - 250 > (e.clientY - offsetY) && (window.innerWidth) - 175 > (e.clientX - offsetX)){
          el.style.top = (e.clientY - offsetY) + 'px';
          el.style.left = (e.clientX - offsetX) + 'px';
        }
     
        if (parseInt(window.getComputedStyle(el).left) < 100){
          el.style.left = '100px';
        }
        if (parseInt(window.getComputedStyle(el).top) < 0){
          el.style.top = '0px';
        }
      }
  
      function reset() {
        window.removeEventListener('mousemove', mouseMoveHandler);
        window.removeEventListener('mouseup', reset);
      }
  
      window.addEventListener('mousemove', mouseMoveHandler);
      window.addEventListener('mouseup', reset);
    });
  }
  
    const resizeAttendeeRoster = () => {
      const attendeeRoster:any = document.getElementById("attendeeRoster")
      attendeeRoster.style.top = "auto";
      attendeeRoster.style.left = "auto";
    }

  return (
    <>
       <div className={cx('rightmain')}>
          <div className={cx('rightmainleft')}>
            <Tooltip tooltip={intl.formatMessage({ id: 'TileView.minimize' })
            }>
              <div
                onClick={
                  () => {
                    setMinMax(false)
                    setGlobalVariables('tileView',false)
                    tileFullView = false;
                    shareScreenSmall(false);
                    resizeAttendeeRoster();
                  }
                } className={cx('minimize')}>
                <img src="/icons1/minimizeIcon.png" />
              </div>
            </Tooltip>

            <Tooltip tooltip={intl.formatMessage({ id: 'TileView.maximize' })}>
            <div
              onClick={
                () => {
                  setMinMax(true)
                  setGlobalVariables('tileView',false)
                  tileFullView = false;
                  shareScreenSmall(false);
                  resizeAttendeeRoster();
                }
              } className={cx('maximize')}>
              <img src="/icons1/maximizeIcon.png" />
            </div>
          </Tooltip>

            <div
              onClick={
                () => {
                  if (attendeeIds.length != 0) {
                    reorganize();
                    setGlobalVariables('tileView',true);
                    shareScreenSmall(true);
                    setMinMax(true);
                    tileFullView = false;
                    localStorage.setItem('fullscreen', 'false');
                    resizeAttendeeRoster();
                  }
                }
              } className={cx('tileViewIcon')}>
                <Tooltip tooltip={intl.formatMessage({ id: 'TileView.gridview' })}>
                <img src="/icons1/gridViewIcon.png" />
                </Tooltip>              
            </div>
          </div>
        </div>
      <div
        id="tileView"
        style={tileView ? { background: 'none' } : {}}
        className={cx(
          'remoteVideoGroup',
          {
            roomMode: viewMode === ViewMode.Room && !tileView,
            tileView: tileView && attendeeIds.length != 0,
            tileViewRightBarEnabled: tileView && isRightBarEnabled && attendeeIds.length != 0,
          }
        )}
      >
        {numberOfVisibleIndices === 0 && !isContentShareEnabled && (
          <div className={cx('instruction')}>
          </div>
        )}     

        <div id='attendeeRoster'
          className={cx({
            videostart: !tileView,
            videoTileView: tileView && attendeeIds.length != 0,
          })}>
          <div
            className={cx(
              `attendeeTileViews-${attendeeIds.length}`,
              {
                attendeeTileView: tileView,
              })}>
            <div
              style={tileView === true ? { width:  reorganize().width, height: reorganize().height, margin: '0.50%'} : {width:'100%',height:'100%',margin:'10px 0 10px 0'}}              className={cx({
                videoOff: localVideoView === true,
                rosterMinimize: !minmax
              })}>
              <LocalVideo
                onShowLocalVidoe={(bl: boolean): Boolean => {
                  setLocalVideoView(bl);
                  return bl;
                }}
                tileView={tileView}
                localVideoView={localVideoView}
                videoLength={attendeeIds.length}
              />
            </div>


            {Array.from(Array(MAX_REMOTE_VIDEOS).keys()).map((key, index) => {
              const visibleIndex1 = visibleIndices[index];
              let rosterAttendee1: RosterAttendeeType = {};
              let raisedHand = false;
              let attId1 = '';

              if (visibleIndex1 && roster) {
                rosterAttendee1 = roster[visibleIndex1.boundAttendeeId];
                attId1 = visibleIndex1.boundAttendeeId;
                if (raisedHandAttendees) {
                  raisedHand = raisedHandAttendees.has(visibleIndex1.boundAttendeeId);
                }
              }
              let rosterLength = attendeeIds.length;
              return (

                <div
                  key={key}
                  style={ tileView ?  { display: visibleIndex1 ? "block" : "none", width: reorganize().width, height: reorganize().height , margin: '0.50%'} : {margin: attId1 == attendeeIdFullScreen ? '0' :'10px 0 10px 0'}}
                  className={cx(
                    {
                      videocenter: attendeeIdFullScreen != '' && attId1 == attendeeIdFullScreen && !tileView && !fullScreenMode,
                      videocenterWithRightsideEnable:attendeeIdFullScreen != '' && attId1 == attendeeIdFullScreen && !tileView && isRightBarEnabled && !fullScreenMode,
                      fullScreenvideocenter: attendeeIdFullScreen != '' && attId1 == attendeeIdFullScreen && !tileView && viewMode != ViewMode.ScreenShare && fullScreenMode,
                      fullScreenvideocenterWithRightsideEnable: attendeeIdFullScreen != '' && attId1 == attendeeIdFullScreen &&  isRightBarEnabled && !tileView && viewMode !== ViewMode.ScreenShare && fullScreenMode,
                      rosterMinimize: !minmax
                    }
                  )}
                >
                  <RosterLayout
                    key={index}
                    videoLength={rosterLength}
                    viewMode={viewMode}
                    tileView={tileView}
                    enabled={!!visibleIndex1}
                    videoElementRef={useCallback((element: HTMLVideoElement | null) => {
                      if (element) {
                        videoElements[index] = element;
                      }
                    }, [])}
                    size={getSize()}
                    rosterAttendee={rosterAttendee1}
                    raisedHand={raisedHand}
                    isContentShareEnabled={isContentShareEnabled}
                    isRightBarEnabled={isRightBarEnabled}
                    boundAttendeeId={attId1}
                    attId_={attendeeIdFullScreen}
                    rendervideotoparent={
                      (baid) => {
                        setAttendeeId(baid)
                        setGlobalVariables("attendeeIdFullScreenActive",baid)
                        r_attd = baid;
                        shareScreenSmall(true);
                        setGlobalVariables('tileView',false)
                        tileFullView = true;
                      }
                    }
                    resetFullscreen={
                      () => {
                        setAttendeeId("")
                        setGlobalVariables("attendeeIdFullScreenActive",'')
                        shareScreenSmall(false);
                        tileFullView = false;
                      }
                    }
                    isSmallScreen={isSmallScreen}
                    activeSpeaker={activeSpeaker}
                    activeSpeakerMuted={chime?.roster[attendeeIdFullScreen]?.muted? true : false}                    
                    v_height={reorganize().height}
                    muteunmuteAttendee={
                      (id: string)=> {
                        
                        let msg1 = {
                                name: chime?.roster[currentUser].name,
                                payload: {
                                  attendeeId: id, focus: !rosterAttendee1.muted, message: "mute-attendee"
                                },
                                timestampMs: Date.now(),
                                type: "mute-attendee"
                            }
                            chime?.pubnub?.publish({ channel : publicChannel, message: msg1})
                        // let msgType = rosterAttendee1.muted ? "unmute" : "mute"
                        // chime?.sendMessage(msgType, {
                        //   attendeeId: id
                        // });
                      }
                    }
                    removeAttendee={
                      (id: string)=> {
    
                        let msg1 = {
                            name: chime?.roster[currentUser].name,
                            payload: {
                              attendeeId: id, message: "remove-attendee"
                            },
                            timestampMs: Date.now(),
                            type: "remove-attendee"
                        }
                        chime?.pubnub?.publish({ channel : publicChannel, message: msg1})
    
                      }
                    }
                    volume={chime?.roster[attendeeIdFullScreen]?.volume}
                    setActiveAttendeeForChat={(id: string) => { setActiveAttendeeForChatParent(id) }}
                  />
                </div>
              );
            })}

            {attendeeIds.map((key: string, index: number) => {
              let rosterAttendee: RosterAttendeeType = {};

              if (roster) {
                rosterAttendee = roster[key];
              }
              let rosterLength = attendeeIds.length;

              if(!videoAttendees.has(key) || key != currentUser || chime?.roster[key].name != '' || chime?.roster[key].name != meetingRecorderName){
                return (
                    <div
                      style={tileView &&  videoAttendees.has(key) !== true? { display: !videoAttendees.has(key)  ? 'block' : 'none', width: reorganize().width, height: reorganize().height, margin: '0.50%'} : { display: !videoAttendees.has(key)  ? 'block' : 'none',margin: videoAttendees.has(key)  ? '0' : '10px 0 10px 0'}}
                      key={key}
                      className={cx(
                        { 
                          rosterMinimize: !minmax,
                          participants: videoAttendees.has(key) === false && attendeeIdFullScreen != key && !tileView,
                          participantsActiveSpeaker: videoAttendees.has(key) === false && attendeeIdFullScreen == key && isRightBarEnabled == false && !tileView,
                          participantsActiveSpeakerWithRightsideEnable: videoAttendees.has(key) === false && attendeeIdFullScreen == key && isRightBarEnabled == true && !tileView,
                          isTileView:tileView,
                          activeSpeaker:key == activeSpeaker && !chime?.roster[key]?.muted && videoAttendees.has(key) === false,
                          }
                      )}>
                      {
                        <RosterLayout1
                          key={index}
                          tileView={tileView}
                          activeSpeaker={key == activeSpeaker}
                          attendeeIdFullScreen={attendeeIdFullScreen}
                          activeSpeakerMuted={ chime?.roster[key]?.muted? true : false  }
                          isVideoEnable={videoAttendees.has(key)}
                          size={getSize()}
                          rosterAttendee={rosterAttendee}
                          viewMode={viewMode}
                          boundAttendeeId={key}
                          isRightBarEnabled={isRightBarEnabled}
                          exitZoomRoster={
                            () => { setGlobalVariables('attendeeIdFullScreen', '') }
                          }
                          muteunmuteAttendee={
                            (id: string)=> {
                              
                              let msg1 = {
                                    name: chime?.roster[currentUser].name,
                                    payload: {
                                      attendeeId: id, focus: !rosterAttendee.muted, message: "mute-attendee"
                                    },
                                    timestampMs: Date.now(),
                                    type: "mute-attendee"
                                }
                                chime?.pubnub?.publish({ channel : publicChannel, message: msg1})
                              // let msgType = rosterAttendee.muted ? "unmute" : "mute"
                              // chime?.sendMessage(msgType, {
                              //   attendeeId: id
                              // });
                            }
                          }
                          removeAttendee={
                            (id: string)=> {
        
                              let msg1 = {
                                    name: chime?.roster[currentUser].name,
                                    payload: {
                                      attendeeId: id, message: "remove-attendee"
                                    },
                                    timestampMs: Date.now(),
                                    type: "remove-attendee"
                                }
                                chime?.pubnub?.publish({ channel : publicChannel, message: msg1})
        
                            }
                          }
                          volume={ chime?.roster[attendeeIdFullScreen]?.volume}
                        />
                      }
                    </div>
              );
              }else{
                  return null
              }

            })}
          </div>
        </div>
      </div>
    </>
  );
}
