import React from 'react';
import classNames from 'classnames/bind';
import GetEnv from '../GetEnv';
import styles from './NylasLogin.css';
import constants from "../constants/common.json"
import { useHistory } from 'react-router-dom';
import routes from '../constants/routes.json';
import commonob from '../constants/common.json'
import pkgJSON from '../package.json'

const cx = classNames.bind(styles);

const NylasLogin = () => {
  const history = useHistory();
  let nylasAPIbaseURL = 'https://scheduler.roomzz.net/prod';

  if (process.env.NODE_ENV === 'development') {
    nylasAPIbaseURL = 'https://scheduler.roomzz.net/dev';
  }

  return (
    <div className={cx("NylasLogin")}>
    <div className={cx("NylasLoginContainer")}>
    <img className={cx('logo')} src={`/${commonob.app_name}_logo.png`} />
          <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
      <div className={cx("instructionMainDiv")}>
        <p>Easily schedule all your online meetings.</p>
        <p>Customers will be able to schedule appointments with you online.</p>
        <p>Meeting link in the calendar invite</p>
        <p>Embedded booking widget in your website</p>
        <p>Add booking link to your email signature</p>
        {/* <a style={{textDecoration:'none'}} target='_blank' href={`${constants.nylas.nylasAPIbaseURL}/authentication?email=${localStorage.getItem('userName')}&return_page=http://localhost:3000/?returnFromWebLogin=true`}> */}
        <button className={cx("NylasLoginButton")} onClick={() => { 
            // GetEnv().nylasAPI.Login(); 
            window.location.href = `${nylasAPIbaseURL}/sso/auth?email=${localStorage.getItem('userName')}&return_page=${window.location.protocol + '//' + window.location.host}/?returnFromWebLogin=true`;
            }}>
          Sync Your Data With Google or Microsoft
        </button>
        {/* </a> */}
        <div className={cx('login-bottom-link')}>
        <div>
          <div className={cx('loginLink', 'loginLinkBlackColor', 'leftSide')} onClick={() => {
            history.push(routes.JOIN)
          }}>
            Back
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default NylasLogin;
