
/* eslint-disable */

import classNames from 'classnames/bind';
import React, { useContext, useRef, useEffect } from 'react';
import Dropdown from 'react-dropdown';

import getChimeContext from '../context/getChimeContext';
import useDevices from '../hooks/useDevices';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import DeviceType from '../types/DeviceType';

import styles from './DeviceSetting.css';

const cx = classNames.bind(styles);

export default function DeviceSetting() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const deviceSwitcherState = useDevices();
  const video = useRef<HTMLVideoElement>(null);

  // eslint-disable-next-line 
  const changed = (selected: DeviceType, existing: DeviceType | undefined | null): boolean =>
    (!existing || selected.value !== existing.value);

  useEffect(() => {
 
    if (deviceSwitcherState.currentAudioInputDevice){
      localStorage.setItem("audioInputDevice",`${deviceSwitcherState.currentAudioInputDevice}`)
    }
    if (deviceSwitcherState.currentAudioOutputDevice){
      localStorage.setItem("audioOutputDevice",`${deviceSwitcherState.currentAudioOutputDevice}`)
    }
    if (deviceSwitcherState.videoInputDevices){
      localStorage.setItem("videoInputDevice",`${deviceSwitcherState.videoInputDevices}`)
    }
    if (
      video.current &&
      chime?.deviceController &&
      chime?.currentVideoInputDevice
    ){
      // If this finishes too late, it will happen after our cleanup, and
      // we will have a spurious video indicator. The SDK should return a promise.
      chime.deviceController?.startVideoPreviewForVideoInput(video.current!);
    }
  }, [deviceSwitcherState, video]);

  useEffect(() => {
    return () => {
      if (!chime) {
        return;
      }

      const videoElement = video.current;
      const deviceController = chime.deviceController;

      if (videoElement && deviceController) {
        console.log('Stopping video preview.');
        deviceController.stopVideoPreviewForVideoInput(videoElement);
      }
    };
  }, []);
  return (
    <>
    <div className={cx('deviceSettingContainer')}>
      <div className={cx('deviceList')}>
        <div className={cx('form_label')}>
          Audio input source
        </div>
        <Dropdown
          className={cx('dropdown')}
          controlClassName={cx('control')}
          placeholderClassName={cx('placeholder')}
          menuClassName={cx('menu')}
          arrowClassName={cx('arrow')}
          value={deviceSwitcherState.currentAudioInputDevice || undefined}
          options={
            deviceSwitcherState.audioInputDevices || ([] as DeviceType[])
          }
          disabled={
            !deviceSwitcherState.audioInputDevices ||
            !deviceSwitcherState.audioInputDevices.length
          }
          onChange={async (selectedDevice: DeviceType) => {
            // eslint-disable-next-line 
            if (changed(selectedDevice, deviceSwitcherState.currentAudioInputDevice)) {
              localStorage.setItem("audioInputDevice",`${selectedDevice}`)
              await chime?.chooseAudioInputDevice(selectedDevice);
            }
          }}
          placeholder={
            deviceSwitcherState.currentAudioInputDevice
              ? 'No microphone'
              : ''
          }
        />

        <div className={cx('form_label')}>
          Audio output source
        </div>
        <Dropdown
          className={cx('dropdown')}
          controlClassName={cx('control')}
          placeholderClassName={cx('placeholder')}
          menuClassName={cx('menu')}
          arrowClassName={cx('arrow')}
          value={deviceSwitcherState.currentAudioOutputDevice || undefined}
          options={
            deviceSwitcherState.audioOutputDevices || ([] as DeviceType[])
          }
          disabled={
            !deviceSwitcherState.audioOutputDevices ||
            !deviceSwitcherState.audioOutputDevices.length
          }
          onChange={async (selectedDevice: DeviceType) => {

            if (changed(selectedDevice, deviceSwitcherState.currentAudioOutputDevice)) {
              localStorage.setItem("audioOutputDevice",`${selectedDevice}`)
              await chime?.chooseAudioOutputDevice(selectedDevice);
            }
          }}
          placeholder={
            deviceSwitcherState.currentAudioOutputDevice
              ? 'No speaker'
              : ''
          }
        />
        <div className={cx('form_label')}>
          Video input source
        </div>
        <Dropdown
          className={cx('dropdown')}
          controlClassName={cx('control')}
          placeholderClassName={cx('placeholder')}
          menuClassName={cx('menu')}
          arrowClassName={cx('arrow')}
          value={deviceSwitcherState.currentVideoInputDevice || undefined}
          options={
            deviceSwitcherState.videoInputDevices || ([] as DeviceType[])
          }
          disabled={
            !deviceSwitcherState.videoInputDevices ||
            !deviceSwitcherState.videoInputDevices.length
          }
          onChange={async (selectedDevice: DeviceType) => {
            // eslint-disable-next-line 
            if (changed(selectedDevice, deviceSwitcherState.currentVideoInputDevice)) {
              localStorage.setItem("videoInputDevice",`${selectedDevice}`)
              await chime?.chooseVideoInputDevice(selectedDevice);
            }
          }}
          placeholder={
            deviceSwitcherState.currentVideoInputDevice
              ? 'No video device'
              : ''
          }
        />
      </div>
      <div className={cx("videoDiv")}>
      <video className={cx('videoPreview')} ref={video}></video>
      </div>
    </div>
    </>
  );
}
