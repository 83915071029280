export default {
  'Back.link': "Back",
  'Signup.title': "Sign Up",
  'Signup.email': "E-mail",
  'Signup.password': "Password",
  'Signup.cpassword': "Confirm Password",
  'Signup.registerbutton': "Sign Up",

  'UserLogin.title': "Sign In",
  'UserLogin.email': "E-mail",
  'UserLogin.password': "Password",
  'UserLogin.loginbutton': "Login",
  'UserLogin.forgotpassword': "Forgot Password?",
  'UserLogin.join': "Join Meeting/Class?",
  'UserLogin.signup': "Sign Up",
  'UserLogout.title': "Logout",
  'UserLogin.accnt' : "Don’t have an account? ",
  'UserLogin.signupadvise' : "SignUp for free.",
  'Login.title': `Tell me about you`,
  'Login.teacherTitle': `Teachers can`,
  'Login.teacherDescription1': `Create a Meeting/Class`,
  'Login.teacherDescription2': `Share audio, video, and screen`,
  'Login.teacherDescription3': `Send chat messages`,
  'Login.teacherDescription4': `Toggle focus:`,
  'Login.teacherToggleDescription1': `Focus mutes all students`,
  'Login.teacherToggleDescription2': `Focus turns off student chat`,
  'Login.teacherButton': `I'm a teacher`,

  'Login.studentTitle': `Students can`,
  'Login.studentDescription1': `Join a Meeting/Class`,
  'Login.studentDescription2': `Share video`,
  'Login.studentDescription3': `Raise hand`,
  'Login.studentDescription4': `When focus is off:`,
  'Login.studentToggleDescription1': `Unmute and share audio`,
  'Login.studentToggleDescription2': `Send chat messages`,
  'Login.studentButton': `I'm a student`,

  'CreateOrJoin.teacherTitle': `Create or join a Meeting/Class`,
  'CreateOrJoin.studentTitle': `Join a Meeting/Class`,
  'CreateOrJoin.titlePlaceholder': `Meeting/Class Id`,
  'CreateOrJoin.namePlaceholder': `Your name`,
  'CreateOrJoin.continueButton': `Continue`,
  'CreateOrJoin.submitButton' : 'Create',
  'CreateOrJoin.upDateButton' : 'Update',
  'CreateOrJoin.createMeeting' : 'Create New Meeting',
  'CreateOrJoin.upDateMeeting' : 'Update Meeting',
  'CreateOrJoin.createSuccessMessage' :  "Meeting created successfully!",
  'CreateOrJoin.upDateSuccessMessage' :  "Meeting updated successfully!",
  'CreateOrJoin.errorMessage' : "Oops,something went wrong!.Please try after sometime.",
  'CreateOrJoin.notTeacherLink': `Not a teacher? Click here.`,
  'CreateOrJoin.notStudentLink': `Not a student? Click here.`,
  'CreateOrJoin.classRoomDoesNotExist': `Meeting/Class does not exist`,
  'CreateOrJoin.serverError': `Server error`,
  'CreateOrJoin.meetingtitlePlaceholder': `Meeting Title`,
  'CreateOrJoin.descriptionPlaceholder': `Description (Optional)`,
  'CreateOrJoin.deleteMeetingConfirmation': `Are you sure want to delete?`,

  'Classroom.classroom': `Classroom`,

  'RemoteVideoGroup.noVideo': `No one is sharing video`,

  'DeviceSwitcher.noAudioInputPlaceholder': `No microphone`,
  'DeviceSwitcher.noAudioOutputPlaceholder': `No speaker`,
  'DeviceSwitcher.noVideoInputPlaceholder': `No video device`,

  'Controls.turnOffFocusTooltip': `Turn off focus`,
  'Controls.turnOnFocusTooltip': `Turn on focus`,
  'Controls.unmuteTooltip': `Unmute`,
  'Controls.muteTooltip': `Mute`,
  'Controls.endMeetingTooltip': `End Meeting`,
  'Controls.turnOnVideoTooltip': `Turn on video`,
  'Controls.turnOffVideoTooltip': `Turn off video`,
  'Controls.shareScreenTooltip': `Share screen`,
  'Controls.endClassroomTooltip': `End Meeting/Class`,
  'Controls.leaveClassroomTooltip': `Leave Meeting/Class`,
  'Controls.micMutedInScreenViewMode': `Mic muted`,
  'Controls.focusOnMessage': `Host has put you on mute`,
  'Controls.focusOffMessage': `Host has put you on unmute`,
  'Controls.Settings': `Settings`,
  'Controls.AttendeeList': `User List`,
  'Controls.AttendeeInvite': `Invite User`,
  'Controls.Documents': `Upload Documents`,
  'Controls.VideoOnDemand': `Video On Demand`,
  'Controls.LiveStreaming': `Live Streaming`,
  'Controls.SignOut': `Sign Out`,
  'Controls.FullScreen': `Full Screen`,
  'Controls.CloseFullScreen': `Exit Full Screen`,
  'Controls.turnOffRecording': `Stop Recording`,
  'Controls.turnOnRecording': `Start Recording`,
  'Controls.chatTooltip': `Chat`,
  'Controls.editMeeting': `Edit`,
  'Controls.deleteMeeting': `Cancel`,
  'Controls.joinMeeting': `Join`,

  'ScreenPicker.title': `Share your screen`,
  'ScreenPicker.applicationWindowTab': `Application window`,
  'ScreenPicker.yourEntireScreenTab': `Your entire screen`,
  'ScreenPicker.noScreen': `No screen`,
  'ScreenPicker.cancel': `Cancel`,
  'ScreenPicker.share': `Share`,

  'ScreenShareHeader.stopSharing': `Stop sharing`,
  'ScreenShareHeader.online': `{number} online`,

  'ChatInput.inputPlaceholder': `Message...`,
  'ChatInput.raiseHandAriaLabel': `Raise hand`,

  'Roster.raiseHandAriaLabel': `Raised hand by {name}`,

  'RosterLayout.FullScreen' : 'Full screen',
  'RosterLayout.CloseFullScreen' : 'Close Full screen',
  'RosterLayout.ZoomIn' : 'Zoom In',
  'RosterLayout.ZoomOut' : 'Zoom Out',
  'RosterLayout.ExitZoom' : 'Exit zoom',

  'RemoteVideo.raiseHandAriaLabel': `Raised hand`,

  'TileView.gridview': `Grid View`,
  'TileView.listview': `List View`,
  'TileView.maximize': `Maximize`,
  'TileView.minimize': `Minimize`,
};
