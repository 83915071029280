/* eslint-disable */

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../constants/routes.json';
import moment from 'moment';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import MessageType from '../types/MessageType';
import ChatInput from './ChatInput';
import styles from './Chat.css';
import commonob from '../constants/common.json';
import getRosterContext from '../context/getRosterContext';
import getGlobalVarContext from '../context/getGlobalVarContext';
import localStorageKeys from "../constants/localStorageKeys.json"
import ClassMode from '../enums/ClassMode';

const cx = classNames.bind(styles);

type Props = {
  messageCounter: (obj: any) => void;
  hideMessgePopup: () => void;
  title: string;
  tab: number;
  // newActiveAttendee: string;
  activeAttendeeGo: (key: any) => void;
  documentMsgAdd: (msg: any) => void;
};

var attendeeTyp = []
var attendeeTypPvt = []

export default function ChatPvt(props: Props) {
  const { messageCounter, hideMessgePopup, title, activeAttendeeGo, documentMsgAdd, tab } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [pvtMessages, setPvtMessages] = useState<MessageType[]>([]);
  // const bottomElement = useRef(null);
  const bottomElementPvt = useRef(null);
  const attendeeId = chime?.configuration?.credentials?.attendeeId;
  const history = useHistory();
  const publicChannel = `${commonob.app_name}-chat-${title}`;
  const realTimeMessages: MessageType[] = [];
  const realTimePvtMessages: MessageType[] = [];

  const [activeAttendee, setActiveAttendee] = useState("");
  const [activeAttendeeChannel, setActiveAttendeeChannel] = useState("");
  const roster = useContext(getRosterContext());
  const [attendeeTyping, setAttendeeTyping] = useState([]);
  const [attendeeTypingPvt, setAttendeeTypingPvt] = useState([]);
  const { setGlobalVar } = useContext(getGlobalVarContext());

  // if(newActiveAttendee !== "" && newActiveAttendee !== 'all' && newActiveAttendee !== activeAttendee ){
  //       setActiveAttendee(newActiveAttendee);
  //       localStorage.setItem('activeAttendee',newActiveAttendee);
  //     }

  let attendeeIds: any;
  if (chime?.meetingSession && roster) {
    attendeeIds = Object.keys(roster);

    if (activeAttendee !== "" && activeAttendee !== "all" && !attendeeIds.includes(activeAttendee)) {
      setActiveAttendee("");
      activeAttendeeGo("");
      localStorage.setItem('activeAttendee', "");
    }

    attendeeIds.forEach(function (item: string, i: number) {
      if (item === attendeeId) {
        attendeeIds.splice(i, 1);
        // attendeeIds.unshift(item);
      }
    });
  }


  useEffect(() => {
    const joinRoomMessaging = async () => {
      await chime?.joinRoomMessaging();
    };
    joinRoomMessaging();
  }, []);

  useEffect(() => {

    if (tab === 1) {

      setTimeout(() => {
        var pubMsg = document.getElementById('pubMsg');
        var pvtMsg = document.getElementById('pvtMsg');
        pubMsg.scrollTop = pubMsg.scrollHeight;
        pvtMsg.scrollTop = pvtMsg.scrollHeight;
      }, 10);

    }

  }, [tab])


  useEffect(() => {

    chime?.pubnub?.addListener({
      status: (statusEvent: any) => {
        if (statusEvent.category === "PNConnectedCategory") {
          // console.log("Success PNConnectedCategory !!")
        }
      },
      message: (msg: any) => {
        msg.message['channel'] = msg.channel
        chatMessages(msg.message)

        if (msg.message.type == 'pvt-chat-message' && msg.message.payload.attendeeId !== localStorage.getItem('activeAttendee')) {

          chime?.updateChatMessageCounter(msg.message.payload.attendeeId, (chime?.roster?.[msg.message.payload.attendeeId].msgcount + 1))
        } else if (msg.message.type == 'pvt-chat-message' && msg.message.payload.attendeeId == localStorage.getItem('activeAttendee')) {
          chime?.updateChatMessageCounter(msg.message.payload.attendeeId, 0)
        }

        if (msg.message.type === 'chat-upload-message') {
          documentMsgAdd(msg.message)
        }

      },
      signal: async (signal: any) => {

        // show typing indications of others attendee not for me
        if (signal.publisher !== attendeeId) {

          // remove attendee if not exit or leave
          if (chime?.roster?.[signal.publisher] == undefined) {

            if (attendeeTyp.includes(signal.publisher) == true || attendeeTypPvt.includes(signal.publisher) == true) {

              attendeeTyp.splice(signal.publisher, 1)
              setAttendeeTyping(attendeeTyp);

              attendeeTypPvt.splice(signal.publisher, 1)
              setAttendeeTypingPvt(attendeeTypPvt)
            }
          }
          // code for group chat manage 
          else if (signal.message.to == "" || signal.message.to == "all") {

            if (signal.message.isTyping != chime?.roster?.[signal.publisher]?.isTyping) {

              if (attendeeTyp.includes(signal.publisher) == true && signal.message.isTyping == false) {

                attendeeTyp.splice(signal.publisher, 1)
                setAttendeeTyping(attendeeTyp);
                chime?.updateIstyping(signal.publisher, signal.message.isTyping)
              } else {

                if (attendeeTyp.includes(signal.publisher) == false) {
                  attendeeTyp.push(signal.publisher)
                  setAttendeeTyping(attendeeTyp)
                  chime?.updateIstyping(signal.publisher, signal.message.isTyping)
                }
              }
            }

          } else {
            // code for pvt. chat manage 

            // getting signal only to assign me
            if (signal.message.to === attendeeId) {

              if (signal.message.isTyping != chime?.roster?.[signal.publisher].isTypingPvt) {


                if (attendeeTypPvt.includes(signal.publisher) == true && signal.message.isTyping == false) {

                  attendeeTypPvt.splice(signal.publisher, 1)
                  setAttendeeTypingPvt(attendeeTypPvt);
                  chime?.updateIstypingPvt(signal.publisher, signal.message.isTyping)
                } else {

                  if (attendeeTypPvt.includes(signal.publisher) == false) {
                    attendeeTypPvt.push(signal.publisher)
                    setAttendeeTypingPvt(attendeeTypPvt)
                    chime?.updateIstypingPvt(signal.publisher, signal.message.isTyping)

                  }
                }
              }
            }
          }



        }
      },
      presence: (presenceEvent: any) => {
        // This is where you handle presence. Not important for now :)
      }
    });

    fetchHistory(publicChannel);


  }, []);


  function fetchHistory(channel) {

    chime?.pubnub?.fetchMessages(
      {
        channels: [publicChannel],
        count: 100
      },
      (status, response) => {
        // handle response
        response?.channels?.[publicChannel].forEach(data => {
          chatMessages(data.message)
        })
      }
    );

  }



  async function chatMessages(message: any) {

    let tm: any = 0;

    if (message.name &&
      message.type === 'remove-attendee' &&
      message.payload.attendeeId !== "" &&
      message.payload.attendeeId === attendeeId
    ) {

      let dd = 5;
      let msgObj = {
        type: 'msg',
        message: `You are logged out in ${dd} by host.. !!`,
        name: message.name
      }
      messageCounter(msgObj);
      let ci: any;
      await new Promise((resolve: any) => {
        ci = setInterval(() => {
          dd = dd - 1;
          if (dd <= 0) {
            clearInterval(ci);
            resolve;
            chime?.leaveRoom(false,[],0)
            localStorage.setItem('meeting_url', null)
            history.push(routes.HOME);
          }
          let msgObj = {
            type: 'msg',
            message: `You are logged out in ${dd} by host.. !!`,
            name: message.name
          }
          messageCounter(msgObj);
        }, 1000)
      })

    } else if (
      message.name &&
      (message.type === 'chat-message' || message.type === 'pvt-chat-message' || message.type === 'raise-hand' || message.type === 'chat-upload-message' || message.type === 'auto-focus')
    ) {
      // render message counter when attendee id is not me
      if (attendeeId != message.payload.attendeeId) {

        // clear timeout if no any more message  
        if (tm !== 0)
          clearTimeout(tm);

        // set timer for five second 
        tm = setTimeout(() => {
          // method call to parent for hide popups
          hideMessgePopup()
        }, 5000)

        // separate chat msg and file upload
        if (message.type === 'chat-upload-message') {
          // new message object 
          let msgObj = {
            type: 'file',
            ext: message.payload.message.split('.')[3],
            message: message.payload.original_filename,
            name: message.name
          }
          // method call to parent for message counter display
          messageCounter(msgObj);
        } else if (message.type === 'chat-message' || message.type === 'pvt-chat-message') {
          let msgObj = {
            type: 'msg',
            message: message.payload.message,
            name: message.name
          }

          messageCounter(msgObj);
        } else if (message.type === 'auto-focus') {
          let msgObj = {
            type: 'autoFocus',
            message: message.payload.message,
          }
          messageCounter(msgObj);
        }
      }


      if (message.type === 'chat-message') {
        realTimeMessages.push(message);
        setMessages(realTimeMessages.slice() as MessageType[]);
      } else if (message.type === 'pvt-chat-message') {
        realTimePvtMessages.push(message);
        setPvtMessages(realTimePvtMessages.slice() as MessageType[]);
      }

    } else if (message.type == 'mute-attendee') {
      if (attendeeId === message.payload.attendeeId) {
        chime?.audioVideo?.realtimeSetCanUnmuteLocalAudio(!message.payload.focus);
        if (message.payload.focus === true) {
          chime?.audioVideo?.realtimeMuteLocalAudio();
        } else {
          chime?.audioVideo?.realtimeUnmuteLocalAudio();
        }
      }

    } else if (message.payload.message == 'video-enable' && message.payload) {
      if (attendeeId === message.payload.attendeeId) {
        await chime?.chooseVideoInputDevice(chime?.currentVideoInputDevice);
        if (message.payload.focus === true) {
          chime?.audioVideo?.startLocalVideoTile();
          setGlobalVar((oldData) => ({ ...oldData, localVideo: true }))

        } else {
          chime?.audioVideo?.stopLocalVideoTile();
          setGlobalVar((oldData) => ({ ...oldData, localVideo: false }))
        }
      }
    }else if (message.type === 'record' && message.payload && localStorage.getItem(localStorageKeys.CLASS_MODE) != ClassMode.Teacher) {
      setGlobalVar( (oldData) => ( {...oldData, isRecordStart: message.payload.focus }) )          
    }

  }

  useEffect(() => {
    setTimeout(() => {
      var pubMsg = document.getElementById('pubMsg');
      var pvtMsg = document.getElementById('pvtMsg');
      pubMsg.scrollTop = pubMsg.scrollHeight;
      pvtMsg.scrollTop = pvtMsg.scrollHeight;
    }, 10);

  }, [messages, pvtMessages]);


  return (
    <div className={cx('chat')}>

      <div className={cx('userlistMain')}>
        <div className={cx('userlist')}>

          <div onClick={async () => {
            setActiveAttendee("all");
            activeAttendeeGo("all");
          }}
            className={cx('user', { active: activeAttendee == "" || activeAttendee == 'all' })}>All
          </div>

          {attendeeTyping.length > 0 && (
            <img className={cx('typinggroup')} src="typing-white.gif" />
          )}

          {attendeeIds.map((key: string, index: number) => {

            var initials = chime?.roster[key].name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('');
            return (
              <div key={index} onClick={() => {

                setActiveAttendee(key);
                activeAttendeeGo(key);

                if (!chime?.roster?.[key]?.history) {
                  fetchHistory(chime?.setChannelName(key))
                  chime?.setHistoryTrue(key);
                }

                chime?.updateChatMessageCounter(key, 0)

                localStorage.setItem('activeAttendee', key);

              }}
                className={cx('user', { active: key == activeAttendee })}>{initials}


                {chime?.roster?.[key].isTypingPvt &&
                  activeAttendee !== key && (
                    <img className={'typing'} src="typing-white.gif" />
                  )}

                <div
                  style={{ display: chime?.roster?.[key].msgcount > 0 ? 'block' : 'none' }}
                  className={cx('countuser')}> {chime?.roster?.[key].msgcount} </div>
              </div>
            )

          })
          }


        </div>

        <div style={{ display: (activeAttendee !== '' && activeAttendee !== 'all') ? 'block' : 'none' }}
          className={cx('messages')} id='pvtMsg'>
          {pvtMessages.map((message, index) => {
            let messageString = message.payload.message;
            let user_name = message.name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('').substring(0, 2);
            {
              return (
                <div key={index}
                  style={{
                    display: message.channel === chime?.setChannelName(activeAttendee) ? 'block' : 'none'
                  }}
                >
                  {attendeeId === message.payload.attendeeId ?
                    <div className={cx('messageWrapperSenderDiv')}>
                      {
                        <div
                          key={message.timestampMs}
                          // style={{ marginRight: '-11px' }}
                          className={cx('messageWrapperSender', {
                            raiseHand: message.type === 'raise-hand'
                          })}
                        >
                          <>
                            <div className={cx('msgRight', {

                              blueColor: message.payload.attendeeId == attendeeId,
                              orangeColor: message.payload.attendeeId != attendeeId

                            })}>
                              <span className={cx('chatName')}>
                                {
                                  user_name
                                }
                              </span>
                            </div>
                            <div className={cx('msgLeft')}>
                              <div className={cx('senderName')}>
                                {message.name}
                              </div>
                              {(messageString.includes('https://') || messageString.includes('http://')) ?
                                <div style={{ textDecoration:"underline", cursor: 'pointer' }} onClick={() => {
                                  window.open(messageString)
                                }} className={cx('message')}>{messageString}</div>
                                : <div className={cx('message')}>{messageString}</div>}
                            </div>
                            <span className={cx('timecolor')} >
                              {moment(message.timestampMs).format('h:mm A')}
                            </span>
                          </>
                        </div>
                      }
                    </div>
                    :
                    <div className={cx('messageWrapperReceiverDiv')}>
                      {

                        <div
                          key={message.timestampMs}
                          style={{ marginLeft: '-15px' }}
                          className={cx('messageWrapperReceiver', {
                            raiseHand: message.type === 'raise-hand'
                          })}
                        >
                          <>
                            <div className={cx('msgRight', {

                              blueColor: message.payload.attendeeId == attendeeId,
                              orangeColor: message.payload.attendeeId != attendeeId

                            })}>
                              <span className={cx('chatName')}>
                                {user_name}
                              </span>
                            </div>
                            <div className={cx('msgLeft')}>
                              <div className={cx('senderName')}>
                                {message.name}

                              </div>
                              {(messageString.includes('https://') || messageString.includes('http://')) ?
                                <div style={{ textDecoration:"underline", cursor: 'pointer' }} onClick={() => {
                                  window.open(messageString)
                                }} className={cx('message')}>{messageString}</div>
                                : <div className={cx('message')}>{messageString}</div>}
                            </div>
                            <span className={cx('timecolor')} >
                              {moment(message.timestampMs).format('h:mm A')}
                            </span>
                          </>
                        </div>
                      }
                    </div>
                  }
                </div>
              );
            }
          })}


          <div
            style={{ bottom: '-45px' }}
            className={'istyping'}>
            {activeAttendee !== "" &&
              activeAttendee !== "all" &&
              attendeeTypingPvt.includes(activeAttendee) == true && (
                <>
                  <span>{chime?.roster?.[activeAttendee]?.name} </span>
                  <img className={'typing'} src="typing.gif" />
                </>
              )
            }

          </div>
          {/* <div ref={bottomElementPvt}></div> */}

        </div>


        <div style={{ display: (activeAttendee == 'all' || activeAttendee == '') ? 'block' : 'none' }}
          className={cx('messages')} id='pubMsg'>
          {messages.map((message, index) => {
            let messageString = message.payload.message;

            //  else if (message.type === 'raise-hand') {
            //   messageString = `✋`;
            // }

            let user_name = message.name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)?.join('').substring(0, 2);
            {
              return (
                <div key={index}>
                  {attendeeId === message.payload.attendeeId ?
                    <div className={cx('messageWrapperSender')}>
                      {(message.type === 'chat-upload-message') ? null :
                        <div
                          key={index}
                          // style={{ marginRight: '-11px' }}
                          className={cx('messageWrapperSender', {
                            raiseHand: message.type === 'raise-hand'
                          })}
                        >
                          <>
                            <div className={cx('msgRight', {

                              blueColor: message.payload.attendeeId == attendeeId,
                              orangeColor: message.payload.attendeeId != attendeeId

                            })}>
                              <span className={cx('chatName')}>
                                {
                                  user_name
                                }
                              </span>
                            </div>
                            <div className={cx('msgLeft')}>
                              <div className={cx('senderName')}>
                                {message.name}
                              </div>
                              {(messageString.includes('https://') || messageString.includes('http://')) ?
                                <div style={{ textDecoration:"underline", cursor: 'pointer' }} onClick={() => {
                                  window.open(messageString)
                                }} className={cx('message')}>{messageString}</div>
                                : <div className={cx('message')}>{messageString}</div>}
                            </div>
                            <span className={cx('timecolor')} >
                              {moment(message.timestampMs).format('h:mm A')}
                            </span>
                          </>
                        </div>
                      }
                    </div>
                    :
                    <div className={cx('messageWrapperReceiverDiv')}>
                      {(message.type === 'chat-upload-message') ? null :

                        <div
                          key={message.timestampMs}
                          style={{ marginLeft: '-15px' }}
                          className={cx('messageWrapperReceiver', {
                            raiseHand: message.type === 'raise-hand'
                          })}
                        >
                          <>
                            <div className={cx('msgRight', {

                              blueColor: message.payload.attendeeId == attendeeId,
                              orangeColor: message.payload.attendeeId != attendeeId

                            })}>
                              <span className={cx('chatName')}>
                                {user_name}
                              </span>
                            </div>
                            <div className={cx('msgLeft')}>
                              <div className={cx('senderName')}>
                                {message.name}
                              </div>
                              {(messageString.includes('https://') || messageString.includes('http://')) ?
                                <div style={{ textDecoration:"underline", cursor: 'pointer'}} onClick={() => {
                                  window.open(messageString)
                                }} className={cx('message')}>{messageString}</div>
                                : <div className={cx('message')}>{messageString}</div>}
                            </div>
                            <span className={cx('timecolor')} >
                              {moment(message.timestampMs).format('h:mm A')}
                            </span>
                          </>
                        </div>
                      }
                    </div>
                  }
                </div>
              );
            }

          })}


          <div className={'istyping'}>
            {attendeeTyping.map((id, index) => {
              return (
                <span key={index} > {chime?.roster?.[id]?.name}
                  {attendeeTyping.length != index + 1 && (<span>,</span>)}
                </span>
              )
            })
            }

            {attendeeTyping.length > 0 && (
              <img className={'typing'} src="typing.gif" />
            )}
          </div>
          {/* <div ref={bottomElement}></div> */}
        </div>



      </div>
      <div className={cx('chatInput')}>
        <ChatInput
          publicChannel={publicChannel}
          activeAttendee={activeAttendee}
        />
      </div>
    </div>
  );
}
