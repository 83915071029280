/* eslint-disable  */ 

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import ClassMode from '../enums/ClassMode';
import useFocusMode from '../hooks/useFocusMode';
import styles from './ChatInput.css';
import localStorageKeys from "../constants/localStorageKeys.json"

const cx = classNames.bind(styles);

let timeoutId: number;


type Props = {
  publicChannel: string;
  activeAttendee: string;
};
var msgTimeout;

export default function ChatInput(props: Props) {

  const { publicChannel, activeAttendee } = props;

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [inputText, setInputText] = useState('');
  const [raised, setRaised] = useState(false);
  const focusMode = useFocusMode();
  const intl = useIntl();  
  var msgtype = (activeAttendee === "" || activeAttendee === "all") ? 'chat-message' : 'pvt-chat-message';
  const attendeeId = chime?.configuration?.credentials?.attendeeId;
  

  let hideTypingIndicator = (channel: string) => {
      sendSignal(false, channel)
  }

  // seinding signal typing message 
  const sendSignal = (type: boolean, channel: string) => {
      
      chime?.pubnub?.signal({
          channel: channel,
          message: { 
              isTyping : type,
              to: activeAttendee
            }
      });
  }

  useEffect(() => {
    
   
    if (!attendeeId) {
      return;
    }

    chime?.sendMessage(raised ? 'raise-hand' : 'dismiss-hand', {
      attendeeId
    });

    if (raised) {
      timeoutId = window.setTimeout(() => {
        chime?.sendMessage('dismiss-hand', {
          attendeeId
        });
        setRaised(false);
      }, 10000);
    } else {
      clearTimeout(timeoutId);
    }
  }, [raised, chime?.configuration]);


  const sendMessage = () => {
      // get the channel id
      let channel = publicChannel;
      if (msgtype === "pvt-chat-message") {
        channel = chime?.setChannelName(activeAttendee);
      }
    const sendingMessage = inputText.trim();
                
    if (sendingMessage !== '' && attendeeId) {
        
        // chat message payload  
        let msg = {
          name : localStorage.getItem("currentAtendeeName"),
          payload: {
              attendeeId,
              message: sendingMessage,
              receivedBy: activeAttendee
          },
          timestampMs: Date.now(),
          type: msgtype,
          child: false
        } 
          
          // send singal
          sendSignal(false, channel)
          
          // publish chat message
          chime?.pubnub?.publish({ channel : channel, message: msg})                    
          // inpyt type blank
          setInputText('');
    }
  }

  return (
    <div className={cx('chatInputDiv')}>
    <form
      onSubmit={event => {
        event.preventDefault();
        sendMessage()
      }}
      className={cx('chatForm')}
    >
      {/* <span className={cx('attachIcon')}></span> */}
      <span className={cx('inputSpan')}>
        <input
          className={cx('input')}
          value={inputText}
          ref={inputElement => {
            if (inputElement) {
              inputElement.focus();
            }
          }}
          onChange={event => {
            setInputText(event.target.value);
          }}

          onKeyUp={event => {
            event.preventDefault();

            if (focusMode && localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Student) {
              return;
            }
            const attendeeId = chime?.configuration?.credentials?.attendeeId;
            
            // get the channel id
            let channel = publicChannel;
            if (msgtype === "pvt-chat-message") {
              channel = chime?.setChannelName(activeAttendee);
            }

            // set timeout for each signal request
            if(msgTimeout != null && msgTimeout != undefined)  clearTimeout(msgTimeout);
            msgTimeout = setTimeout( () => hideTypingIndicator(channel), 3000) // 10 seconds
            
            // send message while press enter
            if (event.keyCode === 13) {
                sendMessage()
            }else {

                // send signal while no tab pressed
                if(event.keyCode != 91){

                  let  inputHasText = inputText.length > 0;
                    // signal msg typing
                    sendSignal(inputHasText ? true : false, channel)
                }
            }
          }}
          placeholder={intl.formatMessage({ id: 'ChatInput.inputPlaceholder' })}
        />
         <button className={cx('sendMessageButtton')} type="submit">
            <img src={'/icons1/sendMsg.png'} />
          </button>
      </span>
{/* 
      {localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Student && (
        <button
          type="button"
          className={cx('raiseHandButton', {
            raised
          })}
          onClick={() => {
            setRaised(!raised);
          }}
        >
          <span
            role="img"
            aria-label={intl.formatMessage({
              id: 'ChatInput.raiseHandAriaLabel'
            })}
          >
            ✋
          </span>
        </button>
      )} */}
    </form>
  </div >
  );
};






