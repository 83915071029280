// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */ 

import classNames from 'classnames/bind';
import React from 'react';

import styles from './LoadingSpinner.css';

const cx = classNames.bind(styles);
type Props = {
  className?:any;
  varient?: any;
};
export default function LoadingSpinner(props: Props) {
  const {
    className,
    varient
  } = props
  return (
    <div className={cx('loadingSpinner')}>
      <div className={cx({
        spinner: className != "small",
        smallSpinner : className == "small"
      })}>
        {Array.from(Array(12).keys()).map(key => (
          <div key={key} className={cx({
            blueCircle:varient != "white",
            whiteCircle:varient == "white"
          },'circle', `circle${key + 1}`)} />
        ))}
      </div>
    </div>
  );
}
