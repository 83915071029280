// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable  */

import styles from './CreateOrJoin.css';
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import routes from '../constants/routes.json';
import getChimeContext from '../context/getChimeContext';
import getUIStateContext from '../context/getUIStateContext';
import ClassMode from '../enums/ClassMode';
import RegionType from '../types/RegionType';
import FormValidate from './FormValidate';
import commonob from '../constants/common.json'
import pkgJSON from '../package.json';
import UpcomingMeetings from "./UpcomingMeetings";
import localStorageKeys from '../constants/localStorageKeys.json';
import getGlobalVarContext from '../context/getGlobalVarContext';
import StandAloneChat from './StandAloneChat';
import { userAuthenticate, setLogoutTimer, isEmpty, isEmail, isPhone, checkValidToken } from '../common_function'
import ScheduleMeetingForm from "./ScheduleMeetingForm"
import GetEnv from '../GetEnv';
import { useFeatures } from '../providers/FeatureProvider';
import { useExternalChat } from '../providers/ExternalChatProvider';

const cx = classNames.bind(styles);
var uuid = require('uuid');

export default function CreateOrJoin() {

  const chime = useContext(getChimeContext()) as ChimeSdkWrapper;
  const [state] = useContext(getUIStateContext());
  const [, dispatch] = useContext(getUIStateContext());
  const login_flag = localStorage.getItem("isAuthenticated");

  const history = useHistory();
  const intl = useIntl();
  const location = useLocation<History | undefined>();

  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false)
  const [isJoin, setIsJoin] = useState(false)
  const [editId, setEditId] = useState('')
  const [signUpUrl, setSignUpUrl] = useState('')
  const [openScheduleMeetingForm, setOpenScheduleMeetingForm] = useState(false)
  const [sureToLogout, setSureToLogout] = useState(false);
  const [openScheduler, setOpenScheduler] = useState(false)
  const [dressingRoom, setDressingRoom] = useState(false);
  const [isHost, setIsHost] = useState(false)
  const [openChat,setOpenChat] = useState(false)
  const [isJoinFormEnabled, setIsJoinFormEnabled] = useState(false);
  const [isOPenWithDesktop, setIsOPenWithDesktop] = useState(false);
  const [title, setTitle] = useState(login_flag ? uuid.v1().substring(0, 8) : "");
  const [name, setName] = useState(localStorage.getItem('currentAtendeeName') !== null ? localStorage.getItem('currentAtendeeName') : "");
  const [region, setRegion] = useState("us-east-1");
  const { external_chat } = useFeatures();
  const { chatMessages, setChatMessages } = useExternalChat();
  const { messageCounter,totalMessageCounter } = chatMessages;

  var rand_id = uuid.v1().substring(0, 8);
  var logoutDate = (localStorage.getItem("dateTimeLogout") !== null || localStorage.getItem("dateTimeLogout") !== undefined) ? parseInt(localStorage.getItem("dateTimeLogout")) > new Date().getTime() ? 0 : 1 : 2;
  const removeItem = localStorageKeys.REMOVE_ITEMS;
  const logo_name = commonob.app_name
  const user_name = localStorage.getItem("userName")
  const user_pass = localStorage.getItem("userPass")
  var invitedUrl = localStorage.getItem("meeting_url")

  // cload recording code
  useEffect(() => {

    if (invitedUrl != null) {
      let isRecordingUrl = invitedUrl.split("&")[1] == "recording=1" ? true : false;
      if (invitedUrl.includes("/?m=") && !isRecordingUrl) {
        setIsOPenWithDesktop(true)
      }
      if (invitedUrl.includes("/?m=") && isRecordingUrl) {
        var url = new URL(invitedUrl);
        const urlParams = new URLSearchParams(url.search);
        const meetingParam = urlParams.get('m');
        // localStorage.setItem("currentAtendeeName", "«Meeting Recorder»");
        history.push(`/classroom?title=${encodeURIComponent(meetingParam)}&name=Unknown&region=us-east-1`);
      }
      if (invitedUrl.includes("/?returnFromWebLogin=") && !isRecordingUrl) {
        GetEnv().nylasAPI.GetAccountRecord().then((res:any) => {
          if (res) {
            showCreateMeetingForm()
          }
        });
      }
      if (invitedUrl.includes("&token=") && !isRecordingUrl) {
        // var url = new URL(invitedUrl);
        // const urlParams = new URLSearchParams(url.search);
        // const tokenParam = urlParams.get('token');
        let token = invitedUrl.split(`&token=`)[1];

        new Promise<void>(async (resolve) => {

        const data = await checkValidToken(token)
        if (data?.ok) {
          localStorage.setItem("isAuthenticated", (true).toString());
          localStorage.setItem("isLogin", (true).toString());
          localStorage.setItem(
            localStorageKeys.CLASS_MODE,
            ClassMode.Teacher
          );
      
          dispatch({
            type: 'SET_CLASS_MODE',
            payload: {
              classMode: ClassMode.Teacher
            }
          });
          history.push({
            pathname: routes.CREATE_OR_JOIN,
          });
          // set logout timer
          setLogoutTimer();
          setTimeout(()=>{
            showCreateMeetingForm()
          },1000)
          localStorage.setItem('meeting_url', null)
        } 
        // GetEnv().nylasAPI.GetAccountRecord().then((res:any) => {
        //   if (res) {
        //   }
        // });
        resolve()
      })
      }
    }

    if (location.state !== undefined) {
      setIsJoinFormEnabled(true)
    }

  }, []);


  useEffect(() => {
    console.log(localStorage.getItem("platform"))

    // check the credentials from localStorage
    if ((user_name !== null && user_pass !== null) && logoutDate === 1) {
      // request payload
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user_name, password: user_pass, app: commonob.app_name })
      };

      new Promise<void>(async (resolve) => {
        // user authenticate
        let data = await userAuthenticate(requestOptions);
        if (data?.success) {
          // set logout timer
          setLogoutTimer();
        } else {
          alert('You are sign out!\nPlease signin again!')
          // clear localStorage for logout
          localStorage.clear();
          history.push(routes.LOGIN);
          localStorage.setItem("lastActiveTime",`${new Date().getTime() * 10000}`)
        }
        // resolve promise
        resolve();
      })
    }

    // localStorage.clear();  
    removeItem.map((key) =>
      localStorage.removeItem(key)
    )
  }, [])

  useEffect(() => {
    if(login_flag){
      if (!localStorage.getItem('nylas_token')) {
        GetEnv().nylasAPI.GetAccountRecord().then((res:any) => {
          if (res) {
          }
        });
      } else {
        fetch('https://api.nylas.com/account', {
          headers: {
            Authorization: localStorage.getItem('nylas_token')
          }
        }).then((res:any) => { 
        }).catch((err) => {  // if the local token is not valid
          console.log("err",err)
          localStorage.removeItem('nylas_token');
        });
      }
    }
  },[login_flag])


  async function signUpURL() {
    const baseURL = commonob.getBaseUrl
    const response = await fetch(`${baseURL}user/setting?app=${commonob.app_name}`)
    const res = await response.json();
    return res;
  }

  useEffect(() => {
    if (signUpUrl == ""){
      new Promise(async (res) => {
        let newData = await signUpURL();
        setSignUpUrl(newData.signup_url + commonob.return_url)
        res({})
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (event: any) => {

    event.preventDefault();
    if (!login_flag)
      localStorage.clear();

    setError({})
    let error: any = {}
    if (FormValidate.isEmpty(title)) {
      error['title'] = `Invalid ${intl.formatMessage({ id: 'CreateOrJoin.titlePlaceholder' })}`
    }
    if (FormValidate.isEmpty(name)) {
      error['name'] = `Invalid ${intl.formatMessage({ id: 'CreateOrJoin.namePlaceholder' })}`
    }
    if (FormValidate.isEmpty(title, name)) {
      setError(error)
    } else {
      setOpenScheduler(false)
      setOpenScheduleMeetingForm(false)
      setIsJoinFormEnabled(false)
      // setDressingRoom(true)
      let url = `/dressingroom?title=${encodeURIComponent(title)}&name=${encodeURIComponent(name)}&region=${region}&isHost=${isHost}`;
      history.push(url);
    }
  }

  const showJoinForm = () => {
    setIsHost(false)

    localStorage.setItem(
      localStorageKeys.CLASS_MODE,
      ClassMode.Student
    );

    dispatch({
      type: 'SET_CLASS_MODE',
      payload: {
        classMode: ClassMode.Student
      }
    });
    if (isJoinFormEnabled == true) {
      setIsJoinFormEnabled(false)
      setIsOPenWithDesktop(false)
    }
    else {
      setIsJoinFormEnabled(true)
    }
  }
  // open upcoming meeting form
  const showMeetingForm = () => {
    if (openScheduler === true) {
      setOpenScheduler(false)
    }
    else {
      setOpenScheduler(true)
    }
  }

  // open schedule meeting form
  const showCreateMeetingForm = () => {
    if (openScheduleMeetingForm === true) {
      setOpenScheduleMeetingForm(false)
    }
    else {
      setOpenScheduleMeetingForm(true)
    }
  }

  // join meeting with desktop app
  const joinWithDesktop = () => {
    var url = new URL(invitedUrl);
    const urlParams = new URLSearchParams(url.search);
    const meetingParam = urlParams.get('m');
    document.location.href = commonob.app_name.replace(/ /g,"") + '://meeting=' + meetingParam
  }

  // join meeting with web app
  const joinWithWeb = () => {
    var url = new URL(invitedUrl);
    const urlParams = new URLSearchParams(url.search);
    const meetingParam = urlParams.get('m');
    setTitle(meetingParam)
    setIsJoinFormEnabled(true)
    setIsOPenWithDesktop(false)
  }

  // download desktop app with diffrent system
  const downLoadDesktopApp = () => {
    var link = "";
    if (navigator.platform.indexOf('Mac') > -1) {
      link = commonob.deskTopApp_downloadUrl + ".dmg";
    }
    if (navigator.platform.indexOf('Win') > -1) {
      link = commonob.deskTopApp_downloadUrl + ".exe";
    }
    if (navigator.platform.indexOf('Lin') > -1) {
      alert("Application cooming soon ...")
    }
    if (link != "") {
      var a = document.createElement("a");
      a.setAttribute("download", commonob.app_name);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  const setTotalMessageCount = () => {
    let ttlCnt = 0;
    messageCounter.map((item)=>{
      ttlCnt = ttlCnt + item.count
    })
    setChatMessages((oldState)=>({...oldState,totalMessageCounter:ttlCnt}))
  }

  return (
    <>
      {!sureToLogout && !isOPenWithDesktop &&
        <div className={cx("createOrJoinParentDiv")}>
        <div className={cx("createOrJoinDiv")}>
          {login_flag && !isJoinFormEnabled && !openScheduler && !openScheduleMeetingForm && !dressingRoom &&
            <div className={cx('createOrJoin')}>
              {
                <div className={cx('formWrapper')}>
                  <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
                  <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
                  
                  <button className={cx('button')} type="button" onClick={async () => { await setTitle(""); await showJoinForm(); await setIsHost(false)}}>
                    <img src={'/icons1/joinMeeting.png'} />
                    <div>
                      JOIN
                    </div>
                  </button>
                  <button
                    type="button"
                    className={cx('loginButton', 'loginButtonColor')}
                    onClick={async () => {
                      setIsHost(true)
                      await localStorage.setItem(
                        localStorageKeys.CLASS_MODE,
                        ClassMode.Teacher
                      );

                      await dispatch({
                        type: 'SET_CLASS_MODE',
                        payload: {
                          classMode: ClassMode.Teacher
                        }
                      });

                      await setIsJoinFormEnabled(true);
                      await setTitle(rand_id);
                    }}
                  >
                    <img src={'/icons1/startMeeting.png'} />
                    <div> 
                      START
                    </div>
                  </button>

                  {
                    login_flag && <button className={cx('button')} type="button" onClick={async () => await showMeetingForm()}>
                      <img src={'/icons1/upcomingMeeting.png'} />
                      <div>
                        UPCOMING
                      </div>
                    </button>
                  }
                  {
                    login_flag && (
                      // <a style={{textDecoration:'none'}}target='_blank' href={`${commonob.open_url}desktop&token=${localStorage.getItem("personalData")}`}>
                      <button
                        className={cx('loginButton', 'loginButtonColor')}
                        type="button"
                        onClick={async () => await showCreateMeetingForm()}
                      >
                        <img src={'/icons1/scheduleMeeting.png'} />
                        <div>
                          SCHEDULE
                        </div>
                      </button>
                      // </a>
                    )
                  }

                  <div className={cx('login-bottom-link')}>

                    <div className={cx('margintop')} >

                      <div className={cx('loginLink', 'loginLinkBlackColor', 'rightSide')} onClick={() => {
                        setSureToLogout(true);
                      }}
                      >
                        <FormattedMessage id="UserLogout.title" />
                      </div>

                    </div>
                  </div>
                </div>
              }
            </div>
          }

          {
            !login_flag && !isJoinFormEnabled && !isOPenWithDesktop && !dressingRoom &&
            <div className={cx('createOrJoin')}>
              <div className={cx('formWrapper')}>
                <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
                <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
                <button className={cx('button')} type="button" onClick={() => showJoinForm()}>
                  <img src={'/icons1/joinMeeting.png'} />
                  <div>
                    JOIN
                    </div>
                </button>
                <button
                  type="button"
                  className={cx('loginButton', 'loginButtonColor')}
                  onClick={() => {
                    history.push(routes.LOGIN);
                  }}
                >
                  <img src={'/icons1/scheduleMeeting.png'} />
                  <div>
                    SIGN IN
                      </div>
                </button>


                <div className={cx('login-bottom-link')}>
                  {/* <br /><br /> */}
                  <a target="_blank" style={{ color: "#9BABC5" }} className={cx('loginLink')} href={signUpUrl}>
                    <FormattedMessage id="UserLogin.accnt" />
                  </a><a target="_blank" className={cx('loginLink')} href={signUpUrl}>
                    <FormattedMessage id="UserLogin.signupadvise" />
                  </a>
                </div>
              </div>
            </div>
          }

          {openScheduler && login_flag && !isJoinFormEnabled && !isOPenWithDesktop &&
            <UpcomingMeetings
              joinMeetingFromList={
                (id,host) => {
                  if (host){
                    localStorage.setItem(
                      localStorageKeys.CLASS_MODE,
                      ClassMode.Teacher
                    );

                     dispatch({
                      type: 'SET_CLASS_MODE',
                      payload: {
                        classMode: ClassMode.Teacher
                      }
                  })
                }else{
                  localStorage.setItem(
                    localStorageKeys.CLASS_MODE,
                    ClassMode.Student
                  );

                   dispatch({
                    type: 'SET_CLASS_MODE',
                    payload: {
                      classMode: ClassMode.Student
                    }
                })     
                }  
                  setIsHost(host)
                  setTitle(id)
                  setIsJoin(true)
                  setIsJoinFormEnabled(true)
                }
              }
              editMeetingFromList={
                (id: any) => {
                  setIsEdit(true)
                  setEditId(id)
                  setOpenScheduleMeetingForm(true)
                  setOpenScheduler(false)
                }
              }
            />
          }

          {openScheduleMeetingForm && login_flag && !isJoinFormEnabled &&(
            <ScheduleMeetingForm
              isEdit={isEdit}
              meetingId={editId}
              goBack={() => {
                setIsEdit(false)
                setEditId('')
                setOpenScheduleMeetingForm(false)
                setOpenScheduler(true)
              }}
            />)
          }

          {isJoinFormEnabled &&
            <div className={cx('createOrJoin')}>
              <div className={cx('formWrapper')}>
                <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
                <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
       
                <h1 className={cx('title')}>
                  {(login_flag && localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher) ? (
                    <FormattedMessage id="CreateOrJoin.teacherTitle" />
                  ) : (
                    <FormattedMessage id="CreateOrJoin.studentTitle" />
                  )}
                </h1>
                <div>
                  <form onSubmit={handleSubmit}>
                  <label className={cx("formLabel")}>Meeting ID</label>
                    <input
                      value={title}
                      autoFocus={title == "" ? true : false}
                      className={cx('titleInput')}
                      placeholder={intl.formatMessage({
                        id: 'CreateOrJoin.titlePlaceholder'
                      })}
                      onChange={event => {
                        setTitle(event.target.value);
                      }}
                    />

                    {error['title'] ? <div className={cx('errorMessage')}>{error['title']}</div> : null}
                    <label className={cx("formLabel")}>Your name</label>
                    <input
                      className={cx('nameInput')}
                      autoFocus={title == "" ? false : true}
                      onChange={event => {
                        setName((event.target.value));
                      }}
                      value={name}
                      placeholder={intl.formatMessage({
                        id: 'CreateOrJoin.namePlaceholder'
                      })}
                    />

                    {error['name'] ? <div className={('errorMessage')}>{error['name']}</div> : null}

                    {(login_flag && localStorage.getItem(localStorageKeys.CLASS_MODE) === ClassMode.Teacher) && (
                      <div className={cx('regionsList')}>
                        <Dropdown
                          className={cx('dropdown')}
                          controlClassName={cx('control')}
                          placeholderClassName={cx('placeholder')}
                          menuClassName={cx('menu')}
                          arrowClassName={cx('arrow')}
                          value={region}
                          options={
                            region ? chime?.supportedChimeRegions : ([] as RegionType[])
                          }
                          disabled={!region}
                          onChange={(selectedRegion: RegionType) => {
                            // setRegion(selectedRegion);
                          }}
                          placeholder=""
                        />
                      </div>
                    )}
                   <button className={cx('submitbutton')} type="submit">
                      <FormattedMessage id="CreateOrJoin.continueButton" />
                    </button>
                  </form>
                </div>


                <div className={cx('login-bottom-link')}>
                  <div>
                    <div className={cx('loginLink', 'loginLinkBlackColor', 'leftSide')} onClick={() => {
                      if (isJoin) {
                        setTitle('')
                        setIsJoin(false)
                        setIsJoinFormEnabled(false)
                        setOpenScheduler(true)
                        localStorage.setItem('meeting_url', null)
                      } else {
                        history.push(routes.JOIN)
                        localStorage.setItem('meeting_url', null)
                      }
                    }}
                    >
                      <FormattedMessage id="Back.link" />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        { login_flag &&  external_chat &&
        <div className={cx("chatComponent",{
          openChat:openChat
        })}>
          { openChat ? 
          <>
          <div className={cx('tabtitle')}>  
          <div className={cx('TitleHeader')}>
          <img  onClick={() => {
            console.log("back")
             localStorage.setItem("lastActiveTime",`${new Date().getTime() * 10000}`)
             setOpenChat(false)
             setTotalMessageCount()
              }}
              src={'/icons1/backArrow.png'}
              />
          </div>
          <h3 className={cx('titlename')}> Chat  
           </h3> 
          </div>
            <StandAloneChat />
            </>
          :
          <div className={cx('chatIconHeader')}>
          <img  onClick={() => {
            setOpenChat(true)
              }}
              src={'/icons1/chatIcon-white.png'}
              />
            {totalMessageCounter > 0 && <span className={cx("totalMessageCount")}>{totalMessageCounter}</span>}
          </div>      
          }
        </div>
        }
          </div>
      }

      {
        isOPenWithDesktop &&
        <div className={cx('createOrJoin')}>
          <div className={cx('formWrapper')}>
            <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
            <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
            <div className={cx('title')}>How do you want to join your {commonob.title_name} meeting?</div>
            <div
              className={cx('joinWithDesktop')}
              onClick={() => downLoadDesktopApp()}
            >
              <div className={cx('continueWithParentClass')}>
                <div className={cx('continueWithChild1Class')}>
                  <img className={cx('continueInIcon')} src={`/icons1/continueInDesktop.png`} />
                </div>
                <div className={cx('continueWithChild2Class')}>
                  <div className={cx('text1')}>Download the Desktop App</div>
                  <div className={cx('text2')}>Use the desktop app for a better experience.</div>
                </div>
              </div>
            </div>
            <div
              className={cx('joinWithWeb')}
              onClick={() => joinWithWeb()}>
              <div className={cx('continueWithParentClass')}>
                <div className={cx('continueWithChild1Class')}>
                  <img className={cx('continueInIcon')} src={`/icons1/continueInBrowser.png`} />
                </div>
                <div className={cx('continueWithChild2Class')}>
                  <div className={cx('text3')}>Continue in the browser</div>
                  <div className={cx('text2')}>You don’t need to install anything, just from the browser directly.</div>
                </div>
              </div>
            </div>
            <div
              className={cx('joinWithDesktop')}
              onClick={() => joinWithDesktop()}>
              <div className={cx('continueWithParentClass')}>
                <div className={cx('continueWithChild1Class')}>
                  <img className={cx('continueInIcon')} src={`/icons1/continueInApp.png`} />
                </div>
                <div className={cx('continueWithChild2Class')}>
                  <div className={cx('text1')}>Continue in the App</div>
                  <div className={cx('text2')}>You already have the {commonob.title_name} app? Have your meeting there.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {sureToLogout &&

        <div className={cx('createOrJoin')}>
          {
            <div className={cx('formWrapper')}>
              <img className={cx('logo')} src={`/${logo_name}_logo.png`} />
              <div className={cx('versionNumber')}>Version: {pkgJSON.version}</div>
              <h1 style={{ fontSize: "1.2rem" }} className={cx('title')}> Are you sure you want to logout ? </h1>
              <div className={cx('login-bottom-link')}>
                <div className={cx('logout')}>
                  <div className={cx('logoutOptions', 'bgcolorLeftSide')} onClick={() => {
                    window.localStorage.setItem("isLogin", (false).toString());
                    window.localStorage.setItem("isAuthenticated", (false).toString());
                    localStorage.clear();
                    setIsJoinFormEnabled(false);
                    setIsOPenWithDesktop(false)
                    history.push(routes.LOGIN);
                    localStorage.setItem("lastActiveTime",`${new Date().getTime() * 10000}`)
                  }} >
                    Yes
              </div>
                  <div className={cx('logoutOptions', 'bgcolorRightSide')}
                    onClick={() => {
                      setSureToLogout(false);
                    }}
                  >
                    No
              </div>

                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}
