import classNames from 'classnames/bind';
import React, { useState, useEffect,useContext } from 'react';
import DeviceSetting from './DeviceSetting';
import styles from './DressingRoom.css';
import { useHistory,useLocation } from 'react-router-dom';
import commonob from '../constants/common.json'
import WaitRoom from './WaitRoom';
import { FormattedMessage } from 'react-intl';
import routes from '../constants/routes.json';
import LoadingSpinner from './LoadingSpinner';
import localStorageKeys from '../constants/localStorageKeys.json';
import ClassMode from '../enums/ClassMode';
import { globalApiService } from "../common_function"

const cx = classNames.bind(styles);

const JOIN_BUTTON_TIMEOUT_MSEC = 8000;

type RoomPreviewProps = {
  name: any;
  title: any;
  region: any;
  time:any;
  timer:any;
  shouldMeetingStart:boolean;
  remainTime:any;
  isHost:any;
  goingBack:any;
};
const DressingRoomPreview = (props: RoomPreviewProps) => {
  const { title, name, region,time,timer,isHost,goingBack,remainTime,shouldMeetingStart } = props;
  const history = useHistory();
  const [buttonEnabled, enableButton] = useState(false);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      enableButton(true)
    }, JOIN_BUTTON_TIMEOUT_MSEC);
    return () => {
      clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
   let timer = setTimeout(()=>{
      setIsLoading(false)
    },1500)
  return () => {
    clearTimeout(timer);
  }
  }, [timer]);

  useEffect(() => {
    if(shouldMeetingStart){
      joinMeeting()
    }
  }, [shouldMeetingStart]);


  const handleJoin = () => {
      joinMeeting()
  }

  const joinMeeting = () => {
    localStorage.setItem("currentAtendeeName", name);
    localStorage.setItem("currentAtendeeMeetingTitle", title);
    localStorage.setItem("audio_mute", 'false');
    localStorage.setItem("video_enable", 'false');
    localStorage.setItem("shareScreen", "false");
    localStorage.setItem("screenshare_", 'false')
    localStorage.setItem("meetingStartTime", `${new Date().getTime()}`);
    if (localStorage.getItem("audioInputDevice") == null){
      localStorage.setItem("audioInputDevice",`{}`)
    }
    if (localStorage.getItem("audioOutputDevice") == null){
      localStorage.setItem("audioOutputDevice",`{}`)
    }
    if (localStorage.getItem("videoInputDevice") == null){
      localStorage.setItem("videoInputDevice",`{}`)
    }

    let url = `/classroom?title=${encodeURIComponent(title)}&name=${encodeURIComponent(name)}&region=${region}&timeRemain=${remainTime}`;
    history.push(url);
  }

  const skipSettings = async() => {
    await new Promise(resolve => {
      goingBack()
      resolve({})
    })
    joinMeeting()
  }


  return (
    <>
    { loading ? (<LoadingSpinner />)
      :
      (
    <>
      {timer ?
        <WaitRoom
          time={time}
          timer={timer}
          title={title}
          isHost={isHost}
          goingBack={goingBack}
        />
        : localStorage.getItem("shouldMeetingStart") == "false" && 
        <>
          <h1>Settings</h1>
          <div className={cx('settingDescription')}>Pick the right settings for your device so you can have an amazing call</div>
          <DeviceSetting />
          <button
            className={cx('joinButton')}
            onClick={() => {
              if (!buttonEnabled) {
                return;
              }
              handleJoin()
            }}
          >
            {buttonEnabled ? (
              'Join'
            ) : (
              'Setting up audio and video...'
            )}
          </button>
          <div className={cx("skipSettings")} onClick={skipSettings}>Skip</div>

          <div className={cx('login-bottom-link')}>

            <div>
              <div className={cx('loginLink', 'loginLinkBlackColor', 'leftSide')} onClick={() => {
                goingBack()
                history.push(routes.JOIN)
              }}>

                <FormattedMessage id="Back.link" />
              </div>
            </div>
          </div>
        </>
      }
    </>
    )}
    </>
  );
};

var timeOut:any;
const DressingRoom = () => {
  const query = new URLSearchParams(useLocation().search);
  const title =  query.get('title')
   const name =query.get('name')
   const region = query.get('region')
   const [isHost,setIsHost] = useState(false)
   const [shouldMeetingStart,setShouldMeetingStart] = useState(false)
   const [time,setTime] = useState(0)
  //  const [isHost,setIsHost] = useState(false)
   const [timer,setTimer] = useState(false)
   const [isTimeRemain,setIsTimerRemain] = useState(false)

   useEffect(() => {
    localStorage.removeItem ("shouldMeetingStart")
    getInitTime()
    timeOut = setInterval(()=>{
      getInitTime()
    },10000)   
    setIsHost(query.get('isHost') == 'true' ? true : false)
  }, []);

  const getInitTime = async() => {

    var requestOption = {
      "method":"GET",
      "url":`${commonob.getBaseUrl}meeting_schedule?meetingId=${title}`
    }
    try{
      let result:any = await globalApiService(requestOption);
      if (result.length != 0) {
        localStorage.setItem("created_by",result[0].created_by)
        if (result[0].created_by == localStorage.getItem("userName")){
          setIsHost(true)
          localStorage.setItem(
            localStorageKeys.CLASS_MODE,
            ClassMode.Teacher
          );  
        }else{
          setIsHost(false)
          localStorage.setItem(
            localStorageKeys.CLASS_MODE,
            ClassMode.Student
          ); 
        }
        let now_time = new Date().getTime();
        let event_time = result[0]?.from_ts * 1000;
        setIsTimerRemain(true)
        if(event_time - now_time > 0){
          setTime(event_time)
          setTimer(true)
        }else{
          localStorage.setItem("created_by",localStorage.getItem("userName"))
          setTimer(true)
          clearInterval(timeOut)
          if (localStorage.getItem("audioInputDevice") != null && localStorage.getItem("audioOutputDevice") != null && localStorage.getItem("videoInputDevice") != null){
            localStorage.setItem("shouldMeetingStart","true")
            setShouldMeetingStart(true)
            setTimer(false)
          }else{
            localStorage.setItem("shouldMeetingStart","false")
            setShouldMeetingStart(false)
            setTimer(false)
            // setTimeout(() => {
            //   if (localStorage.getItem("audioInputDevice") == null && localStorage.getItem("audioOutputDevice") == null && localStorage.getItem("videoInputDevice") == null){
            //     localStorage.setItem("shouldMeetingStart","false")
            //     window.location.reload()
            //   }
            // }, JOIN_BUTTON_TIMEOUT_MSEC);
          }
        }
      }else{
        setTimer(true)
        setIsTimerRemain(false)
        clearInterval(timeOut)
        if (localStorage.getItem("audioInputDevice") != null && localStorage.getItem("audioOutputDevice") != null && localStorage.getItem("videoInputDevice") != null){
          setShouldMeetingStart(true)
          setTimer(false)
        }else{
          localStorage.setItem("shouldMeetingStart","false")
          setShouldMeetingStart(false)
          setTimer(false)
          // setTimeout(() => {
          //   if (localStorage.getItem("audioInputDevice") == null && localStorage.getItem("audioOutputDevice") == null && localStorage.getItem("videoInputDevice") == null){
          //     window.location.reload()
          //   }
          // }, JOIN_BUTTON_TIMEOUT_MSEC);
        }
      }
    } catch (e){
      console.log(e)
    } 
  }

  const goingBack = async() => {
    setTimer(false)
    await new Promise(resolve => {   
      clearInterval(timeOut)
      timeOut = 0;
      resolve({})
    })
  }

  return (
    <div className={cx('dressingRoom_wrapper')}>
      <div className={cx('dressingRoom')} >
        <DressingRoomPreview
          title={title}
          name={name}
          region={region}
          time={time}
          timer={timer}
          remainTime={isTimeRemain}
          shouldMeetingStart={shouldMeetingStart}
          isHost={isHost}
          goingBack={goingBack}
        />
      </div>
    </div>
  );
};

export default DressingRoom;
